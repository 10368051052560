@import url(https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300;400;700&display=swap);
* {
    padding: 0;
    margin:0;
    box-sizing: border-box;
}


/* ===== Start Form Builder Top bar ===== */
.section__top{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 70px;
    background: var(--white);
    border-radius: 5px;
    margin: 10px 0 10px 0;
    padding: 27px 20px;

}
.section__top__left {
    flex: 0.6 1;
}
.section__top__right {
    flex: 0.6 1;
}
.section__top__left h2{
    font-family: var(--poppins);
    font-weight: 400;
    font-size: 24px;
    line-height: 36px;
    color: var(--dark_blue);
    margin:0;

}
.section__top__right button{
    font-family: var(--poppins);
    padding: 13px 24px 13px 24px;
    border-radius: 5px;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    text-transform: capitalize;
    height: 50px;
}

.section__top__right button.add__icon{
    background: var(--light_blue);
    color: var(--white);
    outline:none;
    border:none;
    box-shadow: none;
}
.add__icon svg path{
    fill: white !important;
    fill-opacity: 1 !important;
}
.section__top__right button.tempalte__icon{
    background: var(--gray_light);
    color: rgba(19, 49, 89, 0.65);
    margin-right: 20px;
    padding:13px 29.35px;
}

/* ======= Start All Form Body ====== */

.form__wrapper{
    padding: 20px;
    background: var(--white);
    border-radius: 10px;
}
.form__wrapper .form__top{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}
.form__wrapper .form__top__left h3{
    font-family: var(--poppins);
    font-size: 20px;
    font-weight: 500;
    color: rgba(19, 49, 89, 0.65);
    line-height: 22px;
    margin:0;
    text-transform: capitalize;
}
.form__top__left .off__icon{
    background: var(--gray_light);
    padding: 12px 15px;
    font-family: var(--poppins);
    font-size: 16px;
    line-height: 22px;
    font-weight: 500;
    color: rgba(19, 49, 89, 0.65);
    height: 50px;
    border-radius: 5px;
    text-transform: none;
}

/* ===== Form Content ===== */

.form__content{
    border: 1px solid var(--gray_dark);
    padding: 20px 20px 10px;
    border-radius: 5px;
    min-height: 380px;
}
.form__content__nopreview{
    display: flex;
    justify-content: center;
    align-items: center;
}
.form__content__nopreview p{
    font-family: var(--poppins);
    font-size: 20px;
    line-height: 24px;
    color: var(--dark_blue);
}
.form__content .formcontrol_custom{
    width: 100%;
}
.form__content input{
    width: 100%;
    background: var(--gray_light) !important;
    border: 1px solid var(--gray_dark) !important;
    border-radius: 5px !important;
    padding: 0 10px !important;
    height: 48px !important;
}
.form__content input.focus-visible {
    box-shadow: none !important;
}
.form__content .MuiInput-formControl{
    margin-top: 5px !important;
    margin-bottom: 3px;
}

.form__content .MuiFormLabel-root{
    font-family: var(--poppins);
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    color: var(--dark_blue);
}

.form__wrapper .form__bottom{
    display: flex;
    align-items: center;
    justify-content:center;
    margin-top: 20px;
}

.form__wrapper .form__bottom li{
    height: 50px;
    width: 50px;
    min-width: 50px;
    display: flex;
    align-items: center;
    justify-content:center ;
    background: var(--gray_light);
    margin: 0 17.5px;
    color: rgba(19, 49, 89, 0.65);
}
.form__wrapper .form__bottom li svg {
    width: 20px;
    height: 20px;
}


/* Form Modal */
.createNewForm .modalBody .input-field input{
    width: 100%;
    height: 50px;
    border: 1px solid rgba(19, 49, 89, 0.3);
    border-radius: 5px;
    padding: 0 20px;
    margin-top:3px;
    font-family: var(--poppins);
    font-size: 16px;
    line-height: 24px;
    color:rgba(19, 49, 89, 0.35);
    font-weight: 400;
}
.createNewForm .modalBody .input-field input.focus-visible {
    box-shadow:none !important;
    border: 1px solid rgba(19, 49, 89, 0.3);
}
.createNewForm .modalBody .input-field input::-webkit-input-placeholder  {
    font-family: var(--poppins);
    font-size: 16px;
    line-height: 24px;
    color:rgba(19, 49, 89, 0.35);
    font-weight: 400;
    text-transform: capitalize;
}
.createNewForm .modalBody .input-field input::placeholder  {
    font-family: var(--poppins);
    font-size: 16px;
    line-height: 24px;
    color:rgba(19, 49, 89, 0.35);
    font-weight: 400;
    text-transform: capitalize;
}
.createNewForm .modalBody .input-field label {
    position: static;
    -webkit-transform: none;
            transform: none;
    font-family: var(--poppins);
    font-size: 16px;
    line-height: 24px;
    color: var(--dark_blue);
    font-weight: 400;
    text-transform: capitalize;
    margin-bottom: 7px;
}
.createNewForm .massage_send_close_button .send_massage, .createNewForm .massage_send_close_button .close_btn {
    font-family: var(--poppins);
    font-size:16px;
    line-height: 24px;
}

/* ===== Start System Form Temaplete ===== */

.systemform__sections .section__back{
    background: var(--light_blue);
    padding: 12px 15px;
    height: 50px;
    width: 95px;
    font-size: 16px;
    line-height: 24px;
    font-family: var(--poppins);
    font-weight: 500;
    border-radius: 5px;
    color: var(--white);
    display: flex;
}
.section__top__link{
    display: flex;
}
.section__top__link li{
    height: 50px;
    width: 50px;
    min-width: 50px;
    display: flex;
    align-items: center;
    justify-content:center ;
    background: var(--gray_light);
    margin: 0 4px;
    color: rgba(19, 49, 89, 0.65);
    padding: 10px;
}
.section__top__link li svg{
    font-size: 24px !important;
}
.systemform__sections .systemFromTemplate {
    background-color: var(--white);
    max-height: 514px;
    padding: 34px 17px;
    border-radius: 10px;
}
.systemform__sections .systemFromTemplate p {
    font-family: var(--poppins);
    font-size: 20px;
    line-height: 22px;
    font-weight: 500;
    color:rgba(19, 49, 89, 0.65);
    margin-bottom: 34px;

}
.systemform__sections .systemFromTemplate img {
    width: 100%;
    height: 334px;
    border-radius: 5px;
}
.systemform__sections .systemFromTemplate .formCount {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
}
.systemform__sections .systemFromTemplate .formCount p {
    font-family: var(--poppins);
    font-size: 16px;
    line-height: 22px;
    color:rgba(19, 49, 89, 0.65);
    margin:0;
    font-weight: 500;
}
.systemform__sections .systemFromTemplate .formCount svg{
    width: 50px;
    height: 50px;
    border-radius: 5px;
    background-color: var(--gray_light);
    padding: 15px;
    cursor:  pointer;
}

/* Accordin Css */
.accroding__wr .MuiAccordion-root{
    border-radius: 10px !important;
    /*margin-bottom: 30px;*/

}
.accroding__wr .MuiAccordionDetails-root {
    padding: 2px 1px 32px !important;
}

.accroding__wr .MuiPaper-root{
    background: transparent;
    margin-top: 10px;
}
.accroding__wr .MuiButtonBase-root{
    background: var(--white);
    border-radius: 10px;
}
.accroding__wr .MuiPaper-elevation1{
    box-shadow: 0 0 0 0 rgba(0,0,0,0.0)!important;
}
.accroding__wr .MuiGrid-spacing-xs-3 {
    width: 100%;
    margin: 0px;
    margin-top: 10px;
}
.accroding__wr .MuiAccordionSummary-root {
    padding: 0px 20px;
    min-height: 70px;
}
.accroding__wr .MuiTypography-root {
    font-family: var(--poppins);
    font-size:20px;
    line-height: 30px;
    font-weight: 400;
    color: var(--dark_blue);
}


/* ====== Start Preview and State  =====*/
.accroding__wr .accordionContain .leftSide{
    background-color: var(--white) !important;
    border-radius: 10px;
    padding: 25px 50px 50px;
}
.accroding__wr .accordionContain .leftSide .topbar {
    display: flex;
    align-items:center;
    justify-content: space-around;

}
.accroding__wr .accordionContain .leftSide .topbar p {
    font-family: var(--poppins);
    font-size: 16px;
    line-height: 22px;
    font-weight: 500;
    color:rgba(19, 49, 89, 0.65);
    margin-right: 20px;
}
.accroding__wr .accordionContain .leftSide .topbar button {
    background-color: var(--gray_light);
    padding: 14px 11px;
    height: 50px;
    border-radius: 5px;
    font-family: var(--poppins);
    font-size: 16px;
    line-height: 22px;
    font-weight: 500;
    color:var(--light_blue);
    text-transform: none;
    box-shadow: none;
}
.accroding__wr .accordionContain .leftSide .topbar button svg {
    width:18px;
    height: 18px;
    vertical-align: middle;
    margin-right: 10px;
}
.accroding__wr .accordionContain .leftSide .textArea {
    display:flex;
    align-items: center;
    padding: 12px;
    border-radius: 5px;
    background-color: var(--gray_light);
    margin-top: 20px;
}
.accroding__wr .accordionContain .leftSide .textArea .circleArea {
    display: flex;
    margin-right: 10px;
}
.accroding__wr .accordionContain .leftSide .textArea .circleArea .circle{
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: var(--gray_light);
    margin: 4px;
}
.accroding__wr .accordionContain .leftSide .textArea .circleArea .circle .red {
    background-color:#ED6A5E;
}
.accroding__wr .accordionContain .leftSide .textArea .circleArea .circle .green {
    background-color:#F6BE4F;
}
.accroding__wr .accordionContain .leftSide .textArea p {
    font-family: var(--poppins);
    font-size: 12px;
    line-height: 18px;
    color: rgba(19, 49, 89, 0.65);
    background-color: var(--white);
    padding: 3px 13px;
    border-radius: 16px;
    width: 100%;
    margin-right: 16px;
    word-break: break-all;
}
.accroding__wr .accordionContain .leftSide .textArea svg {
    width: 20px;
    height: 20px;
    margin-left: auto;
    cursor: pointer;
}
.accroding__wr .accordionContain .leftSide .formArea {
    width: 100%;
    min-height: 354px;
    border: 2px solid var(--gray_dark);
    margin-top: 26px;
}
.accroding__wr .accordionContain .rightSide {
    background-color: var(--white);
    border-radius: 10px;
    max-height: 160px;
    margin-left: 30px;
    padding: 20px;
    max-width: 48% !important;
}
.accroding__wr .accordionContain .rightSide .countCard {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.accroding__wr .accordionContain .rightSide .countCard:not(:last-child) {
    border-right:1px solid var(--gray_light);
}
.accroding__wr .accordionContain .rightSide .countCard h5 {
    font-family: var(--poppins);
    font-size: 32px;
    line-height: 48px;
    font-weight: 700;
    margin: 0;
}
.accroding__wr .accordionContain .rightSide .countCard p {
    font-family: var(--poppins);
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    margin-top: 10px;
}
.accroding__wr .accordionContain .rightSide .countCard.lightOrange {
    color:var(--orange);
}
.accroding__wr .accordionContain .rightSide .countCard.lightBlue {
    color:var(--light_blue);
}
.accroding__wr .accordionContain .rightSide .countCard.lightGreen {
    color:#6FCF97
}

/* ===== Start Dragable area ===== */

.systemform__sections .formBuilder {
    width: 100%;
    margin-top: 10px;
}
.systemform__sections .formBuilder .frmb {
    background-color: var(--white) !important;
    max-height: unset !important;
    min-height: 678px !important;
    width: 72.2%;
    font-family: var(--poppins);
    font-size: 24px;
    line-height: 36px;
    font-weight: 400;
    color:var(--dark_blue);
    border: 2px dashed var(--gray_dark) !important;
}
.systemform__sections .formBuilder .input-control {
    height: 30px;
    width: auto;
    background: var(--white) !important;
    border: 1px solid var(--gray_light) !important;
    box-shadow: none !important;
    margin-top: 3px !important;
    border-radius: 5px;
    font-family: var(--poppins);
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    color: rgba(19, 49, 89, 0.65) !important;

}

.form-wrap.form-builder .frmb-control li{
    padding: 3px !important;
}
.systemform__sections .MuiAccordion-root:before  {
    opacity:0 !important;
}
.systemform__sections .formBuilder .btn-group button{
    background-color: var(--light_blue);
    height: 50px;
    padding: 13px 20px;
    border-radius: 5px;
    font-family: var(--poppins);
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    text-transform: none;
}

/* ====== Start Responsive ======*/
@media only screen and (max-width: 1600px) {
    .form__wrapper .form__bottom li {
        width: 40px;
        height: 40px;
        min-width: 1px;
        margin: 0 8px;
        border-radius: 5px;
    }
    .accroding__wr .accordionContain .rightSide {
        max-width:47% !important;
    }
}

@media only screen and (max-width: 1024px) {

    /* Start Accordion Topbar */

    .section__top {
        height: 70px;
        margin:20px 0 10px 0;
    }
    .section__top__left h2 {
        font-size: 16px;
        line-height: 24px;
    }
    .section__top__right button {
        padding: 5px 12px;
        font-size:12px;
        line-height: 20px;
    }
    .systemform__sections .section__back {
        font-size: 12px;
        line-height: 18px;
    }

    /* Start Accordion */

    .allform__sections .MuiGrid-spacing-xs-3 {
        width: 102% !important;
        margin: unset!important;
        margin-top: -10px;
        margin-left: -6px !important;

    }
    .allform__sections .MuiGrid-spacing-xs-3 > .MuiGrid-item {
        padding: 6px !important;
    }

    /* Start Form */
    .form__wrapper {
        padding: 10px;
    }
    .form__wrapper .form__top {
        margin-bottom: 10px;
    }
    .form__wrapper .form__top__left h3 {
        font-size: 12px;
        line-height: 20px;
        font-weight: 500;
    }
    .form__top__left .off__icon {
        height: 40px;
        font-size: 12px;
        line-height: 20px;
    }
    .form__top__left .MuiButton-startIcon {
        margin-right: 5px;
    }
    .form__content {
        padding: 10px;
        min-height: 358px;
    }
    .form__content__nopreview p {
        font-size: 12px;
        line-height: 24px;
    }
    .form__content .MuiFormLabel-root {
        font-size:12px;
        line-height: 20px;
    }
    .form__wrapper .form__bottom li {
        width: 30px;
        height: 30px;
        min-width: 1px;
        margin: 0 3px;
        border-radius: 5px;
    }

    /* Modal Start */
    .createNewForm {
        min-width: 369px !important;
    }
    .createNewForm .modalBody {
        padding: 0 30px 0 10px !important;
    }
    .createNewForm .modalBody .input-field label {
        font-size: 16px;
        line-height: 24px;
    }
    .createNewForm .massage_send_close_button .send_massage, .createNewForm .massage_send_close_button .close_btn {
        height: 40px;
        font-size: 12px;
        line-height: 20px;
        padding: 9px 20px !important;
        margin-right: 12px !important;
    }
    .createNewForm .massage_send_close_button .send_massage svg, .createNewForm .massage_send_close_button .close_btn svg{
        width: 24px !important;
        height: 24px !important;
    }

    /* start Test form */
    .accroding__wr .MuiTypography-root {
        font-size:16px;
        line-height: 20px;
    }
    .accroding__wr .MuiAccordion-root {
        margin-bottom: 10px;
    }
    .accroding__wr .MuiAccordionSummary-root {
        min-height: 70px;
    }
    .accroding__wr .accordionContain .rightSide {
        max-width:48% !important;
        margin-left: 10px;
        padding: 8px;
        max-height: 92px;
    }
    .accroding__wr .accordionContain .leftSide {
        padding: 10px;
    }
    .accroding__wr .accordionContain .leftSide .topbar p {
        font-size: 12px;
        line-height: 22px;
        margin-right:11px;
    }
    .accroding__wr .accordionContain .leftSide .topbar button {
        font-size: 12px;
        line-height: 22px;
        padding: 11px 13px;
        height: 40px;
    }
    .accroding__wr .accordionContain .leftSide .textArea {
        padding: 9px;
        margin-top: 10px;
    }
    .accroding__wr .accordionContain .leftSide .textArea p {
        margin-right: 10px;
    }
    .accroding__wr .accordionContain .leftSide .textArea svg {
        width: 30px;
        height: 24px;
    }
    .accroding__wr .accordionContain .leftSide .formArea {
        margin-top: 20px;
    }
    .accroding__wr .accordionContain .rightSide .countCard h5 {
        font-size: 20px;
        line-height: 30px;
    }
    .accroding__wr .accordionContain .rightSide .countCard p {
        font-size: 8px;
        line-height: 12px;
        border-radius: 10px;
        margin-top:0;
    }

    .systemform__sections .formBuilder .input-control {
        font-size: 12px;
        line-height: 20px;
    }
    .main__content_wrapper {
        display:block !important;
        padding-top: 10px;
    }
    .systemform__sections .systemFromTemplate {
        padding: 10px;
    }
    .systemform__sections .systemFromTemplate p {
        margin-bottom: 10px;
        font-size: 12px;
        line-height: 20px;
    }
    .systemform__sections .systemFromTemplate .formCount p {
        font-size: 12px;
    }
    .systemform__sections .systemFromTemplate .formCount svg {
        width: 40px;
        height: 40px;
        padding: 8px;
    }

}
@media only screen and (max-width: 1366px) {
    .accroding__wr .accordionContain .rightSide .countCard p {
        font-size: 14px;
        line-height: 21px;
    }

}
.form__wrapper .card.form-card{
    width: 100% !important;
}
.form__bottom .form__action__buttons {
    border: none;
    background: none;
    cursor: pointer;
}
.setting_container {
    height: 400px;
}
.editor-wrapper {
    width: 100%;
}
.systemFromTemplate .clone_button {
    border: none;
}
.systemFromTemplate .clone_button:focus {
    outline: none;
    background-color: transparent;
}
.editor-wrapper ul li input:not([type]), 
.editor-wrapper ul li input[type=text]:not(.browser-default), 
.editor-wrapper ul li input[type=password]:not(.browser-default), 
.editor-wrapper ul li input[type=email]:not(.browser-default), 
.editor-wrapper ul li input[type=url]:not(.browser-default), 
.editor-wrapper ul li input[type=time]:not(.browser-default),
.editor-wrapper ul li input[type=date]:not(.browser-default),
.editor-wrapper ul li input[type=datetime]:not(.browser-default), 
.editor-wrapper ul li input[type=datetime-local]:not(.browser-default),
.editor-wrapper ul li input[type=tel]:not(.browser-default), 
.editor-wrapper ul li input[type=number]:not(.browser-default), 
.editor-wrapper ul li input[type=search]:not(.browser-default), 
.editor-wrapper ul li textarea.materialize-textarea {
  border-bottom: none;
  background: #F6F7FB;
  border-radius: 5px;
  box-sizing: border-box;
}
.editor-wrapper .form-wrap.form-builder .frmb li{
    /* border-bottom: 3px solid #e5e5e5; */
    border: 1px solid #F6F7FB;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 20px;
}


.allform__sections .form__wrapper .card.form-card{
    box-shadow: none;
}
.allform__sections .form__wrapper .card.form-card input:not([type]), input[type=text]:not(.browser-default),
 .allform__sections .form__wrapper .card.form-card input[type=password]:not(.browser-default),
 .allform__sections .form__wrapper .card.form-card  input[type=email]:not(.browser-default),
  .allform__sections .form__wrapper .card.form-card  input[type=url]:not(.browser-default), 
   .allform__sections .form__wrapper .card.form-card input[type=time]:not(.browser-default),
   .allform__sections .form__wrapper .card.form-card  input[type=date]:not(.browser-default), 
   .allform__sections .form__wrapper .card.form-card  input[type=datetime]:not(.browser-default),
   .allform__sections .form__wrapper .card.form-card   input[type=datetime-local]:not(.browser-default),
   .allform__sections .form__wrapper .card.form-card    input[type=tel]:not(.browser-default), 
.allform__sections .form__wrapper .card.form-card input[type=number]:not(.browser-default), 
.allform__sections .form__wrapper .card.form-card input[type=search]:not(.browser-default), 
.allform__sections .form__wrapper .card.form-card textarea.materialize-textarea{
    background: #F6F7FB;
    border: 1px solid #E5E8EF;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 10px 12px;
}
input[type=text]:not(.browser-default).box_shadow_none:focus {
    box-shadow: none;
}
.allform__sections .form__wrapper .card.form-card .card-body{
    border: 1px solid #E5E8EF;
    border-radius: 5px;
    padding: 20px;
}
.allform__sections .form__wrapper .card.form-card .formbuilder-text-label{
    font-family: var(--poppins);
    font-style: normal;
    font-weight: 500;
    font-size: 16px!important;
    line-height: 24px;
    letter-spacing: 0.01em;
    text-transform: capitalize;
    color: #133159;
}

.allform__sections .form__wrapper .card.form-card .submit-btn{
    min-height: 50px;
    height: 100%;
    line-height: unset;
}


.createNewForm .modalBody .input-field .MuiFilledInput-underline:before,
.createNewForm .modalBody .input-field .MuiFilledInput-underline:after{
    display: none;
}.createNewForm .modalBody .input-field input{

}.createNewForm .modalBody .input-field input{

}
.createNewForm .modalBody  .input-color-picker{
    padding: 12px 15px;
    border-radius: 5px;
    background: #F6F7FB;
    border: 1px solid #E5E8EF;
    width: 100%;
}
.createNewForm .modalBody  .input-color-picker [type='color'] {
    -webkit-appearance: none;
    appearance: none;
    padding: 0;
    width: 15px;
    height: 15px;
    border: none;
  }
  .createNewForm .modalBody  .input-color-picker label{
    position: relative;
    left: unset;
    width: 100%;
    display: flex;
    align-items: center;
    margin: 0;

  }
  .createNewForm .modalBody  .input-color-picker label span{
      padding-left: 5px;
      text-transform: initial;
  }
.createNewForm .modalBody  .pp_select_field,
.setting_container .pp_setting_select,
.pp_form_entry_select{
    background: #F6F7FB;
    border: 1px solid #E5E8EF;
    border-radius: 5px;
    width: 100%;
}
.createNewForm .modalBody  .pp_select_field select{
    padding: 15px 20px;
}
.createNewForm .modalBody  .pp_select_field::before,
.createNewForm .modalBody  .pp_select_field::after,
.setting_container .pp_setting_select::after,
.setting_container .pp_setting_select::before,
.pp_form_entry_select::before,
.pp_form_entry_select::after{
    display: none;
}
.createNewForm .modalBody .input-field .pp_switcher_field{
  display: flex;
  align-items: center;
}
.createNewForm .modalBody  #landing-page-bg-file{
    border: 1px solid rgba(19, 49, 89, 0.3);
    border-radius: 5px;
    height: 100%;
    padding: 10px 20px;
}
.createNewForm .modalBody .pp_form_style .MuiFilledInput-root{
  background-color: transparent;
}
.createNewForm .modalBody  .pp_switcher_field svg,
.setting_container .pp_switcher_field svg{
    width: 16px;
    display: flex;
    left: 5px;
}
.setting_container select{
    /* border: 1px solid #E5E8EF;
    border-radius: 5px; */
    padding: 15px 20px;
}

.pp_integration-modal .globalModalWrapper {
    width: 800px;
}

.from-builder-preview-wrapper{
    max-height: 300px;
    min-height: 300px;
    overflow-y: auto;
    padding-bottom: 0;
}

.no-form-preview-text-center{
    display: grid;
    place-items: center;
    max-height: 300px;
    min-height: 327px;
    overflow-y: auto;
    padding-bottom: 0;
}

.pp_integration-modal.integrate-html {
    bottom: 0;
}

.form-settings-form-builder.setting_container .input-field {
    margin-top: 3px!important;
    margin-bottom: 2px!important;
}

.formBuilder .input-wrap input[type='checkbox'] {
    opacity: 1 !important;
    margin-top: 10px !important;
    position: unset !important;
    pointer-events: auto;
}
.pp_integration-modal .integrate__form__v2{
    max-height: calc(100vh - 265px);
}


.formbuilder-radio  [type="radio"]:not(:checked), .formbuilder-radio  [type="radio"]:checked{
    opacity: 1;
    position: relative;
}

.formbuilder-checkbox  [type="checkbox"]:not(:checked), .formbuilder-checkbox  [type="checkbox"]:checked{
    opacity: 1;
    position: relative;
}

.form-wrap.form-builder .frmb li.form-field.checkbox-field {
    pointer-events: initial;
    opacity: 1;
}
body.react-confirm-alert-body-element {
  overflow: hidden;
}

.react-confirm-alert-blur {
  -webkit-filter: url(#gaussian-blur);
          filter: url(#gaussian-blur);
  filter: blur(2px);
  -webkit-filter: blur(2px);
}

.react-confirm-alert-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  background: rgba(255, 255, 255, 0.9);
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: center;
  -ms-align-items: center;
  align-items: center;
  opacity: 0;
  -webkit-animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
  animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
}

.react-confirm-alert-body {
  font-family: Arial, Helvetica, sans-serif;
  width: 400px;
  padding: 30px;
  text-align: left;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 20px 75px rgba(0, 0, 0, 0.13);
  color: #666;
}

.react-confirm-alert-svg {
  position: absolute;
  top: 0;
  left: 0;
}

.react-confirm-alert-body > h1 {
  margin-top: 0;
}

.react-confirm-alert-body > h3 {
  margin: 0;
  font-size: 16px;
}

.react-confirm-alert-button-group {
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: flex-start;
  margin-top: 20px;
}

.react-confirm-alert-button-group > button {
  outline: none;
  background: #333;
  border: none;
  display: inline-block;
  padding: 6px 18px;
  color: #eee;
  margin-right: 10px;
  border-radius: 5px;
  font-size: 12px;
  cursor: pointer;
}

@-webkit-keyframes react-confirm-alert-fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes react-confirm-alert-fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.action-disabled-action-control{
    pointer-events: none;
}
.custom-switch .react-switch-bg {
    display: flex;
}
.custom-switch-off-icon{
    padding: 0px 6px 0 0;
}
.custom-switch-on-icon{
    padding: 4px;
}
.custom-switch-active-mode .react-switch-handle {
    top: 4px !important;
}
.custom-switch-inactive-mode .react-switch-bg {
    border: 1px solid #e1e5ec !important;
    background-color: #e1e5ec !important;
}
.custom-switch-inactive-mode .react-switch-handle{
    top: 4px !important;
    left: 4px
}

.custom-switch .react-switch-handle {
    height: 16px !important;
    width: 16px !important;
}
.form-card{
  width: 100% !important;
  box-shadow: unset !important;
  padding: 20px;
}
.field-email input{
  background: #F6F7FB !important;
  border: 1px solid #E5E8EF !important;
  box-sizing: border-box !important;
  border-radius: 5px !important;
  padding: 10px 12px !important;
}
.field-first_name input:focus,
.field-email input:focus{
  box-shadow: unset !important;
  border-bottom: 1px solid #E5E8EF !important;
}
.form_url_area {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.form_url_area .copy__button {
  border: none;
}
.form_url_area .copy__button:focus {
  background: none;
}

.setting_container label {
  width: 100%;
  display: inline-block;
}

#fb-rendered-form .form-control{
  background: #F6F7FB !important;
  border: 1px solid #E5E8EF !important;
  box-sizing: border-box !important;
  border-radius: 5px !important;
  padding: 10px 12px !important;
}
.form-wrap.form-builder .stage-wrap {
  min-height: unset !important;
  height: 740px !important;
  overflow-y: auto;
}
.editor-wrapper{
  z-index: 99;
}
.editor-wrapper .form-wrap.form-builder .frmb{
  padding: 20px;
}
.form-wrap.form-builder .cb-wrap{
  height: 740px;
  overflow-y: auto;
}

#fb-rendered-form select.form-control, #fb-rendered-form input.form-control{
  height: 3rem;
}

.FormBuilderModule-container-parent-class .checkbox-field.form-field.editing .option-actions {
  display: none !important;
}

.FormBuilderModule-container-parent-class .checkbox-field.form-field.editing .form-group.className-wrap{
  display: none !important;
}

.FormBuilderModule-container-parent-class .checkbox-field.form-field.editing .form-group.name-wrap{
  display: none !important;
}

.FormBuilderModule-container-parent-class .checkbox-field.form-field.editing .form-group.field-options .option-label.option-attr:nth-child(2) {
  width: 100%;
}

.FormBuilderModule-container-parent-class .checkbox-field.form-field.editing .form-group.field-options .option-value.option-attr:nth-child(3) {
  display: none !important;
}

.FormBuilderModule-container-parent-class .checkbox-field.form-field.editing .form-group.field-options .false-label {
  color: transparent;
}

.FormBuilderModule-container-parent-class .checkbox-field.form-field.editing .form-group.field-options .false-label:after {
  content: "Add your text here";
  color: #9e9e9e;
  margin-left: -35px;
}

#fb-rendered-form .formbuilder-checkbox input {
  margin-right: 4px;
}
.form_entry_container .section__top__right{
    display: flex;
}
.form_entry_container .section__top__link .section__top__right{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}
.form_entry_container .section__top__link li{
    height: 40px;
    width: 40px;
}
.form_entry_container .form_builder.body-search input{
    border-radius: 5px !important;
    height: 40px !important;
    width: auto !important;
}
.datatable-area.pp_form_entry_table_wrapper {
    padding: 20px;
    background: white;
    border-radius: 5px;
}
.Drip_table.pp_form_entry_table {
    background: white;
}
.pp_form_entry_select{
    padding: 5px 10px;
    margin: 10px 0px;
}
.pp_form_entry_table .rdt_TableHead *{
    font-family: var(--poppins);
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 36px;
    color: #133159;
}
.pp_form_entry_table .rdt_TableBody .rdt_TableCell{
    font-family: var(--poppins);
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 24px;
    color: rgba(19, 49, 89, 0.65);
}
.pp_add_to_campaigns{
    display: flex;
    align-items: center;
    position: relative;
}
.globalModalWrapper .campaign_modal .pp_add_to_campaigns input{
    box-sizing: border-box;
    padding: 10px 20px;
}
.pp_add_to_campaigns svg{
    top: auto;
    position: absolute;
    right: 15px;
}
.pp_form_entry_table_wrapper .datatable_helper {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.pp_form_entry_table_wrapper .select-wrapper {
    width: 80px;
    background: #fff;
}

.pagination_bar ul {
    display: flex;
    justify-content: center;
}

.pagination_bar ul li a {
    color: #fff;
    background: #3C7EF3;
    border-radius: 5px;
    padding: 8px 23.5px;
    margin-left: 10px;
    display: block;
    font-weight: 600;
    border: 2px solid #3C7EF3;
    font-size: 18px;
    line-height: 30px;
}

.pagination_bar ul li:nth-child(2) a {
    background: transparent;
    border: 2px solid #3C7EF3;
    color: #3C7EF3;
}

.pagination_bar ul li:nth-child(3) a {
   background: transparent;
   color: #3C7EF3;
   border: unset;
   padding: 8px 6px;
}

.datatable_helper .total_entries {
   color: #657993;
}
.body-search form{
    position: relative;
    border: 1px solid #dddddd;
    border-radius: 5px;
}

.body-search form input {
    width: 214px !important;
    background: #FFFFFF !important;
    border-radius: 20px !important;
    border: none !important;
    display: block;
    padding: 8px 10px !important;
    font-style: normal;
    font-weight: normal;
    font-size: 14px !important;
    line-height: 24px;
    color: #BABABA;
    height: auto !important;
    min-height: auto;
    margin: 0px !important;
    overflow: hidden;
}
.body-search form input:placeholder{
    opacity: 1 !important;
}
.body-search form input:focus{
    box-shadow: inherit !important;

}
body .search-data-table .body-search input {
    margin: 0 !important;
    height: 42px !important;
    padding-top: 0px !important;
    padding-bottom: 0 !important;
}
.body-search form button {
    position: absolute;
    top: 50%;
    right: 3px;
    background: transparent;
    border: none;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
}

.body-search form button i{
    color: #3C7EF3;
}
.formbuilder-radio label, .formbuilder-checkbox label{
    margin-left: -14px;
    padding-left: 14px;
}

.form_success_wrapper{
    color: #202124;
    background-color: #fff;
    border-radius: 8px;
    margin-bottom: 12px;
    position: relative;
    padding: 14px 6px 6px 0;
}

.form_title{
    font-weight: 400;
    line-height: 40px;
    color: #202124;
    padding-bottom: 16px;
    font-size: 24pt;
}

.form_success_message{
    font-size: 16px;
    font-weight: 400;
    letter-spacing: .2px;
    line-height: 20px;
    color: #202124;
    white-space: pre-wrap;
}

.form_resubmit{
    margin-top: 20px;
}

.form_resubmit span {
    color: #4c4cb6;
    text-decoration: underline;
    cursor: pointer;
}

.form_disabled_wrapper{
    position: absolute;
    background: #24201a4d;
    width: 100%;
    height: 100%;
    z-index: 1111;
    right: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: auto;
}

.form_disabled_alert{
    font-size: 20px;
    color: #383837 !important;
    padding: 10px;
    background: #e1d5c2;
    border-radius: 4px;
    max-width: 390px;
    text-align: center;
}

.form_disabled_alert h1 {
    font-size: 20px;
}
.not__found__page {
    background: url('https://app.pypepro.com/assets/img/error/404.png') no-repeat fixed center/cover;
    height: 100vh;
    width: 100%;
    display: grid;
    place-items: center;
}

.not__found__page p {
    font-size: 20px;
    font-weight: 600;
    color: teal;
    margin-top: 210px;
}
.custom_form_list__wrapper {
    padding: 20px;
    background: #f7f7f79c;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    margin: 20px;
    box-shadow: 1px 1px 4px #bbb;
}

.custom_form_list__wrapper .form__top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 500px;
    width: 100%;
}

.custom_form_list__wrapper .form__bottom {
    display: flex;
    align-items: center;
    justify-content: center;
}

.custom_form_list__wrapper .form__top__left h3 {
    font-family: var(--poppins);
    font-size: 20px;
    font-weight: 500;
    color: rgba(19, 49, 89, 0.65);
    line-height: 22px;
    margin: 0;
    text-transform: capitalize;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 300px;
}

.custom_form_list__wrapper .form__bottom li {
    height: 50px;
    width: 50px;
    min-width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ffffff;
    margin: 0 17.5px;
    color: rgba(19, 49, 89, 0.65);
    box-shadow: 0px 0px 2px #bbb;
    border-radius: 4px;
}

.custom_form_list__wrapper .form__bottom .form__action__buttons {
    border: none;
    background: none;
    cursor: pointer;
}

.custom_form_list__wrapper .form__top__left {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.custom_form_list__wrapper .form__top__left button {
    margin: -2px 0 0 20px;
    height: 30px;
    border-radius: 8px;
    text-transform: capitalize;
    background-color: #3980F3;
}

.custom_form_list__wrapper .form__top__left button span {
    font-weight: 600;
    letter-spacing: 1px;
    color: #fff;
    padding: 0 10px 0 10px;
    font-size: 15px;
}

/* Form Design */
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

.body__container__form__builder {
  width: 60%;
  margin: 50px auto;
}

.body__container__form__builder.logo__area{
  width: 60%;
  margin: 0 auto;
}

.body__container__form__builder .ck.ck-editor__editable_inline {
  overflow: hidden !important;
}

.form-title {
  width: 100%;
  font-size: 28px;
  font-weight: 700;
  color: black;
  margin: 25px 0;
  border: navajowhite;
}

.form-input:focus,
.form-input:focus-visible {
  border: none;
  outline: none;
}

.single-input-area .action-buttons {
  transition: 500ms;
  opacity: 1;
}

.single-input-area:hover .action-buttons {
  opacity: 1;
}

.input-area > div {
  padding: 5px;
}

.action-buttons {
  display: flex;
  justify-content: flex-end;
  height: 36px;
}
.preview__top__relative{
  padding: 0 !important;
}

.action-buttons button {
  display: inline-block;
  padding: 0.3rem 0.7rem;
  margin: 1px;
  transition: 300ms;
  cursor: pointer;
  background-color: #efefef;
}

.action-buttons button:hover {
  background-color: #dfdfdf;
}

.page-devider {
  margin: 30px 0;
  display: flex;
  justify-content: space-between;
}

.page-devider Button {
  opacity: 1;
  transition: 500ms;
  background-color: rgba(0, 0, 0, 0.04);
  min-width: 50px;
  height: 50px;
  margin-right: 12px;
}

.page-devider Button:hover {
  background: #00000066;
  color: #000;
}

.page-devider .horizontal-line-area {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.page-devider .horizontal-line-area div:nth-child(1),
.page-devider .horizontal-line-area div:nth-child(3) {
  width: 40%;
  height: 1px;
  background-color: rgba(0, 0, 0, 0.5);
}

.page-devider .horizontal-line-area div:nth-child(2) {
  width: 20%;
  background-color: #fff;
  text-align: center;
  border: 1px solid rgba(0, 0, 0, 0.5);
  padding: 5px;
  font-size: 0.75em;
}

.modal-100w {
  width: 100%;
  max-width: none !important;
  margin: 0;
  border: none;
  height: 100vh;
}

.min-h-100vh.public__page__background{
  background-size: cover;
  background-repeat: no-repeat;
}
.modal-100w .modal__preview__content__wr {
  height: 100vh;
  margin: 0;
  background-color: white;
  border-radius: inherit;
  box-shadow: none;
  z-index: 999;
  overflow-y: auto;
  padding: 24px;
  background-size: cover;
  background-repeat: no-repeat;
}

.modal__preview__content__wr .modal__preview__content__heading__wr {
  display: flex;
  justify-content: space-between;
  margin: 20px;
}

.modal__preview__content__wr .back__to__edit {
  padding: 0px 12px;
  border: 0px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  text-transform: capitalize;
  display: flex;
  grid-gap: 5px;
  z-index: 5;
  font-weight: 700;
  color: #fff;
  height: 34px !important;
  background: #0d6efd !important;
}

.modal__preview__content__heading__wr h2 {
  font-size: 26px;
  font-weight: 700;
  color: rgb(55, 53, 47);
}

.modal__preview__content__wr .back__to__edit .MuiButton-label {
  display: flex;
  align-items: center;
  grid-gap: 8px;
}

.modal__preview__content__wr .back__to__edit .MuiButton-label {
  display: flex;
  align-items: center;
  grid-gap: 8px;
}

.new__form__builder__preview__body {
  overflow-y: auto;
  max-width: 740px;
  margin: 50px auto 0px;
  padding: 0 10px;
}

.new__form__builder__preview__body .logo__area{
  margin: 0 auto !important;
}

.new__form__builder__preview__body .new__form__builder__preview__title {
  font-size: 40px;
  font-weight: 800;
  line-height: 1.1;
  padding-bottom: 40px;
  margin: 0px;
}

.new__form__builder__preview__body .singleItem textarea,
.new__form__builder__preview__body .singleItem input {
  width: 100%;
  height: 36px;
  padding: 0px 10px 0px 30px;
  box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
    rgba(0, 0, 0, 0.12) 0px 1px 1px 0px, rgba(60, 66, 87, 0.16) 0px 0px 0px 1px,
    rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
    rgba(60, 66, 87, 0.08) 0px 2px 5px 0px !important;
  border: 0px !important;
  border-radius: 5px;
  outline: none;
  background-color: rgb(255, 255, 255);
  margin: 0;
}

.new__form__builder__preview__body .singleItem textarea {
  min-height: 60px;
  height: auto;
  resize: none;
  padding: 10px 10px 10px 30px;
}

.ar__flex_column {
  display: flex;
  flex-direction: column;
}

.new__form__builder__preview__body .singleItem.alt svg {
  top: 10px;
  -webkit-transform: unset;
          transform: unset;
}

.singleItem h5 {
  font-size: 16px;
  margin-bottom: 10px;
}

.new__form__builder__preview__body .singleItem > div {
  position: relative;
}

.new__form__builder__preview__body .singleItem svg {
  position: absolute;
  left: 10px;
  top: 7px;
}

.new__form__builder__preview__body .new__form__builder__preview__submit__btn {
  background-color: rgb(0, 0, 0);
  color: #fff;
  text-transform: capitalize;
  font-weight: 700;
  /* padding: 3px 17px; */
}

.new__form__builder__preview__body
  .new__form__builder__preview__submit__btn:hover {
  background-color: rgb(0, 0, 0);
}

.new__form__builder__preview__body
  .new__form__builder__preview__submit__btn
  .MuiButton-label {
  display: flex;
  grid-gap: 3px;
}

.new__form__builder__preview__body
  .new__form__builder__preview__submit__btn
  svg {
  font-size: 16px;
}

.preview-body::-webkit-scrollbar {
  width: 3px;
}

/* Track */
.preview-body::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.preview-body::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
.preview-body::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.preview-form-title {
  margin: 20px 0 30px;
}

.ck.ck-editor__editable_inline {
  margin: 50px 0;
}

.ck.ck-editor__editable:not(.ck-editor__nested-editable).ck-focused {
  border: none !important;
  box-shadow: none !important;
}

.ck.ck-editor__editable_inline > :last-child {
  margin-bottom: 0 !important;
}

.ck.ck-editor__editable_inline > :first-child {
  margin-top: 0 !important;
}

.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}

/* Preview Radio, Checkbox, Multiple Choice */
.new__form__builder__preview__body .ar__form_global_control {
  margin-bottom: 20px !important;
  display: flex !important;
}

.ar__form_global_control > legend {
  font-size: 18px;
  margin-bottom: 10px;
  font-weight: 700;
}

.ar__form_global_control label span.MuiButtonBase-root {
  padding-top: 4px !important;
  padding-bottom: 4px !important;
}

.ar__form_global_control .ar__flex_column {
  flex-direction: row;
}

.ar__form_global_control.ar__multiple .ar__flex_column {
  display: inline-flex;
  flex-direction: column;
}

.ar__form_global_control.ar__multiple .ar__flex_column button {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  min-width: 200px;
  justify-content: flex-start !important;
  box-shadow: unset !important;
}

.new__form__builder__preview__body .ar__form_global_control {
  margin-bottom: 20px !important;
  display: flex !important;
}

.ar__form_global_control.ar__multiple
  .ar__flex_column
  button.MuiButton-contained {
  background: #3f51b5;
}

.ar__form_global_control.ar__multiple
  .ar__flex_column
  button.MuiButton-contained
  span
  span {
  background: #ddd;
}

.ar__flex_column .choice-list-icon {
  width: 25px;
  height: 25px;
  margin-right: 10px;
}

.ar__form_global_control.ar__multiple .ar__flex_column button + button {
  margin-top: 10px;
}

.ar__form_global_control.ar__multiple .ar__flex_column button span span {
  background: #3f51b552;
  padding: 0 8px;
  margin-right: 10px;
  border-radius: 4px;
  color: #222 !important;
}

.ar__form_global_control.ar__dropdown {
  padding: 8px 12px;
  margin: 30px 0;
}

.ar__form_global_control.ar__dropdown fieldset {
  border: 0;
  background: transparent;
}

.new__form__builder__preview__body form form {
  margin: 40px 0 10px;
  position: relative;
}

.ar__specific_input {
  position: relative;
}

.ar__req {
  position: absolute;
  right: -5px;
  font-size: 25px;
  color: #c00;
  top: 5px;
}
.new__form__builder__preview__body form form .ar__req {
  top: -12px;
}
.textareaAlt .ar__req {
  top: 3px;
}

.textarea2 .ar__req {
  top: -6px;
}

.textareaAlt3 .ar__req {
  top: -15px;
}

.alter.customRow span.ar__req {
  top: -3px;
}

.alternative span.ar__req {
  top: -12px;
}

.ar__dropdown2 .ar__req,
.singleItem span.ar__req {
  top: -13px;
}

.singleItem.dropdown_block span.ar__req {
  top: 13px;
  z-index: 9;
}

.ar__form_global_control legend.MuiFormLabel-root {
  position: relative;
}

.ar__form_global_control legend.MuiFormLabel-root .ar__req {
  top: -3px;
  right: -15px;
}

.new__form__builder__preview__body .ar__form_global_control legend {
  position: relative;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.MuiFormControl-root.ar__dropdown.ar__dropdown2 .MuiInputLabel-formControl {
  top: -7px;
}

.MuiFormControl-root.ar__dropdown.ar__dropdown2
  .MuiInputLabel-formControl.MuiFormLabel-filled {
  top: -15px !important;
  left: 0px !important;
}

.specific__input__field__wr {
  transition: all 0.3s linear;
}

.specific__input__field__wr.decrease__opacity {
  opacity: 0.35;
}

.preview__date_field .MuiFormControl-root.MuiTextField-root.MuiFormControl-marginNormal {
  width: 100%;
}

.preview__date_field button.MuiButtonBase-root.MuiIconButton-root{
  position: absolute;
  top: -4px;
  right: 0;
}

.singleItem.date-field .preview__date_field svg{
  position: unset;
}
.singleItem.date-field .preview__date_field span.ar__req {
  top: 15px !important;
}
.singleItem.date-field .preview__date_field .MuiInputAdornment-root.MuiInputAdornment-positionEnd{
  margin-left: 0px !important;
}

.preview__date_field button:focus{
  background-color: rgba(0,0,0,0.0) !important ;
}

.preview__date_field .MuiFormControl-root.MuiFormControl-marginNormal {
  margin-top: 0;
  margin-bottom: 0;
}

button.new__form__builder__preview__submit__btn.center {
  margin: 0 auto;
  display: block;
}

button.new__form__builder__preview__submit__btn.right {
  margin-left: auto;
}

button.new__form__builder__preview__submit__btn.MuiButton-sizeLarge {
  font-size: 1.3rem;
}

button.new__form__builder__preview__submit__btn.MuiButton-sizeMedium {
  font-size: 0.95rem;
}

button.new__form__builder__preview__submit__btn.MuiButton-sizeSmall {
  font-size: 0.75rem;
}

.FormBuilderModule-container-parent-class .min-h-100vh {
  min-height: 100vh;
}

.FormBuilderModule-container-parent-class .form__builder__background__image__section {
  background-size: cover;
  background-repeat: no-repeat;
}

/* progressbar designs */
.progressbar__preview-container {
  background-color: #F6F7FB;
  width: 100%;
  height: 10px;
  border-radius: 10px;
  box-shadow: 0 0 0px 1px #ddd;
}

.progressbar__preview-container .progressbar__preview-bar {
  display: block !important;
  height: 10px;
  border-radius: 10px;
}

.progressbar__preview-container .progressbar__tooltip {
  border-radius: 4px;
  display: inline-block;
  padding: 8px;
  font-size: 14px;
  line-height: 16px;
  margin-top: 10px;
  position: relative;
}
.progressbar__preview-container .progressbar__tooltip::before{
  position: absolute;
  left: 50%;
  top: -6px;
  margin-left: -8px;
  content: '';
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
}

.new__form__builder__preview__body .progressbar__preview-container {
  margin-bottom: 65px;
}

.new__form__builder__preview__body {
  overflow-y: unset;
}

.ar__flex_column .fb__choice_icon{
  width: 40px;
  height: 40px;
}
html,
body {
  font-family: "Roboto Condensed", sans-serif;
}
ul {
  list-style: none;
  margin: 0;
}

.FormBuilderModule-container-parent-class .header-area {
    display: flex;
    justify-content: center;
    align-items: center;
    position: -webkit-sticky;
    position: sticky;
    z-index: 999;
    height: 60px;
    border-bottom: 1px solid transparent;
    box-shadow: rgb(60 66 87 / 4%) 0 2px 5px 0;
    background: #fff;
    box-sizing: border-box;
    /* margin: -16px -20px 0; */
}

.header-area ul {
  display: flex;
  grid-gap: 10px;
}
.header-area .newFormBuilderBack,
.header-middle ul li {
    transition: all 0.4s;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 40px;
    padding: 5px 8px;
    font-size: 11px;
    color: rgb(119, 119, 119);
    margin: 0;
    cursor: pointer;
    border-radius: 5px;
}
.header-middle ul li + li:before {
    background: #dddddd7d;
    height: 70%;
    display: block;
    content: '';
    position: absolute;
    width: 1px;
    left: -6px;
}
.header-area .newFormBuilderBack > svg,
.header-middle ul li > svg {
    display: block;
    margin-bottom: 6px;
    color: rgb(119, 119, 119);
}

.header-area .newFormBuilderBack {
    color: rgb(119, 119, 119);
    position: absolute;
    left: 8px;
}

.header-middle ul li a {
  text-decoration: none;
  color: black;
}
.header-area .newFormBuilderBack:hover,
.header-middle ul li:hover {
    background-color: rgb(245, 245, 245);
    color: rgb(68, 68, 68);
}
.header-area .newFormBuilderBack:hover > svg,
.header-middle ul li:hover > svg {
    color: rgb(68, 68, 68);
}

.header-middle ul li span {
  display: block;
  font-size: 0.8rem;
}

/* li.form-save-button:active:after {
  box-shadow: 0 0 0 0 white;
  position: absolute;
  border-radius: 4em;
  left: 0;
  top: 0;
  opacity: 1;
  transition: 0s;
} */

li.form-save-button {
  box-shadow: 0 0 0 0 #c9c9c9;
  -webkit-animation: pulsing 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  animation: pulsing 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
}

/* Animation */

@-webkit-keyframes pulsing {
  to {
      box-shadow: 0 0 0 30px rgba(232, 76, 61, 0);
  }
}

@keyframes pulsing {
  to {
      box-shadow: 0 0 0 30px rgba(232, 76, 61, 0);
  }
}

.add_form_title_editor_wrapper .mce-content-body:focus-visible{
  outline: 0;
}
.setting_container.custom-form-setting form .disable-row .input-field + .input-field label,
.custom-form-setting .row:not(.switcher-row) .input-field.col label.active {
    top: -10px !important;
}

.setting_container.custom-form-setting {
    height: auto;
    padding: 50px 0 20px;
}
.custom-form-setting .button-row {
    padding-left: .75rem
}
.custom-form-setting button { 
    border: 0;
    padding: 12px 15px;
    border-radius: 4px;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
}

.setting_container.custom-form-setting form .row {
    margin-bottom: 30px;
}

.setting_container.custom-form-setting form .action-row .input-field + .input-field {
    margin-top: 12px !important;
}

.setting_container.custom-form-setting form .disable-row {
    padding-top: 20px !important;
}

.setting_container.custom-form-setting form .disable-row textarea {
    resize: none;
    background: #F6F7FB;
    border: 1px solid #E5E8EF;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 10px 12px;
    min-height: 100px;
    outline: 0 !important;
    box-shadow: unset !important;
}

.form-settings-form-builder.setting_container .disable-row .input-field + .input-field {
    margin: 40px 0 0 !important;
}


.setting_container.custom-form-setting label.active {
    color: #222;
}

@media(max-width: 1366px) {
.form__builder__modal.form_builder_modal_settings_v2.ar__form_builder .modal__body__area__v2.ar__modal_bodyV2 input, 
.form__builder__modal.form_builder_modal_settings_v2.ar__form_builder .modal__body__area__v2.ar__modal_bodyV2 select {
    padding: 8.5px 15px !important;
    height: auto !important;
    font-size: 10px;
    margin: 0 !important;
    outline: 0 !important;
    box-shadow: unset !important;
}
.form__builder__modal.form_builder_modal_settings_v2.ar__form_builder .modal__body__area__v2.ar__modal_bodyV2 select {
    height: 25px !important;
    padding: 5px 15px 0 15px !important;
}

.form__builder__modal.form_builder_modal_settings_v2 .setting_container.custom-form-setting form .row {
    margin-bottom: 5px;
}

.form__builder__modal.form_builder_modal_settings_v2 .modal__body__area__v2 .form-settings-form-builder.setting_container .input-field {
    margin: 0 !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.form__builder__modal.form_builder_modal_settings_v2 .modal__body__area__v2 .form-settings-form-builder.setting_container .action-row .input-field + .input-field {
    margin-top: 6px !important;
}

.form__builder__modal.form_builder_modal_settings_v2 .custom-form-setting button {
    padding: 4px 15px;
    border-radius: 4px;
    font-size: 14px;
}

.form__builder__modal.form_builder_modal_settings_v2 .setting_container.custom-form-setting form .row:last-child {
    margin: 0;
}

.setting_container.custom-form-setting label {
    position: static !important;
    -webkit-transform: unset !important;
            transform: unset !important;
    font-size: 10px;
    margin-bottom: 5px;
    color: #222;
}

.form__builder__modal.form_builder_modal_settings_v2 .setting_container.custom-form-setting form .disable-row {
    padding-top: 0 !important;
}

.form__builder__modal.form_builder_modal_settings_v2 .setting_container.custom-form-setting {
    padding: 15px 0 35px 0;
}

.form__builder__modal.form_builder_modal_settings_v2.ar__form_builder .modal__body__area__v2.ar__modal_bodyV2 input:focus, 
.form__builder__modal.form_builder_modal_settings_v2.ar__form_builder .modal__body__area__v2.ar__modal_bodyV2 select:focus {
    border: 1px solid #E5E8EF !important;
}

.form__builder__modal.form_builder_modal_settings_v2 .form-settings-form-builder.setting_container .disable-row .input-field + .input-field {
    margin: 5px 0 !important;
}

.form__builder__modal.form_builder_modal_settings_v2 .setting_container.custom-form-setting form .disable-row textarea {
    padding: 8.5px 15px !important;
    font-size: 10px;
    min-height: 50px;
}

.form__builder__modal.form_builder_modal_settings_v2 .custom-switch .react-switch-handle {
    height: 12px !important;
    width: 12px !important;
}

.form__builder__modal.form_builder_modal_settings_v2 .custom-switch .react-switch-bg {
    height: 20px !important;
}

.form__builder__modal.form_builder_modal_settings_v2 .custom-switch .react-switch-bg > div {
    height: 20px !important;
}

.form__builder__modal.form_builder_modal_settings_v2 .custom-switch-on-icon, .custom-switch-off-icon {
    font-size: 12px !important;
}
}
.mapping__select__wrapper .MuiSelect-select{
    border: 1px solid #ddd;
    padding: 10px 0 10px 12px;
    width: 100%;
    color: #8b8383;

}
.maping__table__wrapper .MuiPaper-root{
    box-shadow: 0 0 2px #aaa;
}
.mapping__select__wrapper>div{
    width: 74% ;
}

.mapping__select__wrapper{
    display: flex;
    align-items: center;
    grid-gap: 10px;
}
.mapping__select__wrapper .maping__select__arrow svg{
    color: #8b8383;
}

.maping__table__wrapper .MuiTableHead-root .MuiTableCell-head{
    color: #000;
    font-weight: 600 !important;
    font-size: 15px;
}
.maping__table__wrapper .MuiTableBody-root .MuiTableCell-head{
    color: #000;
    font-weight: 400px;
    font-size: 15px;
}
/* .maping__table__wrapper tr,
.maping__table__wrapper td,
.maping__table__wrapper th
{
    border-bottom: 0;
} */

.mapping__enabled_button_wrapper{
    padding: 20px;
}

.mapping__enabled_button_wrapper .mapping__enabled_button {
    display: inline-block;
    padding: 15px 60px 14px 10px;
    border-radius: 5px;
    box-shadow: 0 0 5px #ddd;
}


.custom-switch .react-switch-bg {
    display: flex;
}
.custom-switch-off-icon{
    padding: 0 6px 0 0;
}
.custom-switch-on-icon{
    padding: 4px;
}
.custom-switch-active-mode .react-switch-handle {
    top: 4px !important;
}
.custom-switch-inactive-mode .react-switch-bg {
    border: 1px solid #e1e5ec !important;
    background-color: #e1e5ec !important;
}
.custom-switch-inactive-mode .react-switch-handle{
    top: 4px !important;
    left: 2px
}

.custom-switch .react-switch-handle {
    height: 16px !important;
    width: 16px !important;
}

.form__style__body__wrapper {
    padding: 60px 90px;
}

.form__style__body__wrapper .form__style__each__item {
    border-bottom: 1px solid rgb(238, 238, 238);
    padding-bottom: 15px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 20px;
}

.form__style__body__wrapper .form__style__each__item .form__style__each__item__heading {
    width: 90%;
}

.form__style__body__wrapper .form__style__each__item .form__style__each__item__custom__switch {
    width: 10%;
}
.form__style__body__wrapper .form__style__each__item .form__style__each__item__custom__switch div {
    float: right
}
.form__style__body__wrapper .form__style__each__item .form__style__each__item__custom__switch .custom-switch-inactive-mode div {
    display: flex;
    justify-content: end;   
}
.form__style__body__wrapper .form__style__each__item .form__style__each__item__custom__switch .custom-switch-active-mode div {
    display: flex;
    justify-content: start;   
}
.form__style__body__wrapper .form__style__each__item .form__style__each__item__heading h1 {
    font-size: 16px;
    color: rgb(55, 53, 47);
    font-weight: bold;
}

.form__style__body__wrapper .form__style__each__item .form__style__each__item__heading p {
    font-size: 14px;
    line-height: 1.5;
    color: rgb(136, 136, 136);
}
.form__background__wr,
.form__animation_acc__wr {
  width: 100%;
}
.form__background__wr .MuiAccordion-root,
.form__animation_acc__wr .MuiAccordion-root{
  box-shadow: 0 0 0 rgba(0,0,0,0.0) !important;
}
.form__background__wr .MuiAccordion-root .MuiAccordionSummary-root,
.form__animation_acc__wr .MuiAccordion-root .MuiAccordionSummary-root{
  padding: 0;
}
.form__background__wr .MuiAccordion-root .MuiAccordionSummary-root .MuiIconButton-label svg,
.form__animation_acc__wr .MuiAccordion-root .MuiAccordionSummary-root .MuiIconButton-label svg{
  color:rgb(55, 53, 47)
}

.form__background__wr .MuiAccordion-root .MuiAccordionSummary-root .MuiAccordionSummary-expandIcon:hover,
.form__animation_acc__wr .MuiAccordion-root .MuiAccordionSummary-root .MuiAccordionSummary-expandIcon:hover {
  background-color: unset !important;
}
.form__background__wr .MuiAccordionSummary-root .Mui-focused:focus,
.form__background__wr .MuiIconButton-root:focus,
.form__animation_acc__wr .MuiAccordionSummary-root .Mui-focused:focus,
.form__animation_acc__wr .MuiIconButton-root:focus{
  color: rgba(0,0,0,0.0) !important;
}

.form__background__wr .form__background__heading,
.form__animation_acc__wr .form__animation__heading {
  font-size: 16px;
  color: rgb(55, 53, 47);
  font-weight: bold;
}

.form__background__wr .form__background__details {
  padding: 0 !important;
}

.form__background__wr .form__background__details .form__style__background__tabs {
  display: flex;
  flex-grow: 1;
  background-color: #fff;
  width: 100%;
}
.form__background__wr .form__background__details .form__style__background__tabs .MuiBox-root{
  padding: 12px 24px 24px 24px !important;
  height: 300px;
  overflow-y: auto;
  position: relative;
}

.form__background__details .MuiAppBar-colorPrimary {
  color: #000;
  background-color: #fff;
}

.form__style__background__tab__section button:focus {
  background-color: #fff;
}

/* Tab style */
.form__style__background__tabs .form__style__background__tab__section__with__remove{
  display: flex;
  justify-content: space-between;
  position: relative;

}
.form__style__background__tabs .form__style__background__tab__section__with__remove:after{
  position: absolute;
  width: 100%;
  content: " ";
  height: 2px;
  background: #ddd;
  left: 0;
  bottom: 10px;
  z-index: -1;

}
.form__style__background__tab__section .MuiTabs-root{
  min-height: unset !important;
}
.form__style__background__tabs .form__style__background__remove__section{
  display: flex;
  align-items: center;
  grid-gap: 5px;
  cursor: pointer;
  background: #ff0000;
  margin: 6px 10px 0 0;
  padding: 1px 5px;
  height: 34px;
  border-radius: 6px;
  color: #fff;
}

.form__style__background__tab__section{
  width: 70%;
}
.form__style__background__tab__section .MuiButtonBase-root{
  min-width: unset !important;
  padding: 0;
  margin: 6px 16px;
  text-transform: capitalize;
}
.form__style__background__tab__section .MuiTabs-fixed .MuiTabs-flexContainer +span{
  background-color: rgb(55, 53, 47) !important;
  bottom: 10px !important
}
.form__style__background__tabs .form__style__background__remove__section span{
  display: inline-flex;
}


/* upload section css */
.file__upload__wrapper__v2 {
  display: grid;
  grid-template-columns: repeat(3,1fr);
  grid-gap: 16px;
}
.file__upload__wrapper__v2 .file__upload__signle__v2{
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 32px 0;
  display: flex;
  flex-direction: column;
}

.file__upload__wrapper__v2 .file__upload__signle__v2.pexel{
  background: rgba(5, 160, 129, 0.1);
  border: 1px dashed #05A081;
  border-radius: 5px;
}

.file__upload__wrapper__v2 .file__upload__signle__v2.canva{
  background: rgba(32, 196, 203, 0.1);
  border: 1px dashed #7D2AE8;
  border-radius: 5px;
}

.file__upload__wrapper__v2 .file__upload__signle__v2.file__upload{
  background: rgba(60, 126, 243, 0.1);
  border: 1px dashed #3C7EF3;
  border-radius: 5px;
}

.file__upload__wrapper__v2 .file__upload__signle__v2.file__drag__zone{
  background: rgba(155, 159, 179, 0.3);
  border: 2px dashed rgb(104, 115, 141);
  border-radius: 5px;
}

.file__upload__wrapper__v2__top {
  display: flex;
  align-items: center;
  grid-gap: 8px;
  margin: 0 0 40px 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  color: #546376;
}
.file__upload__wrapper__v2__top span {
  display: inline-flex;
}
.file__upload__wrapper__v2 .file__upload__signle__v2 h4{
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  color: #133159;
  margin: 5px 0 20px;

}
.file__upload__wrapper__v2 .file__upload__signle__v2 .file__upload__signle__v2__drag__title{
  margin: 3px 0 7px 0;
}
.file__upload__wrapper__v2 .file__upload__signle__v2 .file__upload__signle__v2__drag__sub__title{
  margin: 0 0 5px 0;
}
/* .file__upload__wrapper__v2 .file__upload__signle__v2 span{} */
.file__upload__wrapper__v2 .file__upload__signle__v2 button{
  border: 0;
  color: #fff;
  padding: 12px 56px;
  border-radius: 5px;
  cursor: pointer;
}
.file__upload__wrapper__v2 .file__upload__signle__v2 button.pixel__for__upload__btn{
  background: #05A081;
}
.file__upload__wrapper__v2 .file__upload__signle__v2 button.file__for__upload__btn{
  background: #3C7EF3;
}
.file__upload__wrapper__v2 .file__upload__signle__v2 button.canva__for__upload__btn{
  background: #7D2AE8;
}

/* tab link css */
.form__style__background__tabs__link form{
  text-align: center;
}
/* .form__tabs__link__input{} */
.form__tabs__link__button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: 1px solid var(--dark_blue);
  background: var(--dark_blue);
  color: #fff;
  padding: 9px 16px;
  border-radius: 4px;
  margin: 13px 0 0 0;
  cursor: pointer;
}

/* tab color css */
.form__fixed__background{
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  grid-gap: 5px;
}
.form__fixed__background .form__fixed__background__single {
  height: 50px;
  border-radius: 2px;
  cursor: pointer;
  box-shadow: 1px 1px 0px #bbb;
}

.form__fixed__background .form__fixed__background__single.active {
  box-shadow: 0px 0px 2px #000 !important;
}

.form__fixed__background .form__fixed__background__single svg {
  fill: #2ecc71 !important;
  float: right;
  width: 20px;
  height: 20px;
}

/* Canva */
.canva__alert__wr .canva__alert__title {
  margin: 20px 0;
  font-size: 16px;
  font-weight: bold;
  line-height: 16px;
  color: rgb(55, 53, 47);
  display: grid;
  place-items: center;
}

.canva__alert__wr .canva__alert__note {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  padding: 0 20px;
}

.canva__alert__wr .canva__alert__image__section {
  margin-top: 10px;
}

.canva__alert__wr .canva__alert__btn__section {
  display:grid;
  place-items: center;
  margin-bottom: 15px;
}

.canva__alert__wr .canva__alert__btn {
  padding: 7px 15px;
  background: #3C7EF3;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.canva__alert__wr .canva__alert__btn span{
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
}

.position-static {
  position: static;
}

.form__style__background__tabs__link button:focus.form__tabs__link__button {
  background-color: var(--dark_blue);
}

/* Pexel */
.pexel__start__wr .pexel__start__top__section {
  display: flex;
  justify-content: space-between;
}

.pexel__start__wr .pexel__start__top__section .form_builder__pexels_search {
  flex: 0.5 1;
}

.pexel__start__wr .pexel__start__top__section .form_builder__pexels__back__button {
  display: flex;
  align-items: center;
  grid-gap: 5px;
  cursor: pointer;
  background: #3C7EF3;
  padding: 7px 15px;
  border-radius: 6px;
  border: none;
  height: 34px;
}

.pexel__start__wr .pexel__start__top__section .form_builder__pexels__back__button span {
  display: inline-flex;
  color: #fff;
}
.pexel__start__wr .pexel__start__top__section .form_builder__pexels__back__button svg {
  width: 18px;
  height: 18px;
}

.pexel__start__wr .pexel__start__top__section .form_builder__pexels__back__button svg path {
  fill: #fff;
}

.form_builder__pexels_search input {
  border: 1px solid #9da6b1 !important;
  border-radius: 6px !important;
  height: 34px !important;
}

.form_builder__pexels_search input:focus {
  box-shadow: unset !important;
}

.form_builder__pexels_search i {
  top: 2px !important;
}

/* Pexel Image */
.form__pexel__image__section__wr{
  margin: 20px 0;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  grid-gap: 5px;
}
.form__pexel__image__section__wr .form__pexel__image__section{
  width: 100%;
  cursor: pointer;
}

.form__pexel__image__section__wr .form__pexel__image__section .form__pexel__image {
  border-radius: 5px;
  width: 100px;
  height: 130px;
}

/*  Pexel Image Skeleton */
.pexel__start__wr .pexel__image__skeleton__wr {
  margin: 20px 0;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  grid-gap: 5px;
}

.pexel__start__wr .pexel__image__skeleton__wr .skeleton__each__image__section {
  width: 100%;
}

.pexel__start__wr .pexel__image__skeleton__wr .skeleton__each__image__section .skeleton__each__image{
  border-radius: 5px;
  width: 100px;
  height: 130px;
}

/*  No Image Found */
.pexel__start__wr .pexel__no__image__found__section {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}


.form__animation__wr {
  width: 100%;
}

.form__animation__wr .form__animation__heading {
  font-size: 16px;
  color: rgb(55, 53, 47);
  font-weight: bold;
}

.form__animation__dropdown {
  display: inline-flex;
  float: right;
}

.form__animation__dropdown .MuiSelect-select:focus {
  box-shadow: 0 0 0 rgba(0, 0, 0, 0) !important;
  border-color: #ddd;
  width: 88px;
}

.form__animation__dropdown .MuiSelect-select {
  border-color: #ddd;
  width: 88px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.form__animation__acc .form__animation_list .each_animation_option img{
  height: 200px;
  width: 200px;
  margin-bottom: 10px;
}

.form__animation__acc .form__animation_list .each_animation_option {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 0 8px 1px #eee;
  margin: 5px;
  padding: 5px;
  padding-bottom: 20px;
  cursor: pointer;
  border-radius: 8px;
  position: relative;
}

.form__animation__acc .form__animation_list .each_animation_option svg {
  position: absolute;
  right: 8px;
  top: 8px;
  height: 30px;
  width: 30px;
}

.form__animation__acc .form__animation_list .each_animation_option:hover {
  box-shadow: 0 0 8px 1px rgb(114, 114, 114);
}

.form__animation__acc .form__animation_list {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
}

.all__action__buttons {
  display: flex;
  grid-column-gap: 10px;
  -webkit-column-gap: 10px;
          column-gap: 10px;
  justify-content: flex-start;
}

.all__action__buttons .MuiButtonBase-root {
  background-color: rgba(0, 0, 0, 0.04);
}

.each_functionality {
  display: flex;
  flex-wrap: wrap;
  flex: 100 1;
  padding: 6px 0;
  min-width: 355px;
}
.each_functionality h5 {
  flex: 70 1;
}

.functionality_button {
  flex: 30 1;
}

.input__with__buttons div:empty,
.all__action__buttons button + div:empty {
  display: none;
}

.ar__specific_input input[type="email"]:focus,
.ar__specific_input input[type="tel"]:focus,
.ar__specific_input input[type="text"]:focus {
  outline: 0;
  box-shadow: rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px,
    rgb(0 0 0 / 12%) 0px 1px 1px 0px, rgb(60 66 87 / 16%) 0px 0px 0px 1px,
    rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px,
    rgb(60 66 87 / 8%) 0px 2px 5px 0px !important;
  border: 0 !important;
}

.ar__add_an .specific__input__field__wr__left {
  margin-top: 6px;
  position: relative;
}

.ar__add_an input {
  padding: 5px;
  box-shadow: rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px,
    rgb(0 0 0 / 12%) 0px 1px 1px 0px, rgb(60 66 87 / 16%) 0px 0px 0px 1px,
    rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px,
    rgb(60 66 87 / 8%) 0px 2px 5px 0px;
  border: 0px;
  border-radius: 5px;
  background: rgba(245, 245, 245, 0.376);
  font-size: 14px;
  padding-left: 35px;
  padding-right: 10px;
  cursor: pointer;
}

.ar__add_an input:focus {
  outline: 0;
}

.ar__add_an svg {
  width: 16px;
  height: 16px;
  min-width: 16px;
  min-height: 16px;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  left: 12px;
}

/* Radio */
.single-input-area.inserted__fieldLevel {
  display: flex;
  align-items: center;
}

body .single-input-area.inserted__fieldLevel input {
  margin: 0 10px !important;
}

.specific__input__field__wr__left.ar__specific_input input {
  height: 30px !important;
  border: 0 !important;
  outline: 0 !important;
}

.form__builder__modal.ar__options_modal
  .modal__body__area__v2.ar__modal_bodyV2 {
  padding: 30px;
  max-width: 415px;
}

.form__builder__modal.ar__options_modal
  .modal__body__area__v2.ar__modal_bodyV2
  h6 {
  margin-bottom: 10px;
  border-bottom: 1px solid #dddddd91;
  padding-bottom: 6px;
  font-weight: 600;
}

.form__builder__modal.ar__options_modal .each_functionality h5 {
  font-size: 16px;
  margin-right: 15px;
}

.ar__fullWidth_input input {
  margin: 0 !important;
}

.ar__fullWidth_input {
  display: flex;
  flex: 0 0 100%;
  margin-top: 12px;
}

.ar__fullWidth_input input {
  border: 1px solid #ddd !important;
  border-radius: 6px !important;
  padding: 8px 12px !important;
  box-shadow: unset !important;
}

.modal__body__area__v2.ar__modal_bodyV2 input[type="color"] {
  position: relative;
  border: 0;
  cursor: pointer;
  box-shadow: 0 0 1px #999;
}

.modal__body__area__v2.ar__modal_bodyV2 input[type="color"]:after {
  display: inline-block;
  content: "";
  background-color: inherit;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.toggle-container {
  width: 70px;
  background-color: #dfe4ea;
  cursor: pointer;
  -webkit-user-select: none;
          user-select: none;
  border-radius: 18px;
  height: 25px;
  position: relative;
}

.dialog-button {
  font-size: 14px;
  line-height: 16px;
  font-weight: bold;
  cursor: pointer;
  background-color: #2ecc71;
  color: white;
  padding: 4.5px 12px;
  border-radius: 30px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  min-width: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 38px;
  min-width: unset;
  border-radius: 18px;
  box-sizing: border-box;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  position: absolute;
  left: 34px;
  transition: all 0.3s ease;
}

.disabled {
  background-color: #e74c3c;
  left: 2px;
}

.tippy-box[data-animation=shift-toward][data-state=hidden]{opacity:0}.tippy-box[data-animation=shift-toward][data-state=hidden][data-placement^=top]{transform:translateY(-10px)}.tippy-box[data-animation=shift-toward][data-state=hidden][data-placement^=bottom]{transform:translateY(10px)}.tippy-box[data-animation=shift-toward][data-state=hidden][data-placement^=left]{transform:translateX(-10px)}.tippy-box[data-animation=shift-toward][data-state=hidden][data-placement^=right]{transform:translateX(10px)}
.tippy-box[data-animation=fade][data-state=hidden]{opacity:0}[data-tippy-root]{max-width:calc(100vw - 10px)}.tippy-box{position:relative;background-color:#333;color:#fff;border-radius:4px;font-size:14px;line-height:1.4;white-space:normal;outline:0;transition-property:transform,visibility,opacity}.tippy-box[data-placement^=top]>.tippy-arrow{bottom:0}.tippy-box[data-placement^=top]>.tippy-arrow:before{bottom:-7px;left:0;border-width:8px 8px 0;border-top-color:initial;transform-origin:center top}.tippy-box[data-placement^=bottom]>.tippy-arrow{top:0}.tippy-box[data-placement^=bottom]>.tippy-arrow:before{top:-7px;left:0;border-width:0 8px 8px;border-bottom-color:initial;transform-origin:center bottom}.tippy-box[data-placement^=left]>.tippy-arrow{right:0}.tippy-box[data-placement^=left]>.tippy-arrow:before{border-width:8px 0 8px 8px;border-left-color:initial;right:-7px;transform-origin:center left}.tippy-box[data-placement^=right]>.tippy-arrow{left:0}.tippy-box[data-placement^=right]>.tippy-arrow:before{left:-7px;border-width:8px 8px 8px 0;border-right-color:initial;transform-origin:center right}.tippy-box[data-inertia][data-state=visible]{transition-timing-function:cubic-bezier(.54,1.5,.38,1.11)}.tippy-arrow{width:16px;height:16px;color:#333}.tippy-arrow:before{content:"";position:absolute;border-color:transparent;border-style:solid}.tippy-content{position:relative;padding:5px 9px;z-index:1}
.tippy-box[data-theme~=light]{color:#26323d;box-shadow:0 0 20px 4px rgba(154,161,177,.15),0 4px 80px -8px rgba(36,40,47,.25),0 4px 4px -2px rgba(91,94,105,.15);background-color:#fff}.tippy-box[data-theme~=light][data-placement^=top]>.tippy-arrow:before{border-top-color:#fff}.tippy-box[data-theme~=light][data-placement^=bottom]>.tippy-arrow:before{border-bottom-color:#fff}.tippy-box[data-theme~=light][data-placement^=left]>.tippy-arrow:before{border-left-color:#fff}.tippy-box[data-theme~=light][data-placement^=right]>.tippy-arrow:before{border-right-color:#fff}.tippy-box[data-theme~=light]>.tippy-backdrop{background-color:#fff}.tippy-box[data-theme~=light]>.tippy-svg-arrow{fill:#fff}
.action__button__submit {
  display: flex;
  grid-gap: 10px;
  padding: 20px 0;
}

.action__button__submit .MuiButtonBase-root {
  background-color: rgba(0, 0, 0, 0.04);
}

.button-input-field.mt-1 input[type="color" i]::-webkit-color-swatch {
  background: inherit !important;
  border: 0;
}

.choice-icon-picker-modal.ar__upload-modal .modal__body__area__v2.ar__modal_bodyV2 {
	max-width: 750px;
	background: #f6f7fb;
	border-radius: 10px;
}

.choice-icon-picker-modal header {
	padding: 20px 20px 10px;
	border-bottom: 2px solid #fff;
	width: 100%;
	box-sizing: border-box;
}

.choice-icon-picker-modal header h3 {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 500;
	font-size: 20px;
	line-height: 30px;
	display: flex;
	align-items: center;
	letter-spacing: 0.01em;
	color: #133159;
}

.choice-icon-picker-modal .modal__body__area__v2 .form__builder__modal__add__close__button {
	background: #ff264a;
	color: #fff;
	top: 20px;
	right: 20px;
}

.choice-icon-picker-modal .cipm-body-wrapper {
	margin: 20px;
	background: #fff;
	border: 1px solid #e5e8ef;
	box-sizing: border-box;
	border-radius: 5px;
	width: calc(100% - 40px);
}

.choice-icon-picker-modal .search-form-group {
	background: #f6f7fb;
	border: 1px solid rgba(60, 126, 243, 0.1);
	box-sizing: border-box;
	border-radius: 5px;
	padding: 13px 20px;
	margin-bottom: 24px;
}

.choice-icon-picker-modal .search-form-group svg {
	float: right;
	margin-top: -20px;
	margin-right: 0px;
	color: #546376;
}

.choice-icon-picker-modal .search-form-group input {
	border: 0;
	box-shadow: unset;
	margin: 0;
	padding: 0 !important;
	display: block;
	height: auto;
	font-family: "Poppins";
	font-weight: 400;
	font-size: 16px;
	line-height: 24px;
	color: rgba(19, 49, 89, 0.3);
}

.choice-icon-picker-modal .search-form-group input:focus {
	outline: 0;
	border: 0 !important;
	box-shadow: unset !important;
}

.choice-icon-picker-modal .MuiTabs-scroller.MuiTabs-fixed {
	border-bottom: 1px solid #e5e8ef;
}
.choice-icon-picker-modal .MuiTabs-scroller.MuiTabs-fixed .MuiTabs-indicator {
	display: none;
}

.choice-icon-picker-modal .MuiTabs-scroller.MuiTabs-fixed button {
	font-family: "Poppins" !important;
	font-style: normal;
	font-weight: 400 !important;
	font-size: 16px;
	line-height: 24px;
	color: #546376;
	padding: 20px;
	opacity: 1;
	text-transform: capitalize;
	border-bottom: 1px solid transparent;
}
.choice-icon-picker-modal .MuiTabs-scroller.MuiTabs-fixed button:focus {
	background-color: transparent;
	color: #133159;
}
.choice-icon-picker-modal .MuiTabs-scroller.MuiTabs-fixed button svg {
	margin: 0 8px 0 0 !important;
}

.choice-icon-picker-modal .MuiTabs-scroller.MuiTabs-fixed button.Mui-selected svg {
	color: #3c7ef3;
}

.choice-icon-picker-modal button .MuiTab-wrapper {
	flex-direction: row;
}

.choice-icon-picker-modal button.Mui-selected {
	border-bottom-color: #3c7ef3 !important;
}

.choice-icon-picker-modal .ifo-icon-wrapper {
	display: flex;
	flex-wrap: wrap;
	overflow-y: auto;
	height: 250px;
}

.cipm-tab-panel-wrapper {
	min-height: 380px;
}

.choice-icon-picker-modal .ifo-icon-wrapper span {
	background: #ffffff;
	border: 1px solid rgba(60, 126, 243, 0.1);
	box-sizing: border-box;
	border-radius: 5px;
	padding: 12.5px;
	margin: 0 12px 12px 0;
	max-height: 60px;
	max-width: 60px;
	text-align: center;
	line-height: 60px;
	display: inline-block;
	transition: all 0.4s ease-in-out;
	cursor: pointer;
}
.choice-icon-picker-modal .ifo-icon-wrapper span:hover {
	background-color: #f6f7fb;
	border-color: #3c7ef3;
}

.choice-icon-picker-modal .ifo-icon-wrapper span img {
	width: 100%;
	height: auto;
}

.choice-icon-picker-modal .awesome__scroll_bar::-webkit-scrollbar {
	width: 8px;
}

.cipm-body-wrapper .file__upload__wrapper__v2__top {
	margin-bottom: 24px;
}

/* Upload Tab */
.cipm-body-wrapper .file__upload__wrapper__v2__top {
	margin-bottom: 24px;
}

.cipm-body-wrapper .file__upload__wrapper__v2 .file__upload__signle__v2 button {
	border: 0 !important;
	padding: 12px;
	border-radius: 4px;
	font-size: 16px;
	box-shadow: unset !important;
	min-width: 180px;
	margin-top: auto;
	font-weight: 600;
}

.cipm-body-wrapper .file__upload__wrapper__v2 .file__upload__signle__v2 {
	background: #dddddd26;
	padding: 12px;
	min-height: 180px;
}
.cipm-body-wrapper .form__style__background__tabs__link input.form__tabs__link__input {
	box-shadow: unset !important;
	margin: 0 !important;
}

.cipm-body-wrapper .form__style__background__tabs__link input.form__tabs__link__input {
	box-shadow: unset !important;
	margin: 0 !important;
	border: 0 !important;
}

.cipm-body-wrapper .form__style__background__tabs__link input.form__tabs__link__input:focus {
	box-shadow: unset !important;
	margin: 0 !important;
}

/* Add Icon Button */
.specific__input__field__wr__left .mc-single-input-area .cipm-button {
	font-family: "Poppins";
	box-shadow: unset !important;
	font-weight: 400;
	font-size: 16px;
	line-height: 24px;
	color: #133159;
	text-transform: capitalize;
	border-radius: 5px 0 0 5px;
	background: #e5e8ef;
	padding: 10px 12px;
	margin: 0 !important;
	min-height: 50px;
}

.specific__input__field__wr__left .mc-single-input-area .cipm-button:hover {
	box-shadow: unset;
}

.mc-single-input-area.single-input-area.inserted__fieldLevel input {
	width: calc(100% - 221px);
	min-height: 50px;
	border: 0 !important;
	margin: 0 20px 0 0 !important;
	border-top-left-radius: 0 !important;
	border-bottom-left-radius: 0;
	box-shadow: unset;
	background: #f6f7fb;
	font-weight: 400;
	font-size: 16px;
	line-height: 24px;
	color: rgb(84 99 118 / 50%);
	text-transform: capitalize;
	padding: 13px 20px;
}

.mc-single-input-area.single-input-area.inserted__fieldLevel input:focus {
	box-shadow: unset !important;
	border: 0 !important;
}
.mc-single-input-area .action-buttons button {
	min-height: 44px;
}

.specific__input__field__wr__left .mc-single-input-area > p#multiple-choice {
	width: 35px;
}

.mc-single-input-area .action-buttons {
	height: auto;
}

.mc-single-input-area .action-buttons button {
	min-height: 50px;
	background: #f6f7fb;
	border-radius: 4px;
	margin: 0 !important;
	min-width: 50px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.mc-single-input-area.single-input-area.inserted__fieldLevel {
    margin: 17px 0 !important;
}

.file__upload__wrapper__v2.choice__upload_wrapper {
	display: block;
}

/*  Icon Finder Skeleton */
.choice-icon-picker-modal .icon__finder__skeleton__wr {
  margin: 20px 0;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(60px, 1fr));
  grid-gap: 5px;
}

.choice-icon-picker-modal .icon__finder__skeleton__wr .skeleton__each__icon__section {
  width: 100%;
}

.choice-icon-picker-modal .icon__finder__skeleton__wr .skeleton__each__icon__section .skeleton__each__icon{
  border-radius: 5px;
  width: 60px;
  height: 60px;
}

/*  No Icon Found */
.choice-icon-picker-modal .no__icon__found__section {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.uploaded__file__name__v1 {
  width: 80px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 1.3rem;
  margin: 0;
}
.heading_1 {
  font-weight: bolder !important;
  border: none !important;
  outline: none !important;
  background: none !important;
  /* box-sizing: unset !important;
  border-radius: unset !important; */
}

.heading_2 {
  font-weight: bolder !important;
  border: none !important;
  outline: none !important;
  background: none !important;
}

.heading_3 {
  font-weight: bolder !important;
  border: none !important;
  outline: none !important;
  background: none !important;
}

.label {
  font-size: 16px !important;
  font-weight: bolder !important;
  border: none !important;
  outline: none !important;
  background: none !important;
}

.texts {
  font-size: 16px !important;
  font-weight: normal !important;
  border: none !important;
  outline: none !important;
  background: none !important;
}

.specific__input__field__wr__left.ar__specific_input input.ar__title_heading {
  height: auto !important;
  padding: 6px 15px;
  box-shadow: none !important;
  font-size: 30px;
  font-weight: 600;
  line-height: 1.25;
  border: 0 !important;
}

.specific__input__field__wr__left.ar__specific_input input.heading_2 {
  font-size: 24px;
}

.specific__input__field__wr__left.ar__specific_input input.heading_3 {
  font-size: 20px;
}

.thankyou-area {
    text-align: center;
    padding: 100px 0;
}

.thankyou-area svg {
    font-size: 3em;
    color: tomato;
}

.thankyou-area h3 {
    color: rgb(36, 32, 43);
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
}

.thankyou-area p {
    padding: 0;
    margin: 0;
}

.thankyou-area a.btn {
    background: #0d6efd;
    box-shadow: none;
    border-radius: 6px;
    margin-top: 20px;
    transition: 500ms;
}

.thankyou-area a:hover {
    background-color: rgb(18, 16, 22);
}

.thankyou-area h3+p {
    margin: 10px 0;
}
.specific__input__field__wr {
  display: flex;
  grid-column-gap: 10px;
  -webkit-column-gap: 10px;
          column-gap: 10px;
  align-items: center;
  position: relative;
}

.specific__input__field__wr__left {
  flex-basis: 100%;
  margin-top: -6px;
}

.ar__specific_input label.input__label {
  margin-bottom: 3px;
  display: block;
  color: #333;
}

.input__with__buttons {
  margin: 15px 0;
}

.specific__input__field__wr__left input {
  /* height: 36px !important; */
  height: 46px !important;
}

/* .input__label {
  font-weight: bold;
} */

.ar__body_container_form_builder .button-input-field {
  width: 100%;
}

.ar__body_container_form_builder .button-input-field label {
  width: 100%;
  padding: 0;
  height: 22px;
  font-size: 12px;
  line-height: 1.5;
  text-transform: uppercase;
  color: rgb(93 85 85 / 80%);
  box-sizing: border-box;
  font-weight: 400;
  -webkit-user-select: none;
          user-select: none;
  display: block;
}

.ar__body_container_form_builder .button-input-field input {
  width: 100%;
  height: 36px;
  padding: 0px 10px;
  box-shadow: rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 12%) 0px 1px 1px 0px, rgb(60 66 87 / 16%) 0px 0px 0px 1px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(60 66 87 / 8%) 0px 2px 5px 0px;
  border: 0px;
  border-radius: 5px;
  outline: none;
  background-color: rgb(255, 255, 255);
}


.ar__body_container_form_builder .tippy-content {
  padding: 10px 10px;
}

.ar__body_container_form_builder .tippy-box[data-theme~=light] {
  color: #26323d;
  box-shadow: 0 0 20px 4px rgba(154, 161, 177, .15), 0 4px 80px -8px rgba(36, 40, 47, .25), 0 4px 4px -2px rgba(91, 94, 105, .15);
  background-color: #fff;
}

.ar__body_container_form_builder .ar__tippy {
  overflow: hidden;
  background-color: rgb(255, 255, 255);
  border-radius: 5px;
  box-shadow: rgb(60 66 87 / 5%) 0px 0px 0px 1px, rgb(60 66 87 / 10%) 0px 3px 6px, rgb(60 66 87 / 20%) 0px 9px 24px;
  max-width: 300px;
  width: 100%;
}

.FormBuilderModule-container-parent-class {
  background: #fff;
  height: auto;
  min-height: 100%;
}

.mr-10 {
  margin-right: 10px;
}

button.ar__send_button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border: 0px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  background: rgb(0, 0, 0);
  outline: none;
  transition: color 100ms ease-in 0s;
  text-align: left;
  color: rgb(255, 255, 255);
  font-weight: bold;
  padding: 15px;
}

.all__action__buttons button.MuiButton-root,
.ar__action_button_submit button.MuiButton-root {
  min-width: 50px;
  padding: 13px 15px;
  min-height: 50px;
}

.all__action__buttons button.MuiButton-root:hover,
.ar__action_button_submit button.MuiButton-root:hover {
  background: #00000066;
  color: #000;
}


.ar__action_button_submit button.MuiButton-root.ar__send_button:hover {
  color: #fff;
  background: rgba(0, 0, 0, 0.336);
}

.all__action__buttons .edit_input__option-button {
  min-width: 50px;
  padding: 13px 15px;
  min-height: 50px;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.04);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
}

.all__action__buttons .edit_input__option-button:hover {
  background: #00000066;
  color: #000;
}



.ar__specific_input input[type="text"],
.ar__specific_input input[type="number"],
.ar__specific_input textarea,
.ar__specific_input input[type="tel"],
.ar__specific_input input[type="date"],
.ar__specific_input input[type="time"],
.ar__specific_input input[type="email"] {
  padding: 0px 10px;
  box-shadow: rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 12%) 0px 1px 1px 0px, rgb(60 66 87 / 16%) 0px 0px 0px 1px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(60 66 87 / 8%) 0px 2px 5px 0px;
  border-radius: 5px;
  background-color: rgb(255, 255, 255);
  height: 30px !important;
  margin: 0 !important;
  border: 0 !important;
}
.ar__specific_input label.input__label.ar__input__label input {
    height: 30px !important;
}
.ar__specific_input textarea {
    resize: none;
    padding-top: 7px;
    height: unset !important;
    min-height: 60px;
}

.ar__specific_input.alt input {
    height: 46px !important;
    margin-top: 8px !important;
}
.ar__specific_input input[type="time"]:focus {
  box-shadow: rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 12%) 0px 1px 1px 0px, rgb(60 66 87 / 16%) 0px 0px 0px 1px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(60 66 87 / 8%) 0px 2px 5px 0px;
}
.ar__specific_input textarea:focus {
  outline: 0;
}
.customRow {
  display: flex;
  grid-column-gap: 10px;
  -webkit-column-gap: 10px;
          column-gap: 10px;
  align-items: center;
}

.ar__upload_logo{
  flex: 1 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    min-height: 150px;
    padding: 20px;
    border: 1px dashed rgb(193, 201, 210);
    border-radius: 5px;
    text-align: center;
    cursor: pointer;
}

.ar__multiple_checkbox button {
   position: relative;
   display: flex;
   padding: 8px 10px;
   box-shadow: rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 12%) 0px 1px 1px 0px, rgb(60 66 87 / 16%) 0px 0px 0px 1px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(60 66 87 / 8%) 0px 2px 5px 0px;
   border-radius: 5px;
   background-color: rgb(255, 255, 255);
   cursor: pointer;
   border: 0;
}

.ar__multiple_checkbox button p {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 18px;
    height: 18px;
    min-width: 18px;
    min-height: 18px;
    background-color: rgb(102, 102, 102);
    font-size: 12px;
    font-weight: 900;
    line-height: 1;
    color: rgb(255, 255, 255);
    border-radius: 3px;
    margin-right: 10px;
}

.ar__file_uploads.file-upload-wrapper {
       position: relative;
       padding: 20px;
       cursor: pointer;
       border: 1px dashed rgb(193, 201, 210);
       border-left: 1.7px dashed rgb(193, 201, 210);
       border-radius: 5px;
       box-sizing: border-box;
       width: calc(100% - 5px);
}



.ar___embed_modal .modal__body__area__v2.ar__modal_bodyV2 input + button {
    padding: 8px 12px;
    border: 0px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    background: rgb(0, 0, 0);
    outline: none;
    transition: color 100ms ease-in 0s;
    color: rgb(255, 255, 255);
    font-weight: bold;
}

.ar___embed_modal .modal__body__area__v2.ar__modal_bodyV2 b {
    padding: 12px 0px;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    margin-bottom: -1px;
    text-decoration: none;
    white-space: nowrap;
    color: rgb(0, 0, 0);
    border-bottom: 2px solid rgb(0, 0, 0);
    margin-right: auto;
}

.ar___embed_modal .modal__body__area__v2.ar__modal_bodyV2 input {
    width: 100%;
    height: 36px;
    padding: 0px 10px;
    box-shadow: rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 12%) 0px 1px 1px 0px, rgb(60 66 87 / 16%) 0px 0px 0px 1px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(60 66 87 / 8%) 0px 2px 5px 0px !important;
    border: 0 !important;
    border-radius: 5px;
    background-color: rgb(255, 255, 255);
    margin: 20px 0;
    outline: 0;
}

.ar___embed_modal .modal__body__area__v2.ar__modal_bodyV2 {
    padding: 20px 40px;
    box-sizing: border-box;
}

/* UPload logo */
.ar__upload-modal .ar__modal_bodyV2 h2 {
    font-size: 26px;
}

.ar__upload-modal .form-area {
    display: flex;
    flex-direction: column;
}

.ar__upload-modal label {
    margin-top: 25px;
}

.ar__upload-modal .form-area .img-label svg {
    font-size: 40px;
}

.ar__upload-modal .form-area input[type="file"] {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    margin: 0 !important;
    opacity: 0;
    cursor: pointer;
}
.ar__upload-modal .modal__body__area__v2.ar__modal_bodyV2 {
    min-height: 250px;
    max-width: 550px;
}
.ar__upload-modal .form-area div > svg {
    font-size: 25px;
    margin-right: 12px;
}

.ar__input_group_item textarea,
.ar__input_group_item input {
    padding-right: 35px !important;
}

.ar__input_group_item textarea + svg, .ar__input_group_item input + svg {
    position: absolute;
    right: 10px;
    top: 50% !important;
    opacity: 0.85;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
}

.ar__input_group_item.ar__input_group_item_sa input + svg {
    top: 6px !important;
    -webkit-transform: unset;
            transform: unset;
}
.ar__input_group_item.ar__input_group_item_la input + svg {
    top: px !important;
    -webkit-transform: unset;
            transform: unset;
}
body .specific__input__field__wr__left.ar__specific_input input.ppfb__label_input {
    all: unset !important;
    font-weight: 400 !important;
    opacity: 1 !important;
    border: 0 !important;
    height: auto !important;
    margin: 0 0 5px !important;
    padding: 0 !important;
    box-shadow: unset !important;
    font-size: 15px !important;
    cursor: text !important;
}

body .specific__input__field__wr__left.ar__specific_input input.ppfb__label_input::-webkit-input-placeholder {
  color: #d6d3d2 !important;
}

body .specific__input__field__wr__left.ar__specific_input input.ppfb__label_input::placeholder {
  color: #d6d3d2 !important;
}

.ar__specific_input label.input__label.ar__input__label input.ppfb__label_input + input {
  margin-top: 3px !important;
}
.body__container__form__builder .textarea2 .ar__req,
.body__container__form__builder .alter.customRow span.ar__req {
  top: 11px;
}

.ar__input_group_item input.ppfb__label_input + input + svg {
  top: 65% !important;
}

.body__container__form__builder .textareaAlt3 .ar__req {
    top: 13px;
}

.body__container__form__builder .ar__nmbr.alter.customRow span.ar__req {
    top: 5px !important;
}

.body__container__form__builder .specific__input__field__wr__left.ar__specific_input.ar__input_group_item.ar__nmbr + span.ar__req {
    top: 11px !important;
}

.ar_shrtans .ar__input_group_item input.ppfb__label_input + input + svg {
    top: 50% !important;
}

.ar__input_group_item.ar__nmbr input.ppfb__label_input + input + svg {
  top: 72% !important;
}

body .ar__lanswer .ar__input_group_item textarea + svg {
    top: 60px !important;
}
.FormBuilderModule-container-parent-class .file__upload__with__label + .ar__req {
  position: absolute;
  right: -5px;
  font-size: 25px;
  color: #c00;
  top: 35px;
}

.FormBuilderModule-container-parent-class .file__upload__with__label .file__upload__with__label__input {
  position: relative;
  top: 20px;
}

.form__builder__background__image__section .body__edit_progress_bar{
  margin: auto;
  width: 60%;
}

.FormBuilderModule-container-parent-class .formbuilder-checkbox label {
    margin-left: -17px;
    padding-left: 17px;
}
.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.form__builder__modal.modal {
  overflow-y: unset;
  overflow-x: unset;
}
.form__builder__modal .form__builder__modal__sidebar {
  overflow-y: auto;
  max-height: 100%;
  box-sizing: border-box;
}
/* .form__builder__modal .form__builder__modal__sidebar__single__wr h3 {
  padding: 0px 10px;
  font-size: 11px;
  line-height: 1.5;
  font-weight: bold;
  text-transform: uppercase;
  color: rgba(136, 136, 136, 0.8);
} */

body .form__builder__modal__sidebar__single__wr .subtitle {
    padding: 0 16px;
    margin-bottom: 0;
    font-size: 12px;
    line-height: 1.5;
    font-weight: bold;
    text-transform: uppercase;
    color: rgba(136, 136, 136, 0.8);
    margin-top: 40px;
    -webkit-user-select: none;
    user-select: none;
    font-weight: 600;
}


.form__builder__modal .form__builder__modal__sidebar__single__li {
    display: flex;
    align-items: center;
    padding: 8.5px 16px;
    -webkit-user-select: none;
            user-select: none;
    transition: all 120ms ease-in 0s;
    cursor: pointer;
    font-size: 14px;
    font-weight: 300;
}
.form__builder__modal .form__builder__modal__sidebar__single__li.active {
    background: rgb(245, 245, 245);
}

.form__builder__modal .form__builder__modal__sidebar__single__li svg {
    margin-right: 10px;
} 

.form__builder__modal .form__builder__modal__sidebar__single__li svg {
  color: rgb(136, 136, 136);
}
.form__builder__modal .form__builder__modal__sidebar__single__li:hover {
  background: rgb(245, 245, 245);
}
.form__builder__modal__sidebar__single__wr {
  margin: 16px 0 0 0;
}

.ul-area li {
  transition: 500ms;
  cursor: pointer;
  padding: 10px 5px;
  border-bottom: 1px dashed aliceblue;
}
.ul-area li:hover {
  background-color: aliceblue;
}

.initial__content__logical__demo__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.initial__content__logical__demo__content svg {
  font-size: 18px;
  color: #fff;
}
.initial__content__logical__demo__content
  span.initial__content__logical__demo__add {
  color: #fff;
  background: rgb(248, 28, 229);
  height: 30px;
  width: 30px !important;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.initial__content__logical__demo__content h5 {
  margin: 20px 0;
  font-size: 16px;
  font-weight: 600;
}

.initial__content__logical__demo__content h5 + p {
      max-width: 280px;
    color: rgb(68, 68, 68);
    text-align: center;
    font-size: 14px;
    line-height: 2;
    -webkit-user-select: none;
            user-select: none;
    font-weight: 300;
}

.initial__content__logical__demo__content {
  color: rgb(68, 68, 68);
  text-align: center;
  font-size: 14px;
  line-height: 2;
}

.example-area {
  border-left: 1px solid rgba(0, 0, 0, 0.5);
}

.logical__demo__content__right__v2 {
	padding: 20px;
}

.logical__demo__content__right__v2 .logical__demo__content__right__top__v2 {
	display: flex;
	justify-content: space-between;
	padding-bottom: 50px;
}

.logical__demo__content__right__v2 .logical__demo__content__right__right button {
	text-transform: capitalize;
	cursor: pointer;
	font-size: 14px;
	font-weight: 700;
	color: rgb(255, 255, 255);
	background-color: rgb(248, 28, 229);
	transition: all 0.4s;
}

.logical__demo__content__right__v2 .logical__demo__content__right__right button.Mui-disabled {
	background-color: rgb(248, 28, 229,0.4);
}

.logical__demo__content__middle__label {
	padding-top: 40px;
}

.logical__demo__content__right__v2 .logical__demo__content__right__right button:hover {
	opacity: 0.75;
}

.logical__demo__content__right__v2 .logical__demo__content__right__right .MuiButton-label {
	display: flex;
	grid-gap: 5px;
}

.logical__demo__content__right__v2 .logical__demo__content__right__left h5 {
	font-size: 20px;
	margin-bottom: 10px;
	font-weight: 700;
	color: rgb(55, 53, 47);
}

.logical__demo__content__right__v2 .logical__demo__content__right__left {
	margin: 0 40px 0 0;
}

.logical__demo__content__right__v2 .logical__demo__content__right__left p {
	color: rgb(68, 68, 68);
	font-size: 14px;
	line-height: 1.5;
}

.logical__demo__content__right__v2 .logical__demo__content__middle__v2 {
	/* padding: 25px 0; */
	border-top: 1px solid rgb(238, 238, 238);
	position: relative;
}

.logical__demo__content__right__v2 .logical__demo__content__middle__heading__v2 {
	display: inline-block;
	padding: 2px 4px;
	border-radius: 4px;
	font-size: 11px;
	font-weight: bold;
	line-height: 1.15;
	text-transform: uppercase;
	color: rgb(136, 136, 136);
	background: rgba(136, 136, 136, 0.125);
	position: absolute;
	top: -9px;
	left: 20px;
}

.logical__demo__content__middle__v2 .logical__demo__content__middle__label .input__label {
	font-size: 14px;
	font-weight: bold;
	line-height: 1.618;
	color: rgb(55, 53, 47);
}

.logical__demo__content__middle__v2 .logical__demo__content__middle__label.alt .input__label input,
.logical__demo__content__middle__v2 .logical__demo__content__middle__label.alt .input__label textarea {
	margin-top: 5px;
	font-weight: 300;
	font-size: 16px;
	color: #535343 !important;
}

.logical__demo__content__middle__v2 .logical__demo__content__middle__label.alt input[type="text"],
.logical__demo__content__middle__v2 .logical__demo__content__middle__label.alt input[type="email"],
.logical__demo__content__middle__v2 .logical__demo__content__middle__label.alt input[type="tel"],
.logical__demo__content__middle__v2 .logical__demo__content__middle__label.alt input[type="date"] {
	background: #fff !important;
	border: 1px solid #e5e8ef;
	box-sizing: border-box;
	border-radius: 5px;
	padding: 10px 12px;
	box-shadow: unset !important;
	font-weight: 300 !important;
}

.logical__demo__content__middle__v2 .logical__demo__content__middle__label.alt .input__label textarea::-webkit-input-placeholder, .logical__demo__content__middle__v2 .logical__demo__content__middle__label.alt input[type="text"]::-webkit-input-placeholder, .logical__demo__content__middle__v2 .logical__demo__content__middle__label.alt input[type="email"]::-webkit-input-placeholder, .logical__demo__content__middle__v2 .logical__demo__content__middle__label.alt input[type="tel"]::-webkit-input-placeholder, .logical__demo__content__middle__v2 .logical__demo__content__middle__label.alt input[type="date"]::-webkit-input-placeholder {
	color: #535353 !important;
}

.logical__demo__content__middle__v2 .logical__demo__content__middle__label.alt .input__label textarea::placeholder,
.logical__demo__content__middle__v2 .logical__demo__content__middle__label.alt input[type="text"]::placeholder,
.logical__demo__content__middle__v2 .logical__demo__content__middle__label.alt input[type="email"]::placeholder,
.logical__demo__content__middle__v2 .logical__demo__content__middle__label.alt input[type="tel"]::placeholder,
.logical__demo__content__middle__v2 .logical__demo__content__middle__label.alt input[type="date"]::placeholder {
	color: #535353 !important;
}

.ar__form_builder .logical__demo__content textarea {
	padding: 10px;
	border: 1px solid #ddd !important;
	border-radius: 5px;
	resize: none;
	min-height: 120px;
	box-shadow: unset !important;
	outline: 0;
}

.mt-40px {
	margin-top: 40px !important;
}

.ar__customFormGroup input {
	border: 1px solid #e5e8ef !important;
	outline: 0 !important;
	box-shadow: unset !important;
	color: #535343 !important;
	font-weight: 300 !important;
	background: #fff !important;
	border-radius: 5px !important;
	padding: 10px 12px !important;
}

fieldset.MuiFormControl-root .Mui-checked + span {
	color: #514f4a;
}

fieldset.MuiFormControl-root .Mui-checked {
	color: #514f4a;
}

.ar__customFormGroup input::-webkit-input-placeholder {
	color: #535343 !important;
}

.ar__customFormGroup input::placeholder {
	color: #535343 !important;
}

.ar__form_builder .logical__demo__content textarea:focus {
	outline: 0;
}

.logical__demo__content__middle__v2 .logical__demo__content__middle__label.alt input[type="text"]:focus,
.logical__demo__content__middle__v2 .logical__demo__content__middle__label.alt input[type="email"]:focus {
	box-shadow: unset;
}

.ar__form_builder p.text-muted {
	font-size: 12px;
	font-weight: 400;
	font-style: italic;
	margin: 8px 0;
}

/* .logical-content .top-content {
  display: flex;
  justify-content: space-between;
}
.logical-content .top-content .block-left h5 {
  font-weight: 700;
}
.logical-content .top-content .block-left {
  width: 70%;
}

.logical-content .bottom-content {
  padding: 30px 0;
  border-top: 1px solid rgba(0, 0, 0, 0.15);
  margin-top: 20px;
}

.logical-content .bottom-content h6 {
  font-weight: 700;
  color: rgb(48, 48, 48);
}
.logical-content .bottom-content small {
  color: black;
  font-weight: 700;
}
.loveFeedback {
  font-weight: bold;
}
.minutes {
  color: red;
}
.newPageSample {
  background-color: gray;
  height: 80px !important;
  margin-bottom: 20px;
}

.newPageSample h4 {
  text-align: center;
}

.embed__image {
  width: 400px;
  height: 260px;
} */

.MuiFormControl-root.ar__dropdown {
	width: 100%;
	border: 1px solid #e5e8ef;
	border-radius: 5px;
	padding: 12px;
	box-sizing: border-box;
}

.MuiFormControl-root.ar__dropdown label + .MuiInput-formControl {
	margin-top: 0;
}

.MuiFormControl-root.ar__dropdown .MuiSelect-select.MuiSelect-select {
	padding: 0;
}

.MuiFormControl-root.ar__dropdown .MuiInputLabel-formControl {
	top: -12px;
	left: 10px;
	background: #fff;
}

.MuiFormControl-root.ar__dropdown .MuiInputLabel-formControl.MuiFormLabel-filled {
	top: -8px;
}

.ar__heading {
	margin: 40px 0 0;
}
.ar__heading h1,
.ar__heading h2,
.ar__heading h3 {
	margin-bottom: 12px;
	font-size: 30px;
	line-height: 32px;
}

.logical__demo__content__middle__label.alt.v2 label {
	font-weight: 400;
	color: #000;
	margin-bottom: 5px;
	display: block;
	font-size: 14px;
}

.logical__demo__content__middle__label.alt.v2 input + label {
	margin-top: 10px;
}

.logical__demo__content__middle__label.alt.v3 p {
	font-size: 14px;
	font-weight: 300;
}

.logical__demo__content__middle__label.alt.v3 p span {
	font-weight: 600;
}

.logical__demo__content__middle__label.alt.v3 p span.minutes {
	background: #e873e575;
	font-weight: 400;
	padding: 0 3px;
}

.newPageSample {
	background: #f5f5f5;
	border-radius: 5px;
	max-width: 250px;
	display: flex;
	align-items: center;
	justify-content: center;
	min-height: 80px;
	font-size: 18px;
	-webkit-user-select: none;
	        user-select: none;
}

.newPageSample + .newPageSample {
	margin-top: 12px;
}

.newPageSample h4 {
	font-size: inherit;
	font-weight: inherit;
	line-height: inherit;
	color: #a1a1a1;
	text-transform: capitalize;
	text-align: center;
}

img.embed__image {
	max-width: 250px;
}

/* Multiple Choice Layout */
.logical__demo__content__middle__v2.multiple_choice_wrapper h6.logical__demo__content__middle__heading__v2::before {
	content: "Select a View";
	font-size: 20px;
	visibility: visible;
	display: block;
	text-transform: capitalize;
}

.logical__demo__content__middle__v2.multiple_choice_wrapper h6.logical__demo__content__middle__heading__v2 {
    visibility: hidden;
    top: -36px;
    left: -4px;
    color: #e873e5;
    right: 0;
    text-align: center;
}

.multiple-choice-grid-wrapper {
	margin-top: 20px;
}

.multiple-choice-grid-wrapper .MuiGrid-item {
	border: 1px solid #efefef;
	border-radius: 8px;
	padding: 20px 10px 0;
	max-width: 48%;
	flex-basis: 48%;
	background: #fff;
	cursor: pointer;
}

.multiple-choice-grid-wrapper .MuiGrid-item + .MuiGrid-item {
	margin-left: 10px;
}

.multiple-choice-grid-wrapper .MuiGrid-item .mb-3 {
	margin-top: 10px !important;
	margin-bottom: 0 !important;
}

.multiple-choice-grid-wrapper .MuiGrid-item h6 {
	font-size: 16px;
	font-weight: 400;
}

.multiple-choice-grid-wrapper .MuiGrid-item.active {
	background: #d0deeb;
}

.logical__demo__content__middle__v2.multiple_choice_wrapper {
	margin-top: -12px;
	border-top: none;
}

.multiple-choice-grid-wrapper .ar__multiple_checkbox button {
	min-width: 110px;
	box-shadow: unset !important;
	border: 1px solid #ddd !important;
}

.multiple-choice-grid-wrapper .MuiGrid-item .mb-3.mt-40px {
	margin-top: 0 !important;
}

.multiple-choice-grid-wrapper .grid_view_col_6 button {
  flex-direction: column;
  align-items: center;
  border: 1px solid #546376 !important;
  border-radius: 5px;
  box-shadow: unset !important;
  outline: 0 !important;
  max-width: 65px;
  max-height: 65px;
  justify-content: center;
  padding: 5px 12px;
  margin: 0 12px 12px 0;
  box-sizing: border-box;
  min-width: unset;
}

.multiple-choice-grid-wrapper .grid_view_col_6 button span { 
	font-weight: 400;
	font-size: 16px;
	line-height: 24px;
  display: block;
	color: #546376;
}

.multiple-choice-grid-wrapper .grid_view_col_6 button svg {
  margin-top: 10px;
  width: 24px;
  height: 24px;
}

.multiple-choice-grid-wrapper .grid_view_col_6 .mb-3 {
	display: flex;
	flex-wrap: wrap;
}


/* Preview View Style */
.ar__flex_column.pfb-preview-form-builder button {
    background: #fff;
    border-radius: 5px;
    border: 1px solid rgba(60, 126, 243, 0.1);
    width: 128px !important;
    height: 128px;
    display: inline-flex;
    margin: 0 24px 24px 0 !important;
    min-width: unset !important;
	box-sizing: border-box;
}
.ar__flex_column.pfb-preview-form-builder button:focus {
	background: #2ab7a9;
}
.ar__flex_column.pfb-preview-form-builder button:hover {
    border-color:  #3C7EF3;
    background: #F6F7FB;
}

.ar__flex_column.pfb-preview-form-builder button span {
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.ar__form_global_control.ar__multiple .ar__flex_column.pfb-preview-form-builder button span span {
    margin: 0 0 16px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;   
    color: #546376 !important;
    background: transparent;
    text-transform: capitalize;
}

.ar__form_global_control.ar__multiple .ar__flex_column.pfb-preview-form-builder button.MuiButton-contained span span {
	color: #fff !important;
}

.ar__form_global_control.ar__multiple .ar__flex_column.pfb-preview-form-builder button span svg {
    width: 40px;
    height: 40px;
}

.ar__form_global_control.ar__multiple .ar__flex_column.pfb-preview-form-builder {
    flex-wrap: wrap;
    flex-direction: row;
    max-width: 304px;
    justify-content: center;
}

.ar__form_global_control.ar__multiple .ar__flex_column.pfb-preview-form-builder button + button {
    margin: 0;
}

.new__form__builder__preview__body .pmc_ar__multiple legend {
    margin-bottom: 16px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px; 
    text-transform: capitalize; 
    color: #133159;
}
.form__logo__upload__wr {
    width: 100%;
    padding: 30px 50px;
}

.form__logo__upload__wr .logo__upload__top__section{
  display: flex;
  align-items: center;
  margin-bottom: 24px;
}

.form__logo__upload__wr .logo__upload__size__section {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.form__logo__upload__wr .logo__upload__justify__between{
  justify-content: space-between;
}
.form__logo__upload__wr .logo__upload__justify__center{
  justify-content: center;
}

/*  Warning */

.form__logo__upload__wr .logo__upload__wrapper__v2__top {
    display: flex;
    align-items: center;
    grid-gap: 8px;
    margin: 0 0 24px 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    color: #546376;
}

.form__logo__upload__wr .logo__upload__wrapper__v2__top span {
    display: inline-flex;
}

/*  Size Dropdown */

.form__logo__upload__wr .logo__upload__size__section .logo__upload__size__dropdown{
    width: 30%;
}

.form__logo__upload__wr .logo__upload__size__section .logo__upload__size__dropdown span {
    font-size: 16px;
    color: rgb(55, 53, 47);
    font-weight: bold;
}

.form__logo__upload__wr .logo__upload__size__section .logo__upload__size__dropdown {
    display: inline-flex;
    float: right;
}

.form__logo__upload__wr .logo__upload__size__section .logo__upload__size__dropdown .MuiInputBase-root{
  width: 100%;
}

.form__logo__upload__wr .logo__upload__size__section .logo__upload__size__dropdown .MuiSelect-select:focus {
  box-shadow: 0 0 0 rgba(0, 0, 0, 0) !important;
  border-color: #ddd;
  width: 100%;
}

.form__logo__upload__wr .logo__upload__size__section .logo__upload__size__dropdown .MuiSelect-select {
  border-color: #ddd;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

/* Remove Logo */
.form__logo__upload__wr .logo__upload__top__remove__section{
  display: flex;
  align-items: center;
  grid-gap: 5px;
  cursor: pointer;
  background: #ff264a; 
  padding: 1px 8px;
  height: 34px;
  border-radius: 6px;
  color: #fff;
  font-size: 14px;
  font-weight: 400;
}

.form__logo__upload__wr .logo__upload__top__left {
  display: flex;
  align-items: center;
  grid-gap: 9px;
  justify-content: center;
}

.form__logo__upload__wr .logo__upload__top__left span {
  font-size: 18px;
  font-weight: 600;
}

.form__logo__upload__wr .load__upload__fill__with__background__section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0;
}
.form__logo__upload__wr .uploader__loader{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.form__logo__upload__wr .logo__upload__button__section {
  background: var(--dark_blue);
  max-width: 180px;
  width: 100%;
  color: #fff;
  border-radius: 6px;
  grid-gap: 10px;
  justify-content: center;
  height: 40px;
  align-items: center;
  text-align: center;
  display: flex;
  margin: 16px auto 0 auto;
  cursor: pointer;
}
.upload-modal .modal-header h2 {
  font-size: 1.5rem;
  text-transform: capitalize;
}
.upload-modal .modal-header svg {
  color: tomato;
  font-size: 1.5rem;
  margin-right: 5px;
}
.upload-modal .btn-close {
  background-color: tomato;
}

/* Upload Image */
.modal__body__area__v2 {
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  width: 100%;
  max-width: 715px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  box-sizing: border-box;
  border-radius: 6px;
  box-shadow: rgb(0 0 0 / 20%) 0 15px 25px 0;
}

.form__builder__modal.ar__form_builder > div {
    background-color: rgba(27, 31, 36, 0.5) !important;
}

.modal__body__area__v2 .form__builder__modal__add__close__button {
  position: absolute;
  right: -11px;
  top: -12px;
  z-index: 9;
  background: #ddd;
  height: 26px;
  border-radius: 50%;
  min-width: 26px;
  max-width: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  padding: 0;
}
.modal__body__area__v2 .form__builder__modal__add__close__button:hover {
  background: #ddd;
}
.modal__body__area__v2 .form__builder__modal__add__close__button svg {
  font-size: 18px;
}
.modal__body__area__v2 .form__builder__modal__add__search__bar {
  width: 100%;
}
.modal__body__area__v2 .display__input__field__modal__wr {
  width: 100%;
  padding: 0 0 24px 0;
  display: flex;
  height: 480px;
  overflow-y: auto;
}
.modal__body__area__v2 .display__input__field__modal__left {
  width: 100%;
  max-width: 249px;
  border-right: 1px solid #ddd;
}
.modal__body__area__v2 .logical__demo__content {
  width: 100%;
  overflow-y: auto;
  max-height: calc(100vh - 150px);
  box-sizing: border-box;
}

.modal-body-area > div {
  width: 100%;
  border-radius: 10px;
  padding: 10px;
}
.form-area {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.form-area .img-label svg {
  cursor: pointer;
  font-size: 4.5rem;
  transition: 500ms;
  color: rgb(28, 29, 32);
}

#uploadNow {
  display: none;
}

.form-area input[type="file"] {
  display: none;
}
.preview {
  border: 1px dashed rgba(0, 0, 0.5);
  padding: 5px;
}
.preview img {
  width: 100%;
}

.ar__form_builder .form__builder__modal .form__builder__modal__sidebar { 
    max-height: 100%; 
}

.ar__form_builder .makeStyles-search-1 .MuiInputBase-root .MuiInputBase-input {
    height: 60px;
    padding-left: 38px;
    border-radius: 0;
    font-weight: 300;
    font-size: 16px;
    padding-top: 10px;
    box-sizing: border-box;
    border-top-left-radius: 6px;
}

.ar__form_builder .makeStyles-search-1 .MuiInputBase-root .MuiInputBase-input:focus {
    box-shadow: unset;
    border-bottom-color: rgb(238, 238, 238);
}

.ar__modal_bodyV2 .form-area .fb__image-info-modal {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 20px;
  color: red;
  font-size: 14px;
}


.cover-area {
  width: 100%;
  height: 170px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
  /* background-color: #116dfa47; */
}
.cover-area img {
  max-width: 100%;
  height: 100%;
  object-fit: cover;
  width: 100%;
}
.cover-area .action-button {
  position: absolute;
  right: 20px;
  bottom: 20px;
  border-radius: 4px;
}
.cover-area .action-button span {
  color: #fff;
  font-weight: 600;
  text-transform: capitalize;
}

.cover-area .action-button button {
  transition: 500ms;
  cursor: pointer;
  border: none;
  display: inline-flex;
  align-items: center;
  background: #0d6efd;

}

.cover-area .action-button button:hover {
  background-color: tomato;
}

.cover-area .action-button button > span > svg{
  margin-right: 8px;
}

.logo-container > div {
  position: relative;
  height: 100px;
}
.logo-area {
  width: 250px;
  /* height: 100px; */
  display: inline-flex;
  justify-content: center;
  align-items: center;
  /* overflow: hidden; */
  /* border-radius: 100px; */
  /* background-color: #f6f6f6; */
  position: relative;
}
.preview__top__fixed{
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 333;
}
.preview__top__fixed + .new__form__builder__preview__body{
  padding: 210px 10px 0 10px;
}
.logo-area .form__logo__delete{
  position: absolute;
    border: 1px solid transparent;
    right: 2px;
    top: -26px;
    z-index: 77;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    color: #f00;
    background: #fff;
    box-shadow: 1px 1px 10px rgb(0 0 0 / 50%);
    cursor: pointer;

}
.bouncing {
  -webkit-animation: 2s bounce infinite;
          animation: 2s bounce infinite;
}
@-webkit-keyframes bounce {
  50% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
  }
}
@keyframes bounce {
  50% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
  }
}
.logo-area img {
  width: 100%;
  max-width: 150px;
  height: auto;

}

/*  */
.ar__formControlLabel_grid button {
  min-width: 50px;
  margin-left: 10px;
}

.ar__formControlLabel {
  display: flex;
  align-items: center;
  margin: 0 !important;
}

.ar__formControlLabel_grid .col-lg-10 {
  width: calc(100% - 62px);
}

.ar__formControlLabel_grid {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex: 1 1;
  width: 100%;
}

.ar__formControlLabel > span {
  padding: 0px ​4px 4p;
}

label.MuiFormControlLabel-root.ar__formControlLabel {
  width: 100%;
}
span.MuiTypography-root.MuiFormControlLabel-label.MuiTypography-body1 {
  width: 100% !important;
  padding-top: 0;
  padding-bottom: 0;
}

.justify_center ul {
  justify-content: center;
}

.ar__formControlLabel_grid.mb1 {
  margin-bottom: 10px !important;
}

.ar__formControlLabel > span {
  padding: 0px 4px 4px;
}

button.ar__add_option {
  position: relative;
  height: 32px;
  padding: 0px 12px;
  border: 0px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  background: rgb(0, 0, 0);
  outline: none;
  transition: color 100ms ease-in 0s;
  text-align: left;
  color: rgb(255, 255, 255);
  font-weight: bold;
}

input.mt3-space + .ar__formControlLabel {
  margin-top: 10px !important;
}

.cover-area .cover_image__drag_info {
  position: absolute;
  background: rgba(0,0,0,0.7);
  color: white;
  padding: 10px;
  border-radius: 10px;
}

.cover-area.cursor-mouse-move {
  cursor: move;
}

/*  Logo */
/* .form__logo__fixed__width {
  width: 200px;
  height: auto;
} */

/* .form__logo__fixed__height img {
  height: 150px;
  object-fit: cover;
  object-position: center;
} */

.preview__image__ratio {
  width: 150px;
  height: 150px;
  background-size: 150px;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 4px;
  margin-top: -75px;
}

.preview__image__ratio.fill__with__color {
  background-color: #fff;
}

.preview__image__ratio.Rectangular__logo {
  width: 250px;
  height: 200px;
  background-size: 250px;
    margin-top: -100px;
}

.preview__image__ratio.Circular__logo {
  border-radius:100%;
}
.global__dropdown__fb .MuiList-padding {
  padding: 0 !important;
}
.global__dropdown__fb .MuiPopover-paper {
  background: #FFFFFF;
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1) !important;
border-radius: 5px;
  width: auto !important;
}

.global__dropdown__fb .MuiListItemIcon-root {
  min-width: unset !important;
}

.global__dropdown__fb .MuiListItemText-root.MuiListItemText-inset {
  padding-left: 10px;
}

.global__dropdown__fb .MuiMenuItem-root {
  display: flex !important;
  align-items: center !important;
}

.global__dropdown__fb .MuiMenuItem-root.gd__red-option{
  color: red;
}

.global__dropdown__fb .MuiMenuItem-root.gd__red-option svg{
  color: red;
}

.global__dropdown__fb .MuiMenuItem-root.gd__divider::before {
  content: '';
  position: absolute;
  top: -3px;
  left: 6px;
  width: calc(100% - 12px);
  background: #ddd;
  display: block;
  height: 1px;
}

.global__dropdown__fb .MuiMenuItem-root.gd__divider {
  overflow: visible;
  margin-top : 5px;
}
.form_entry_container .section__top__right{
    display: flex;
}
.form_entry_container .section__top__link .section__top__right{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}
.form_entry_container .section__top__link li{
    height: 40px;
    width: 40px;
}
.form_entry_container .form_builder.body-search input{
    border-radius: 5px !important;
    height: 40px !important;
    width: auto !important;
}
.datatable-area.pp_form_entry_table_wrapper {
    padding: 20px;
    background: white;
    border-radius: 5px;
}
.Drip_table.pp_form_entry_table {
    background: white;
}
.pp_form_entry_select{
    padding: 5px 10px;
    margin: 10px 0px;
}
.pp_form_entry_table .rdt_TableHead *{
    font-family: var(--poppins);
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 36px;
    color: #133159;
}
.pp_form_entry_table .rdt_TableBody .rdt_TableCell{
    font-family: var(--poppins);
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 24px;
    color: rgba(19, 49, 89, 0.65);
}
.pp_add_to_campaigns{
    display: flex;
    align-items: center;
    position: relative;
}
.globalModalWrapper .campaign_modal .pp_add_to_campaigns input{
    box-sizing: border-box;
    padding: 10px 20px;
}
.pp_add_to_campaigns svg{
    top: auto;
    position: absolute;
    right: 15px;
}
.pp_form_entry_table_wrapper .datatable_helper {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.pp_form_entry_table_wrapper .select-wrapper {
    width: 80px;
    background: #fff;
}

.pagination_bar ul {
    display: flex;
    justify-content: center;
}

.pagination_bar ul li a {
    color: #fff;
    background: #3C7EF3;
    border-radius: 5px;
    padding: 8px 23.5px;
    margin-left: 10px;
    display: block;
    font-weight: 600;
    border: 2px solid #3C7EF3;
    font-size: 18px;
    line-height: 30px;
}

.pagination_bar ul li:nth-child(2) a {
    background: transparent;
    border: 2px solid #3C7EF3;
    color: #3C7EF3;
}

.pagination_bar ul li:nth-child(3) a {
   background: transparent;
   color: #3C7EF3;
   border: unset;
   padding: 8px 6px;
}

.datatable_helper .total_entries {
   color: #657993;
}
.body-search form{
    position: relative;
    border: 1px solid #dddddd;
    border-radius: 5px;
}

.body-search form input {
    width: 214px !important;
    background: #FFFFFF !important;
    border-radius: 20px !important;
    border: none !important;
    display: block;
    padding: 8px 10px !important;
    font-style: normal;
    font-weight: normal;
    font-size: 14px !important;
    line-height: 24px;
    color: #BABABA;
    height: auto !important;
    min-height: auto;
    margin: 0px !important;
    overflow: hidden;
}
.body-search form input:placeholder{
    opacity: 1 !important;
}
.body-search form input:focus{
    box-shadow: inherit !important;

}
body .search-data-table .body-search input {
    margin: 0 !important;
    height: 42px !important;
    padding-top: 0px !important;
    padding-bottom: 0 !important;
}
.body-search form button {
    position: absolute;
    top: 50%;
    right: 3px;
    background: transparent;
    border: none;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
}

.body-search form button i{
    color: #3C7EF3;
}

.custom_form_builder_pages_dropdown input {
    margin: 0 !important;
    padding: 0 !important;
    height: auto !important;
}

.custom_form_builder_pages_dropdown .MuiSelect-select {
    padding: 0 0 0 12px !important;
    line-height: 43px !important;
}

.custom_form_builder_pages_dropdown .MuiInput-formControl {
    margin: 0 !important;
    flex: 1 1;
    width: 100%;
}

.custom__field__chip {
    font-size: 10px !important;
    margin-left: 8px;
    height: 17px !important;
}

.input-field .custom_form__file_entry {
    color: #039be5;
    display: flex;
    align-items: center;
    padding-bottom: 0.5rem;
    padding-top: 0.5rem;
    margin-right: 0.25rem;
}

.input-field .custom_form__file_entry svg {
    margin-right: 0.25rem;
}

.input-field .custom_form__file_entry:hover {
    color: #075b85;
}
