.global__dropdown__fb .MuiList-padding {
  padding: 0 !important;
}
.global__dropdown__fb .MuiPopover-paper {
  background: #FFFFFF;
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1) !important;
border-radius: 5px;
  width: auto !important;
}

.global__dropdown__fb .MuiListItemIcon-root {
  min-width: unset !important;
}

.global__dropdown__fb .MuiListItemText-root.MuiListItemText-inset {
  padding-left: 10px;
}

.global__dropdown__fb .MuiMenuItem-root {
  display: flex !important;
  align-items: center !important;
}

.global__dropdown__fb .MuiMenuItem-root.gd__red-option{
  color: red;
}

.global__dropdown__fb .MuiMenuItem-root.gd__red-option svg{
  color: red;
}

.global__dropdown__fb .MuiMenuItem-root.gd__divider::before {
  content: '';
  position: absolute;
  top: -3px;
  left: 6px;
  width: calc(100% - 12px);
  background: #ddd;
  display: block;
  height: 1px;
}

.global__dropdown__fb .MuiMenuItem-root.gd__divider {
  overflow: visible;
  margin-top : 5px;
}