.thankyou-area {
    text-align: center;
    padding: 100px 0;
}

.thankyou-area svg {
    font-size: 3em;
    color: tomato;
}

.thankyou-area h3 {
    color: rgb(36, 32, 43);
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
}

.thankyou-area p {
    padding: 0;
    margin: 0;
}

.thankyou-area a.btn {
    background: #0d6efd;
    box-shadow: none;
    border-radius: 6px;
    margin-top: 20px;
    transition: 500ms;
}

.thankyou-area a:hover {
    background-color: rgb(18, 16, 22);
}

.thankyou-area h3+p {
    margin: 10px 0;
}