.form__background__wr,
.form__animation_acc__wr {
  width: 100%;
}
.form__background__wr .MuiAccordion-root,
.form__animation_acc__wr .MuiAccordion-root{
  box-shadow: 0 0 0 rgba(0,0,0,0.0) !important;
}
.form__background__wr .MuiAccordion-root .MuiAccordionSummary-root,
.form__animation_acc__wr .MuiAccordion-root .MuiAccordionSummary-root{
  padding: 0;
}
.form__background__wr .MuiAccordion-root .MuiAccordionSummary-root .MuiIconButton-label svg,
.form__animation_acc__wr .MuiAccordion-root .MuiAccordionSummary-root .MuiIconButton-label svg{
  color:rgb(55, 53, 47)
}

.form__background__wr .MuiAccordion-root .MuiAccordionSummary-root .MuiAccordionSummary-expandIcon:hover,
.form__animation_acc__wr .MuiAccordion-root .MuiAccordionSummary-root .MuiAccordionSummary-expandIcon:hover {
  background-color: unset !important;
}
.form__background__wr .MuiAccordionSummary-root .Mui-focused:focus,
.form__background__wr .MuiIconButton-root:focus,
.form__animation_acc__wr .MuiAccordionSummary-root .Mui-focused:focus,
.form__animation_acc__wr .MuiIconButton-root:focus{
  color: rgba(0,0,0,0.0) !important;
}

.form__background__wr .form__background__heading,
.form__animation_acc__wr .form__animation__heading {
  font-size: 16px;
  color: rgb(55, 53, 47);
  font-weight: bold;
}

.form__background__wr .form__background__details {
  padding: 0 !important;
}

.form__background__wr .form__background__details .form__style__background__tabs {
  display: flex;
  flex-grow: 1;
  background-color: #fff;
  width: 100%;
}
.form__background__wr .form__background__details .form__style__background__tabs .MuiBox-root{
  padding: 12px 24px 24px 24px !important;
  height: 300px;
  overflow-y: auto;
  position: relative;
}

.form__background__details .MuiAppBar-colorPrimary {
  color: #000;
  background-color: #fff;
}

.form__style__background__tab__section button:focus {
  background-color: #fff;
}

/* Tab style */
.form__style__background__tabs .form__style__background__tab__section__with__remove{
  display: flex;
  justify-content: space-between;
  position: relative;

}
.form__style__background__tabs .form__style__background__tab__section__with__remove:after{
  position: absolute;
  width: 100%;
  content: " ";
  height: 2px;
  background: #ddd;
  left: 0;
  bottom: 10px;
  z-index: -1;

}
.form__style__background__tab__section .MuiTabs-root{
  min-height: unset !important;
}
.form__style__background__tabs .form__style__background__remove__section{
  display: flex;
  align-items: center;
  grid-gap: 5px;
  cursor: pointer;
  background: #ff0000;
  margin: 6px 10px 0 0;
  padding: 1px 5px;
  height: 34px;
  border-radius: 6px;
  color: #fff;
}

.form__style__background__tab__section{
  width: 70%;
}
.form__style__background__tab__section .MuiButtonBase-root{
  min-width: unset !important;
  padding: 0;
  margin: 6px 16px;
  text-transform: capitalize;
}
.form__style__background__tab__section .MuiTabs-fixed .MuiTabs-flexContainer +span{
  background-color: rgb(55, 53, 47) !important;
  bottom: 10px !important
}
.form__style__background__tabs .form__style__background__remove__section span{
  display: inline-flex;
}


/* upload section css */
.file__upload__wrapper__v2 {
  display: grid;
  grid-template-columns: repeat(3,1fr);
  grid-gap: 16px;
}
.file__upload__wrapper__v2 .file__upload__signle__v2{
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 32px 0;
  display: flex;
  flex-direction: column;
}

.file__upload__wrapper__v2 .file__upload__signle__v2.pexel{
  background: rgba(5, 160, 129, 0.1);
  border: 1px dashed #05A081;
  border-radius: 5px;
}

.file__upload__wrapper__v2 .file__upload__signle__v2.canva{
  background: rgba(32, 196, 203, 0.1);
  border: 1px dashed #7D2AE8;
  border-radius: 5px;
}

.file__upload__wrapper__v2 .file__upload__signle__v2.file__upload{
  background: rgba(60, 126, 243, 0.1);
  border: 1px dashed #3C7EF3;
  border-radius: 5px;
}

.file__upload__wrapper__v2 .file__upload__signle__v2.file__drag__zone{
  background: rgba(155, 159, 179, 0.3);
  border: 2px dashed rgb(104, 115, 141);
  border-radius: 5px;
}

.file__upload__wrapper__v2__top {
  display: flex;
  align-items: center;
  grid-gap: 8px;
  margin: 0 0 40px 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  color: #546376;
}
.file__upload__wrapper__v2__top span {
  display: inline-flex;
}
.file__upload__wrapper__v2 .file__upload__signle__v2 h4{
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  color: #133159;
  margin: 5px 0 20px;

}
.file__upload__wrapper__v2 .file__upload__signle__v2 .file__upload__signle__v2__drag__title{
  margin: 3px 0 7px 0;
}
.file__upload__wrapper__v2 .file__upload__signle__v2 .file__upload__signle__v2__drag__sub__title{
  margin: 0 0 5px 0;
}
/* .file__upload__wrapper__v2 .file__upload__signle__v2 span{} */
.file__upload__wrapper__v2 .file__upload__signle__v2 button{
  border: 0;
  color: #fff;
  padding: 12px 56px;
  border-radius: 5px;
  cursor: pointer;
}
.file__upload__wrapper__v2 .file__upload__signle__v2 button.pixel__for__upload__btn{
  background: #05A081;
}
.file__upload__wrapper__v2 .file__upload__signle__v2 button.file__for__upload__btn{
  background: #3C7EF3;
}
.file__upload__wrapper__v2 .file__upload__signle__v2 button.canva__for__upload__btn{
  background: #7D2AE8;
}

/* tab link css */
.form__style__background__tabs__link form{
  text-align: center;
}
/* .form__tabs__link__input{} */
.form__tabs__link__button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: 1px solid var(--dark_blue);
  background: var(--dark_blue);
  color: #fff;
  padding: 9px 16px;
  border-radius: 4px;
  margin: 13px 0 0 0;
  cursor: pointer;
}

/* tab color css */
.form__fixed__background{
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  grid-gap: 5px;
}
.form__fixed__background .form__fixed__background__single {
  height: 50px;
  border-radius: 2px;
  cursor: pointer;
  box-shadow: 1px 1px 0px #bbb;
}

.form__fixed__background .form__fixed__background__single.active {
  box-shadow: 0px 0px 2px #000 !important;
}

.form__fixed__background .form__fixed__background__single svg {
  fill: #2ecc71 !important;
  float: right;
  width: 20px;
  height: 20px;
}

/* Canva */
.canva__alert__wr .canva__alert__title {
  margin: 20px 0;
  font-size: 16px;
  font-weight: bold;
  line-height: 16px;
  color: rgb(55, 53, 47);
  display: grid;
  place-items: center;
}

.canva__alert__wr .canva__alert__note {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  padding: 0 20px;
}

.canva__alert__wr .canva__alert__image__section {
  margin-top: 10px;
}

.canva__alert__wr .canva__alert__btn__section {
  display:grid;
  place-items: center;
  margin-bottom: 15px;
}

.canva__alert__wr .canva__alert__btn {
  padding: 7px 15px;
  background: #3C7EF3;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.canva__alert__wr .canva__alert__btn span{
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
}

.position-static {
  position: static;
}

.form__style__background__tabs__link button:focus.form__tabs__link__button {
  background-color: var(--dark_blue);
}

/* Pexel */
.pexel__start__wr .pexel__start__top__section {
  display: flex;
  justify-content: space-between;
}

.pexel__start__wr .pexel__start__top__section .form_builder__pexels_search {
  flex: 0.5;
}

.pexel__start__wr .pexel__start__top__section .form_builder__pexels__back__button {
  display: flex;
  align-items: center;
  grid-gap: 5px;
  cursor: pointer;
  background: #3C7EF3;
  padding: 7px 15px;
  border-radius: 6px;
  border: none;
  height: 34px;
}

.pexel__start__wr .pexel__start__top__section .form_builder__pexels__back__button span {
  display: inline-flex;
  color: #fff;
}
.pexel__start__wr .pexel__start__top__section .form_builder__pexels__back__button svg {
  width: 18px;
  height: 18px;
}

.pexel__start__wr .pexel__start__top__section .form_builder__pexels__back__button svg path {
  fill: #fff;
}

.form_builder__pexels_search input {
  border: 1px solid #9da6b1 !important;
  border-radius: 6px !important;
  height: 34px !important;
}

.form_builder__pexels_search input:focus {
  box-shadow: unset !important;
}

.form_builder__pexels_search i {
  top: 2px !important;
}

/* Pexel Image */
.form__pexel__image__section__wr{
  margin: 20px 0;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  grid-gap: 5px;
}
.form__pexel__image__section__wr .form__pexel__image__section{
  width: 100%;
  cursor: pointer;
}

.form__pexel__image__section__wr .form__pexel__image__section .form__pexel__image {
  border-radius: 5px;
  width: 100px;
  height: 130px;
}

/*  Pexel Image Skeleton */
.pexel__start__wr .pexel__image__skeleton__wr {
  margin: 20px 0;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  grid-gap: 5px;
}

.pexel__start__wr .pexel__image__skeleton__wr .skeleton__each__image__section {
  width: 100%;
}

.pexel__start__wr .pexel__image__skeleton__wr .skeleton__each__image__section .skeleton__each__image{
  border-radius: 5px;
  width: 100px;
  height: 130px;
}

/*  No Image Found */
.pexel__start__wr .pexel__no__image__found__section {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

