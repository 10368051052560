.form__animation__wr {
  width: 100%;
}

.form__animation__wr .form__animation__heading {
  font-size: 16px;
  color: rgb(55, 53, 47);
  font-weight: bold;
}

.form__animation__dropdown {
  display: inline-flex;
  float: right;
}

.form__animation__dropdown .MuiSelect-select:focus {
  box-shadow: 0 0 0 rgba(0, 0, 0, 0) !important;
  border-color: #ddd;
  width: 88px;
}

.form__animation__dropdown .MuiSelect-select {
  border-color: #ddd;
  width: 88px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.form__animation__acc .form__animation_list .each_animation_option img{
  height: 200px;
  width: 200px;
  margin-bottom: 10px;
}

.form__animation__acc .form__animation_list .each_animation_option {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 0 8px 1px #eee;
  margin: 5px;
  padding: 5px;
  padding-bottom: 20px;
  cursor: pointer;
  border-radius: 8px;
  position: relative;
}

.form__animation__acc .form__animation_list .each_animation_option svg {
  position: absolute;
  right: 8px;
  top: 8px;
  height: 30px;
  width: 30px;
}

.form__animation__acc .form__animation_list .each_animation_option:hover {
  box-shadow: 0 0 8px 1px rgb(114, 114, 114);
}

.form__animation__acc .form__animation_list {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
}
