.action__button__submit {
  display: flex;
  grid-gap: 10px;
  padding: 20px 0;
}

.action__button__submit .MuiButtonBase-root {
  background-color: rgba(0, 0, 0, 0.04);
}

.button-input-field.mt-1 input[type="color" i]::-webkit-color-swatch {
  background: inherit !important;
  border: 0;
}
