.formbuilder-radio label, .formbuilder-checkbox label{
    margin-left: -14px;
    padding-left: 14px;
}

.form_success_wrapper{
    color: #202124;
    background-color: #fff;
    border-radius: 8px;
    margin-bottom: 12px;
    position: relative;
    padding: 14px 6px 6px 0;
}

.form_title{
    font-weight: 400;
    line-height: 40px;
    color: #202124;
    padding-bottom: 16px;
    font-size: 24pt;
}

.form_success_message{
    font-size: 16px;
    font-weight: 400;
    letter-spacing: .2px;
    line-height: 20px;
    color: #202124;
    white-space: pre-wrap;
}

.form_resubmit{
    margin-top: 20px;
}

.form_resubmit span {
    color: #4c4cb6;
    text-decoration: underline;
    cursor: pointer;
}

.form_disabled_wrapper{
    position: absolute;
    background: #24201a4d;
    width: 100%;
    height: 100%;
    z-index: 1111;
    right: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: auto;
}

.form_disabled_alert{
    font-size: 20px;
    color: #383837 !important;
    padding: 10px;
    background: #e1d5c2;
    border-radius: 4px;
    max-width: 390px;
    text-align: center;
}

.form_disabled_alert h1 {
    font-size: 20px;
}