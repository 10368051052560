.custom_form_list__wrapper {
    padding: 20px;
    background: #f7f7f79c;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    margin: 20px;
    box-shadow: 1px 1px 4px #bbb;
}

.custom_form_list__wrapper .form__top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 500px;
    width: 100%;
}

.custom_form_list__wrapper .form__bottom {
    display: flex;
    align-items: center;
    justify-content: center;
}

.custom_form_list__wrapper .form__top__left h3 {
    font-family: var(--poppins);
    font-size: 20px;
    font-weight: 500;
    color: rgba(19, 49, 89, 0.65);
    line-height: 22px;
    margin: 0;
    text-transform: capitalize;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 300px;
}

.custom_form_list__wrapper .form__bottom li {
    height: 50px;
    width: 50px;
    min-width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ffffff;
    margin: 0 17.5px;
    color: rgba(19, 49, 89, 0.65);
    box-shadow: 0px 0px 2px #bbb;
    border-radius: 4px;
}

.custom_form_list__wrapper .form__bottom .form__action__buttons {
    border: none;
    background: none;
    cursor: pointer;
}

.custom_form_list__wrapper .form__top__left {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.custom_form_list__wrapper .form__top__left button {
    margin: -2px 0 0 20px;
    height: 30px;
    border-radius: 8px;
    text-transform: capitalize;
    background-color: #3980F3;
}

.custom_form_list__wrapper .form__top__left button span {
    font-weight: 600;
    letter-spacing: 1px;
    color: #fff;
    padding: 0 10px 0 10px;
    font-size: 15px;
}
