ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.form__builder__modal.modal {
  overflow-y: unset;
  overflow-x: unset;
}
.form__builder__modal .form__builder__modal__sidebar {
  overflow-y: auto;
  max-height: 100%;
  box-sizing: border-box;
}
/* .form__builder__modal .form__builder__modal__sidebar__single__wr h3 {
  padding: 0px 10px;
  font-size: 11px;
  line-height: 1.5;
  font-weight: bold;
  text-transform: uppercase;
  color: rgba(136, 136, 136, 0.8);
} */

body .form__builder__modal__sidebar__single__wr .subtitle {
    padding: 0 16px;
    margin-bottom: 0;
    font-size: 12px;
    line-height: 1.5;
    font-weight: bold;
    text-transform: uppercase;
    color: rgba(136, 136, 136, 0.8);
    margin-top: 40px;
    -webkit-user-select: none;
    user-select: none;
    font-weight: 600;
}


.form__builder__modal .form__builder__modal__sidebar__single__li {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    padding: 8.5px 16px;
    user-select: none;
    transition: all 120ms ease-in 0s;
    cursor: pointer;
    font-size: 14px;
    font-weight: 300;
}
.form__builder__modal .form__builder__modal__sidebar__single__li.active {
    background: rgb(245, 245, 245);
}

.form__builder__modal .form__builder__modal__sidebar__single__li svg {
    margin-right: 10px;
} 

.form__builder__modal .form__builder__modal__sidebar__single__li svg {
  color: rgb(136, 136, 136);
}
.form__builder__modal .form__builder__modal__sidebar__single__li:hover {
  background: rgb(245, 245, 245);
}
.form__builder__modal__sidebar__single__wr {
  margin: 16px 0 0 0;
}

.ul-area li {
  transition: 500ms;
  cursor: pointer;
  padding: 10px 5px;
  border-bottom: 1px dashed aliceblue;
}
.ul-area li:hover {
  background-color: aliceblue;
}

.initial__content__logical__demo__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.initial__content__logical__demo__content svg {
  font-size: 18px;
  color: #fff;
}
.initial__content__logical__demo__content
  span.initial__content__logical__demo__add {
  color: #fff;
  background: rgb(248, 28, 229);
  height: 30px;
  width: 30px !important;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.initial__content__logical__demo__content h5 {
  margin: 20px 0;
  font-size: 16px;
  font-weight: 600;
}

.initial__content__logical__demo__content h5 + p {
      max-width: 280px;
    color: rgb(68, 68, 68);
    text-align: center;
    font-size: 14px;
    line-height: 2;
    user-select: none;
    font-weight: 300;
}

.initial__content__logical__demo__content {
  color: rgb(68, 68, 68);
  text-align: center;
  font-size: 14px;
  line-height: 2;
}

.example-area {
  border-left: 1px solid rgba(0, 0, 0, 0.5);
}
