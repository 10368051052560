.setting_container.custom-form-setting form .disable-row .input-field + .input-field label,
.custom-form-setting .row:not(.switcher-row) .input-field.col label.active {
    top: -10px !important;
}

.setting_container.custom-form-setting {
    height: auto;
    padding: 50px 0 20px;
}
.custom-form-setting .button-row {
    padding-left: .75rem
}
.custom-form-setting button { 
    border: 0;
    padding: 12px 15px;
    border-radius: 4px;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
}

.setting_container.custom-form-setting form .row {
    margin-bottom: 30px;
}

.setting_container.custom-form-setting form .action-row .input-field + .input-field {
    margin-top: 12px !important;
}

.setting_container.custom-form-setting form .disable-row {
    padding-top: 20px !important;
}

.setting_container.custom-form-setting form .disable-row textarea {
    resize: none;
    background: #F6F7FB;
    border: 1px solid #E5E8EF;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 10px 12px;
    min-height: 100px;
    outline: 0 !important;
    box-shadow: unset !important;
}

.form-settings-form-builder.setting_container .disable-row .input-field + .input-field {
    margin: 40px 0 0 !important;
}


.setting_container.custom-form-setting label.active {
    color: #222;
}

@media(max-width: 1366px) {
.form__builder__modal.form_builder_modal_settings_v2.ar__form_builder .modal__body__area__v2.ar__modal_bodyV2 input, 
.form__builder__modal.form_builder_modal_settings_v2.ar__form_builder .modal__body__area__v2.ar__modal_bodyV2 select {
    padding: 8.5px 15px !important;
    height: auto !important;
    font-size: 10px;
    margin: 0 !important;
    outline: 0 !important;
    box-shadow: unset !important;
}
.form__builder__modal.form_builder_modal_settings_v2.ar__form_builder .modal__body__area__v2.ar__modal_bodyV2 select {
    height: 25px !important;
    padding: 5px 15px 0 15px !important;
}

.form__builder__modal.form_builder_modal_settings_v2 .setting_container.custom-form-setting form .row {
    margin-bottom: 5px;
}

.form__builder__modal.form_builder_modal_settings_v2 .modal__body__area__v2 .form-settings-form-builder.setting_container .input-field {
    margin: 0 !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.form__builder__modal.form_builder_modal_settings_v2 .modal__body__area__v2 .form-settings-form-builder.setting_container .action-row .input-field + .input-field {
    margin-top: 6px !important;
}

.form__builder__modal.form_builder_modal_settings_v2 .custom-form-setting button {
    padding: 4px 15px;
    border-radius: 4px;
    font-size: 14px;
}

.form__builder__modal.form_builder_modal_settings_v2 .setting_container.custom-form-setting form .row:last-child {
    margin: 0;
}

.setting_container.custom-form-setting label {
    position: static !important;
    transform: unset !important;
    font-size: 10px;
    margin-bottom: 5px;
    color: #222;
}

.form__builder__modal.form_builder_modal_settings_v2 .setting_container.custom-form-setting form .disable-row {
    padding-top: 0 !important;
}

.form__builder__modal.form_builder_modal_settings_v2 .setting_container.custom-form-setting {
    padding: 15px 0 35px 0;
}

.form__builder__modal.form_builder_modal_settings_v2.ar__form_builder .modal__body__area__v2.ar__modal_bodyV2 input:focus, 
.form__builder__modal.form_builder_modal_settings_v2.ar__form_builder .modal__body__area__v2.ar__modal_bodyV2 select:focus {
    border: 1px solid #E5E8EF !important;
}

.form__builder__modal.form_builder_modal_settings_v2 .form-settings-form-builder.setting_container .disable-row .input-field + .input-field {
    margin: 5px 0 !important;
}

.form__builder__modal.form_builder_modal_settings_v2 .setting_container.custom-form-setting form .disable-row textarea {
    padding: 8.5px 15px !important;
    font-size: 10px;
    min-height: 50px;
}

.form__builder__modal.form_builder_modal_settings_v2 .custom-switch .react-switch-handle {
    height: 12px !important;
    width: 12px !important;
}

.form__builder__modal.form_builder_modal_settings_v2 .custom-switch .react-switch-bg {
    height: 20px !important;
}

.form__builder__modal.form_builder_modal_settings_v2 .custom-switch .react-switch-bg > div {
    height: 20px !important;
}

.form__builder__modal.form_builder_modal_settings_v2 .custom-switch-on-icon, .custom-switch-off-icon {
    font-size: 12px !important;
}
}