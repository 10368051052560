.form__style__body__wrapper {
    padding: 60px 90px;
}

.form__style__body__wrapper .form__style__each__item {
    border-bottom: 1px solid rgb(238, 238, 238);
    padding-bottom: 15px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 20px;
}

.form__style__body__wrapper .form__style__each__item .form__style__each__item__heading {
    width: 90%;
}

.form__style__body__wrapper .form__style__each__item .form__style__each__item__custom__switch {
    width: 10%;
}
.form__style__body__wrapper .form__style__each__item .form__style__each__item__custom__switch div {
    float: right
}
.form__style__body__wrapper .form__style__each__item .form__style__each__item__custom__switch .custom-switch-inactive-mode div {
    display: flex;
    justify-content: end;   
}
.form__style__body__wrapper .form__style__each__item .form__style__each__item__custom__switch .custom-switch-active-mode div {
    display: flex;
    justify-content: start;   
}
.form__style__body__wrapper .form__style__each__item .form__style__each__item__heading h1 {
    font-size: 16px;
    color: rgb(55, 53, 47);
    font-weight: bold;
}

.form__style__body__wrapper .form__style__each__item .form__style__each__item__heading p {
    font-size: 14px;
    line-height: 1.5;
    color: rgb(136, 136, 136);
}