.mapping__select__wrapper .MuiSelect-select{
    border: 1px solid #ddd;
    padding: 10px 0 10px 12px;
    width: 100%;
    color: #8b8383;

}
.maping__table__wrapper .MuiPaper-root{
    box-shadow: 0 0 2px #aaa;
}
.mapping__select__wrapper>div{
    width: 74% ;
}

.mapping__select__wrapper{
    display: flex;
    align-items: center;
    grid-gap: 10px;
}
.mapping__select__wrapper .maping__select__arrow svg{
    color: #8b8383;
}

.maping__table__wrapper .MuiTableHead-root .MuiTableCell-head{
    color: #000;
    font-weight: 600 !important;
    font-size: 15px;
}
.maping__table__wrapper .MuiTableBody-root .MuiTableCell-head{
    color: #000;
    font-weight: 400px;
    font-size: 15px;
}
/* .maping__table__wrapper tr,
.maping__table__wrapper td,
.maping__table__wrapper th
{
    border-bottom: 0;
} */

.mapping__enabled_button_wrapper{
    padding: 20px;
}

.mapping__enabled_button_wrapper .mapping__enabled_button {
    display: inline-block;
    padding: 15px 60px 14px 10px;
    border-radius: 5px;
    box-shadow: 0 0 5px #ddd;
}

