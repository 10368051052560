* {
    padding: 0;
    margin:0;
    box-sizing: border-box;
}


/* ===== Start Form Builder Top bar ===== */
.section__top{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 70px;
    background: var(--white);
    border-radius: 5px;
    margin: 10px 0 10px 0;
    padding: 27px 20px;

}
.section__top__left {
    flex: 0.6;
}
.section__top__right {
    flex: 0.6;
}
.section__top__left h2{
    font-family: var(--poppins);
    font-weight: 400;
    font-size: 24px;
    line-height: 36px;
    color: var(--dark_blue);
    margin:0;

}
.section__top__right button{
    font-family: var(--poppins);
    padding: 13px 24px 13px 24px;
    border-radius: 5px;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    text-transform: capitalize;
    height: 50px;
}

.section__top__right button.add__icon{
    background: var(--light_blue);
    color: var(--white);
    outline:none;
    border:none;
    box-shadow: none;
}
.add__icon svg path{
    fill: white !important;
    fill-opacity: 1 !important;
}
.section__top__right button.tempalte__icon{
    background: var(--gray_light);
    color: rgba(19, 49, 89, 0.65);
    margin-right: 20px;
    padding:13px 29.35px;
}

/* ======= Start All Form Body ====== */

.form__wrapper{
    padding: 20px;
    background: var(--white);
    border-radius: 10px;
}
.form__wrapper .form__top{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}
.form__wrapper .form__top__left h3{
    font-family: var(--poppins);
    font-size: 20px;
    font-weight: 500;
    color: rgba(19, 49, 89, 0.65);
    line-height: 22px;
    margin:0;
    text-transform: capitalize;
}
.form__top__left .off__icon{
    background: var(--gray_light);
    padding: 12px 15px;
    font-family: var(--poppins);
    font-size: 16px;
    line-height: 22px;
    font-weight: 500;
    color: rgba(19, 49, 89, 0.65);
    height: 50px;
    border-radius: 5px;
    text-transform: none;
}

/* ===== Form Content ===== */

.form__content{
    border: 1px solid var(--gray_dark);
    padding: 20px 20px 10px;
    border-radius: 5px;
    min-height: 380px;
}
.form__content__nopreview{
    display: flex;
    justify-content: center;
    align-items: center;
}
.form__content__nopreview p{
    font-family: var(--poppins);
    font-size: 20px;
    line-height: 24px;
    color: var(--dark_blue);
}
.form__content .formcontrol_custom{
    width: 100%;
}
.form__content input{
    width: 100%;
    background: var(--gray_light) !important;
    border: 1px solid var(--gray_dark) !important;
    border-radius: 5px !important;
    padding: 0 10px !important;
    height: 48px !important;
}
.form__content input.focus-visible {
    box-shadow: none !important;
}
.form__content .MuiInput-formControl{
    margin-top: 5px !important;
    margin-bottom: 3px;
}

.form__content .MuiFormLabel-root{
    font-family: var(--poppins);
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    color: var(--dark_blue);
}

.form__wrapper .form__bottom{
    display: flex;
    align-items: center;
    justify-content:center;
    margin-top: 20px;
}

.form__wrapper .form__bottom li{
    height: 50px;
    width: 50px;
    min-width: 50px;
    display: flex;
    align-items: center;
    justify-content:center ;
    background: var(--gray_light);
    margin: 0 17.5px;
    color: rgba(19, 49, 89, 0.65);
}
.form__wrapper .form__bottom li svg {
    width: 20px;
    height: 20px;
}


/* Form Modal */
.createNewForm .modalBody .input-field input{
    width: 100%;
    height: 50px;
    border: 1px solid rgba(19, 49, 89, 0.3);
    border-radius: 5px;
    padding: 0 20px;
    margin-top:3px;
    font-family: var(--poppins);
    font-size: 16px;
    line-height: 24px;
    color:rgba(19, 49, 89, 0.35);
    font-weight: 400;
}
.createNewForm .modalBody .input-field input.focus-visible {
    box-shadow:none !important;
    border: 1px solid rgba(19, 49, 89, 0.3);
}
.createNewForm .modalBody .input-field input::placeholder  {
    font-family: var(--poppins);
    font-size: 16px;
    line-height: 24px;
    color:rgba(19, 49, 89, 0.35);
    font-weight: 400;
    text-transform: capitalize;
}
.createNewForm .modalBody .input-field label {
    position: static;
    transform: none;
    font-family: var(--poppins);
    font-size: 16px;
    line-height: 24px;
    color: var(--dark_blue);
    font-weight: 400;
    text-transform: capitalize;
    margin-bottom: 7px;
}
.createNewForm .massage_send_close_button .send_massage, .createNewForm .massage_send_close_button .close_btn {
    font-family: var(--poppins);
    font-size:16px;
    line-height: 24px;
}

/* ===== Start System Form Temaplete ===== */

.systemform__sections .section__back{
    background: var(--light_blue);
    padding: 12px 15px;
    height: 50px;
    width: 95px;
    font-size: 16px;
    line-height: 24px;
    font-family: var(--poppins);
    font-weight: 500;
    border-radius: 5px;
    color: var(--white);
    display: flex;
}
.section__top__link{
    display: flex;
}
.section__top__link li{
    height: 50px;
    width: 50px;
    min-width: 50px;
    display: flex;
    align-items: center;
    justify-content:center ;
    background: var(--gray_light);
    margin: 0 4px;
    color: rgba(19, 49, 89, 0.65);
    padding: 10px;
}
.section__top__link li svg{
    font-size: 24px !important;
}
.systemform__sections .systemFromTemplate {
    background-color: var(--white);
    max-height: 514px;
    padding: 34px 17px;
    border-radius: 10px;
}
.systemform__sections .systemFromTemplate p {
    font-family: var(--poppins);
    font-size: 20px;
    line-height: 22px;
    font-weight: 500;
    color:rgba(19, 49, 89, 0.65);
    margin-bottom: 34px;

}
.systemform__sections .systemFromTemplate img {
    width: 100%;
    height: 334px;
    border-radius: 5px;
}
.systemform__sections .systemFromTemplate .formCount {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
}
.systemform__sections .systemFromTemplate .formCount p {
    font-family: var(--poppins);
    font-size: 16px;
    line-height: 22px;
    color:rgba(19, 49, 89, 0.65);
    margin:0;
    font-weight: 500;
}
.systemform__sections .systemFromTemplate .formCount svg{
    width: 50px;
    height: 50px;
    border-radius: 5px;
    background-color: var(--gray_light);
    padding: 15px;
    cursor:  pointer;
}

/* Accordin Css */
.accroding__wr .MuiAccordion-root{
    border-radius: 10px !important;
    /*margin-bottom: 30px;*/

}
.accroding__wr .MuiAccordionDetails-root {
    padding: 2px 1px 32px !important;
}

.accroding__wr .MuiPaper-root{
    background: transparent;
    margin-top: 10px;
}
.accroding__wr .MuiButtonBase-root{
    background: var(--white);
    border-radius: 10px;
}
.accroding__wr .MuiPaper-elevation1{
    box-shadow: 0 0 0 0 rgba(0,0,0,0.0)!important;
}
.accroding__wr .MuiGrid-spacing-xs-3 {
    width: 100%;
    margin: 0px;
    margin-top: 10px;
}
.accroding__wr .MuiAccordionSummary-root {
    padding: 0px 20px;
    min-height: 70px;
}
.accroding__wr .MuiTypography-root {
    font-family: var(--poppins);
    font-size:20px;
    line-height: 30px;
    font-weight: 400;
    color: var(--dark_blue);
}


/* ====== Start Preview and State  =====*/
.accroding__wr .accordionContain .leftSide{
    background-color: var(--white) !important;
    border-radius: 10px;
    padding: 25px 50px 50px;
}
.accroding__wr .accordionContain .leftSide .topbar {
    display: flex;
    align-items:center;
    justify-content: space-around;

}
.accroding__wr .accordionContain .leftSide .topbar p {
    font-family: var(--poppins);
    font-size: 16px;
    line-height: 22px;
    font-weight: 500;
    color:rgba(19, 49, 89, 0.65);
    margin-right: 20px;
}
.accroding__wr .accordionContain .leftSide .topbar button {
    background-color: var(--gray_light);
    padding: 14px 11px;
    height: 50px;
    border-radius: 5px;
    font-family: var(--poppins);
    font-size: 16px;
    line-height: 22px;
    font-weight: 500;
    color:var(--light_blue);
    text-transform: none;
    box-shadow: none;
}
.accroding__wr .accordionContain .leftSide .topbar button svg {
    width:18px;
    height: 18px;
    vertical-align: middle;
    margin-right: 10px;
}
.accroding__wr .accordionContain .leftSide .textArea {
    display:flex;
    align-items: center;
    padding: 12px;
    border-radius: 5px;
    background-color: var(--gray_light);
    margin-top: 20px;
}
.accroding__wr .accordionContain .leftSide .textArea .circleArea {
    display: flex;
    margin-right: 10px;
}
.accroding__wr .accordionContain .leftSide .textArea .circleArea .circle{
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: var(--gray_light);
    margin: 4px;
}
.accroding__wr .accordionContain .leftSide .textArea .circleArea .circle .red {
    background-color:#ED6A5E;
}
.accroding__wr .accordionContain .leftSide .textArea .circleArea .circle .green {
    background-color:#F6BE4F;
}
.accroding__wr .accordionContain .leftSide .textArea p {
    font-family: var(--poppins);
    font-size: 12px;
    line-height: 18px;
    color: rgba(19, 49, 89, 0.65);
    background-color: var(--white);
    padding: 3px 13px;
    border-radius: 16px;
    width: 100%;
    margin-right: 16px;
    word-break: break-all;
}
.accroding__wr .accordionContain .leftSide .textArea svg {
    width: 20px;
    height: 20px;
    margin-left: auto;
    cursor: pointer;
}
.accroding__wr .accordionContain .leftSide .formArea {
    width: 100%;
    min-height: 354px;
    border: 2px solid var(--gray_dark);
    margin-top: 26px;
}
.accroding__wr .accordionContain .rightSide {
    background-color: var(--white);
    border-radius: 10px;
    max-height: 160px;
    margin-left: 30px;
    padding: 20px;
    max-width: 48% !important;
}
.accroding__wr .accordionContain .rightSide .countCard {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.accroding__wr .accordionContain .rightSide .countCard:not(:last-child) {
    border-right:1px solid var(--gray_light);
}
.accroding__wr .accordionContain .rightSide .countCard h5 {
    font-family: var(--poppins);
    font-size: 32px;
    line-height: 48px;
    font-weight: 700;
    margin: 0;
}
.accroding__wr .accordionContain .rightSide .countCard p {
    font-family: var(--poppins);
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    margin-top: 10px;
}
.accroding__wr .accordionContain .rightSide .countCard.lightOrange {
    color:var(--orange);
}
.accroding__wr .accordionContain .rightSide .countCard.lightBlue {
    color:var(--light_blue);
}
.accroding__wr .accordionContain .rightSide .countCard.lightGreen {
    color:#6FCF97
}

/* ===== Start Dragable area ===== */

.systemform__sections .formBuilder {
    width: 100%;
    margin-top: 10px;
}
.systemform__sections .formBuilder .frmb {
    background-color: var(--white) !important;
    max-height: unset !important;
    min-height: 678px !important;
    width: 72.2%;
    font-family: var(--poppins);
    font-size: 24px;
    line-height: 36px;
    font-weight: 400;
    color:var(--dark_blue);
    border: 2px dashed var(--gray_dark) !important;
}
.systemform__sections .formBuilder .input-control {
    height: 30px;
    width: auto;
    background: var(--white) !important;
    border: 1px solid var(--gray_light) !important;
    box-shadow: none !important;
    margin-top: 3px !important;
    border-radius: 5px;
    font-family: var(--poppins);
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    color: rgba(19, 49, 89, 0.65) !important;

}

.form-wrap.form-builder .frmb-control li{
    padding: 3px !important;
}
.systemform__sections .MuiAccordion-root:before  {
    opacity:0 !important;
}
.systemform__sections .formBuilder .btn-group button{
    background-color: var(--light_blue);
    height: 50px;
    padding: 13px 20px;
    border-radius: 5px;
    font-family: var(--poppins);
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    text-transform: none;
}

/* ====== Start Responsive ======*/
@media only screen and (max-width: 1600px) {
    .form__wrapper .form__bottom li {
        width: 40px;
        height: 40px;
        min-width: 1px;
        margin: 0 8px;
        border-radius: 5px;
    }
    .accroding__wr .accordionContain .rightSide {
        max-width:47% !important;
    }
}

@media only screen and (max-width: 1024px) {

    /* Start Accordion Topbar */

    .section__top {
        height: 70px;
        margin:20px 0 10px 0;
    }
    .section__top__left h2 {
        font-size: 16px;
        line-height: 24px;
    }
    .section__top__right button {
        padding: 5px 12px;
        font-size:12px;
        line-height: 20px;
    }
    .systemform__sections .section__back {
        font-size: 12px;
        line-height: 18px;
    }

    /* Start Accordion */

    .allform__sections .MuiGrid-spacing-xs-3 {
        width: 102% !important;
        margin: unset!important;
        margin-top: -10px;
        margin-left: -6px !important;

    }
    .allform__sections .MuiGrid-spacing-xs-3 > .MuiGrid-item {
        padding: 6px !important;
    }

    /* Start Form */
    .form__wrapper {
        padding: 10px;
    }
    .form__wrapper .form__top {
        margin-bottom: 10px;
    }
    .form__wrapper .form__top__left h3 {
        font-size: 12px;
        line-height: 20px;
        font-weight: 500;
    }
    .form__top__left .off__icon {
        height: 40px;
        font-size: 12px;
        line-height: 20px;
    }
    .form__top__left .MuiButton-startIcon {
        margin-right: 5px;
    }
    .form__content {
        padding: 10px;
        min-height: 358px;
    }
    .form__content__nopreview p {
        font-size: 12px;
        line-height: 24px;
    }
    .form__content .MuiFormLabel-root {
        font-size:12px;
        line-height: 20px;
    }
    .form__wrapper .form__bottom li {
        width: 30px;
        height: 30px;
        min-width: 1px;
        margin: 0 3px;
        border-radius: 5px;
    }

    /* Modal Start */
    .createNewForm {
        min-width: 369px !important;
    }
    .createNewForm .modalBody {
        padding: 0 30px 0 10px !important;
    }
    .createNewForm .modalBody .input-field label {
        font-size: 16px;
        line-height: 24px;
    }
    .createNewForm .massage_send_close_button .send_massage, .createNewForm .massage_send_close_button .close_btn {
        height: 40px;
        font-size: 12px;
        line-height: 20px;
        padding: 9px 20px !important;
        margin-right: 12px !important;
    }
    .createNewForm .massage_send_close_button .send_massage svg, .createNewForm .massage_send_close_button .close_btn svg{
        width: 24px !important;
        height: 24px !important;
    }

    /* start Test form */
    .accroding__wr .MuiTypography-root {
        font-size:16px;
        line-height: 20px;
    }
    .accroding__wr .MuiAccordion-root {
        margin-bottom: 10px;
    }
    .accroding__wr .MuiAccordionSummary-root {
        min-height: 70px;
    }
    .accroding__wr .accordionContain .rightSide {
        max-width:48% !important;
        margin-left: 10px;
        padding: 8px;
        max-height: 92px;
    }
    .accroding__wr .accordionContain .leftSide {
        padding: 10px;
    }
    .accroding__wr .accordionContain .leftSide .topbar p {
        font-size: 12px;
        line-height: 22px;
        margin-right:11px;
    }
    .accroding__wr .accordionContain .leftSide .topbar button {
        font-size: 12px;
        line-height: 22px;
        padding: 11px 13px;
        height: 40px;
    }
    .accroding__wr .accordionContain .leftSide .textArea {
        padding: 9px;
        margin-top: 10px;
    }
    .accroding__wr .accordionContain .leftSide .textArea p {
        margin-right: 10px;
    }
    .accroding__wr .accordionContain .leftSide .textArea svg {
        width: 30px;
        height: 24px;
    }
    .accroding__wr .accordionContain .leftSide .formArea {
        margin-top: 20px;
    }
    .accroding__wr .accordionContain .rightSide .countCard h5 {
        font-size: 20px;
        line-height: 30px;
    }
    .accroding__wr .accordionContain .rightSide .countCard p {
        font-size: 8px;
        line-height: 12px;
        border-radius: 10px;
        margin-top:0;
    }

    .systemform__sections .formBuilder .input-control {
        font-size: 12px;
        line-height: 20px;
    }
    .main__content_wrapper {
        display:block !important;
        padding-top: 10px;
    }
    .systemform__sections .systemFromTemplate {
        padding: 10px;
    }
    .systemform__sections .systemFromTemplate p {
        margin-bottom: 10px;
        font-size: 12px;
        line-height: 20px;
    }
    .systemform__sections .systemFromTemplate .formCount p {
        font-size: 12px;
    }
    .systemform__sections .systemFromTemplate .formCount svg {
        width: 40px;
        height: 40px;
        padding: 8px;
    }

}
@media only screen and (max-width: 1366px) {
    .accroding__wr .accordionContain .rightSide .countCard p {
        font-size: 14px;
        line-height: 21px;
    }

}
.form__wrapper .card.form-card{
    width: 100% !important;
}
.form__bottom .form__action__buttons {
    border: none;
    background: none;
    cursor: pointer;
}
.setting_container {
    height: 400px;
}
.editor-wrapper {
    width: 100%;
}
.systemFromTemplate .clone_button {
    border: none;
}
.systemFromTemplate .clone_button:focus {
    outline: none;
    background-color: transparent;
}
.editor-wrapper ul li input:not([type]), 
.editor-wrapper ul li input[type=text]:not(.browser-default), 
.editor-wrapper ul li input[type=password]:not(.browser-default), 
.editor-wrapper ul li input[type=email]:not(.browser-default), 
.editor-wrapper ul li input[type=url]:not(.browser-default), 
.editor-wrapper ul li input[type=time]:not(.browser-default),
.editor-wrapper ul li input[type=date]:not(.browser-default),
.editor-wrapper ul li input[type=datetime]:not(.browser-default), 
.editor-wrapper ul li input[type=datetime-local]:not(.browser-default),
.editor-wrapper ul li input[type=tel]:not(.browser-default), 
.editor-wrapper ul li input[type=number]:not(.browser-default), 
.editor-wrapper ul li input[type=search]:not(.browser-default), 
.editor-wrapper ul li textarea.materialize-textarea {
  border-bottom: none;
  background: #F6F7FB;
  border-radius: 5px;
  box-sizing: border-box;
}
.editor-wrapper .form-wrap.form-builder .frmb li{
    /* border-bottom: 3px solid #e5e5e5; */
    border: 1px solid #F6F7FB;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 20px;
}


.allform__sections .form__wrapper .card.form-card{
    box-shadow: none;
}
.allform__sections .form__wrapper .card.form-card input:not([type]), input[type=text]:not(.browser-default),
 .allform__sections .form__wrapper .card.form-card input[type=password]:not(.browser-default),
 .allform__sections .form__wrapper .card.form-card  input[type=email]:not(.browser-default),
  .allform__sections .form__wrapper .card.form-card  input[type=url]:not(.browser-default), 
   .allform__sections .form__wrapper .card.form-card input[type=time]:not(.browser-default),
   .allform__sections .form__wrapper .card.form-card  input[type=date]:not(.browser-default), 
   .allform__sections .form__wrapper .card.form-card  input[type=datetime]:not(.browser-default),
   .allform__sections .form__wrapper .card.form-card   input[type=datetime-local]:not(.browser-default),
   .allform__sections .form__wrapper .card.form-card    input[type=tel]:not(.browser-default), 
.allform__sections .form__wrapper .card.form-card input[type=number]:not(.browser-default), 
.allform__sections .form__wrapper .card.form-card input[type=search]:not(.browser-default), 
.allform__sections .form__wrapper .card.form-card textarea.materialize-textarea{
    background: #F6F7FB;
    border: 1px solid #E5E8EF;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 10px 12px;
}
input[type=text]:not(.browser-default).box_shadow_none:focus {
    box-shadow: none;
}
.allform__sections .form__wrapper .card.form-card .card-body{
    border: 1px solid #E5E8EF;
    border-radius: 5px;
    padding: 20px;
}
.allform__sections .form__wrapper .card.form-card .formbuilder-text-label{
    font-family: var(--poppins);
    font-style: normal;
    font-weight: 500;
    font-size: 16px!important;
    line-height: 24px;
    letter-spacing: 0.01em;
    text-transform: capitalize;
    color: #133159;
}

.allform__sections .form__wrapper .card.form-card .submit-btn{
    min-height: 50px;
    height: 100%;
    line-height: unset;
}


.createNewForm .modalBody .input-field .MuiFilledInput-underline:before,
.createNewForm .modalBody .input-field .MuiFilledInput-underline:after{
    display: none;
}.createNewForm .modalBody .input-field input{

}.createNewForm .modalBody .input-field input{

}
.createNewForm .modalBody  .input-color-picker{
    padding: 12px 15px;
    border-radius: 5px;
    background: #F6F7FB;
    border: 1px solid #E5E8EF;
    width: 100%;
}
.createNewForm .modalBody  .input-color-picker [type='color'] {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    padding: 0;
    width: 15px;
    height: 15px;
    border: none;
  }
  .createNewForm .modalBody  .input-color-picker label{
    position: relative;
    left: unset;
    width: 100%;
    display: flex;
    align-items: center;
    margin: 0;

  }
  .createNewForm .modalBody  .input-color-picker label span{
      padding-left: 5px;
      text-transform: initial;
  }
.createNewForm .modalBody  .pp_select_field,
.setting_container .pp_setting_select,
.pp_form_entry_select{
    background: #F6F7FB;
    border: 1px solid #E5E8EF;
    border-radius: 5px;
    width: 100%;
}
.createNewForm .modalBody  .pp_select_field select{
    padding: 15px 20px;
}
.createNewForm .modalBody  .pp_select_field::before,
.createNewForm .modalBody  .pp_select_field::after,
.setting_container .pp_setting_select::after,
.setting_container .pp_setting_select::before,
.pp_form_entry_select::before,
.pp_form_entry_select::after{
    display: none;
}
.createNewForm .modalBody .input-field .pp_switcher_field{
  display: flex;
  align-items: center;
}
.createNewForm .modalBody  #landing-page-bg-file{
    border: 1px solid rgba(19, 49, 89, 0.3);
    border-radius: 5px;
    height: 100%;
    padding: 10px 20px;
}
.createNewForm .modalBody .pp_form_style .MuiFilledInput-root{
  background-color: transparent;
}
.createNewForm .modalBody  .pp_switcher_field svg,
.setting_container .pp_switcher_field svg{
    width: 16px;
    display: flex;
    left: 5px;
}
.setting_container select{
    /* border: 1px solid #E5E8EF;
    border-radius: 5px; */
    padding: 15px 20px;
}

.pp_integration-modal .globalModalWrapper {
    width: 800px;
}

.from-builder-preview-wrapper{
    max-height: 300px;
    min-height: 300px;
    overflow-y: auto;
    padding-bottom: 0;
}

.no-form-preview-text-center{
    display: grid;
    place-items: center;
    max-height: 300px;
    min-height: 327px;
    overflow-y: auto;
    padding-bottom: 0;
}

.pp_integration-modal.integrate-html {
    bottom: 0;
}

.form-settings-form-builder.setting_container .input-field {
    margin-top: 3px!important;
    margin-bottom: 2px!important;
}

.formBuilder .input-wrap input[type='checkbox'] {
    opacity: 1 !important;
    margin-top: 10px !important;
    position: unset !important;
    pointer-events: auto;
}
.pp_integration-modal .integrate__form__v2{
    max-height: calc(100vh - 265px);
}


.formbuilder-radio  [type="radio"]:not(:checked), .formbuilder-radio  [type="radio"]:checked{
    opacity: 1;
    position: relative;
}

.formbuilder-checkbox  [type="checkbox"]:not(:checked), .formbuilder-checkbox  [type="checkbox"]:checked{
    opacity: 1;
    position: relative;
}

.form-wrap.form-builder .frmb li.form-field.checkbox-field {
    pointer-events: initial;
    opacity: 1;
}