.form__logo__upload__wr {
    width: 100%;
    padding: 30px 50px;
}

.form__logo__upload__wr .logo__upload__top__section{
  display: flex;
  align-items: center;
  margin-bottom: 24px;
}

.form__logo__upload__wr .logo__upload__size__section {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.form__logo__upload__wr .logo__upload__justify__between{
  justify-content: space-between;
}
.form__logo__upload__wr .logo__upload__justify__center{
  justify-content: center;
}

/*  Warning */

.form__logo__upload__wr .logo__upload__wrapper__v2__top {
    display: flex;
    align-items: center;
    grid-gap: 8px;
    margin: 0 0 24px 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    color: #546376;
}

.form__logo__upload__wr .logo__upload__wrapper__v2__top span {
    display: inline-flex;
}

/*  Size Dropdown */

.form__logo__upload__wr .logo__upload__size__section .logo__upload__size__dropdown{
    width: 30%;
}

.form__logo__upload__wr .logo__upload__size__section .logo__upload__size__dropdown span {
    font-size: 16px;
    color: rgb(55, 53, 47);
    font-weight: bold;
}

.form__logo__upload__wr .logo__upload__size__section .logo__upload__size__dropdown {
    display: inline-flex;
    float: right;
}

.form__logo__upload__wr .logo__upload__size__section .logo__upload__size__dropdown .MuiInputBase-root{
  width: 100%;
}

.form__logo__upload__wr .logo__upload__size__section .logo__upload__size__dropdown .MuiSelect-select:focus {
  box-shadow: 0 0 0 rgba(0, 0, 0, 0) !important;
  border-color: #ddd;
  width: 100%;
}

.form__logo__upload__wr .logo__upload__size__section .logo__upload__size__dropdown .MuiSelect-select {
  border-color: #ddd;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

/* Remove Logo */
.form__logo__upload__wr .logo__upload__top__remove__section{
  display: flex;
  align-items: center;
  grid-gap: 5px;
  cursor: pointer;
  background: #ff264a; 
  padding: 1px 8px;
  height: 34px;
  border-radius: 6px;
  color: #fff;
  font-size: 14px;
  font-weight: 400;
}

.form__logo__upload__wr .logo__upload__top__left {
  display: flex;
  align-items: center;
  grid-gap: 9px;
  justify-content: center;
}

.form__logo__upload__wr .logo__upload__top__left span {
  font-size: 18px;
  font-weight: 600;
}

.form__logo__upload__wr .load__upload__fill__with__background__section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0;
}
.form__logo__upload__wr .uploader__loader{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.form__logo__upload__wr .logo__upload__button__section {
  background: var(--dark_blue);
  max-width: 180px;
  width: 100%;
  color: #fff;
  border-radius: 6px;
  grid-gap: 10px;
  justify-content: center;
  height: 40px;
  align-items: center;
  text-align: center;
  display: flex;
  margin: 16px auto 0 auto;
  cursor: pointer;
}