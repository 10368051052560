.upload-modal .modal-header h2 {
  font-size: 1.5rem;
  text-transform: capitalize;
}
.upload-modal .modal-header svg {
  color: tomato;
  font-size: 1.5rem;
  margin-right: 5px;
}
.upload-modal .btn-close {
  background-color: tomato;
}

/* Upload Image */
.modal__body__area__v2 {
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  width: 100%;
  max-width: 715px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-sizing: border-box;
  border-radius: 6px;
  box-shadow: rgb(0 0 0 / 20%) 0 15px 25px 0;
}

.form__builder__modal.ar__form_builder > div {
    background-color: rgba(27, 31, 36, 0.5) !important;
}

.modal__body__area__v2 .form__builder__modal__add__close__button {
  position: absolute;
  right: -11px;
  top: -12px;
  z-index: 9;
  background: #ddd;
  height: 26px;
  border-radius: 50%;
  min-width: 26px;
  max-width: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  padding: 0;
}
.modal__body__area__v2 .form__builder__modal__add__close__button:hover {
  background: #ddd;
}
.modal__body__area__v2 .form__builder__modal__add__close__button svg {
  font-size: 18px;
}
.modal__body__area__v2 .form__builder__modal__add__search__bar {
  width: 100%;
}
.modal__body__area__v2 .display__input__field__modal__wr {
  width: 100%;
  padding: 0 0 24px 0;
  display: flex;
  height: 480px;
  overflow-y: auto;
}
.modal__body__area__v2 .display__input__field__modal__left {
  width: 100%;
  max-width: 249px;
  border-right: 1px solid #ddd;
}
.modal__body__area__v2 .logical__demo__content {
  width: 100%;
  overflow-y: auto;
  max-height: calc(100vh - 150px);
  box-sizing: border-box;
}

.modal-body-area > div {
  width: 100%;
  border-radius: 10px;
  padding: 10px;
}
.form-area {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.form-area .img-label svg {
  cursor: pointer;
  font-size: 4.5rem;
  transition: 500ms;
  color: rgb(28, 29, 32);
}

#uploadNow {
  display: none;
}

.form-area input[type="file"] {
  display: none;
}
.preview {
  border: 1px dashed rgba(0, 0, 0.5);
  padding: 5px;
}
.preview img {
  width: 100%;
}

.ar__form_builder .form__builder__modal .form__builder__modal__sidebar { 
    max-height: 100%; 
}

.ar__form_builder .makeStyles-search-1 .MuiInputBase-root .MuiInputBase-input {
    height: 60px;
    padding-left: 38px;
    border-radius: 0;
    font-weight: 300;
    font-size: 16px;
    padding-top: 10px;
    box-sizing: border-box;
    border-top-left-radius: 6px;
}

.ar__form_builder .makeStyles-search-1 .MuiInputBase-root .MuiInputBase-input:focus {
    box-shadow: unset;
    border-bottom-color: rgb(238, 238, 238);
}

.ar__modal_bodyV2 .form-area .fb__image-info-modal {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 20px;
  color: red;
  font-size: 14px;
}

