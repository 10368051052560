.specific__input__field__wr {
  display: flex;
  column-gap: 10px;
  align-items: center;
  position: relative;
}

.specific__input__field__wr__left {
  flex-basis: 100%;
  margin-top: -6px;
}

.ar__specific_input label.input__label {
  margin-bottom: 3px;
  display: block;
  color: #333;
}

.input__with__buttons {
  margin: 15px 0;
}

.specific__input__field__wr__left input {
  /* height: 36px !important; */
  height: 46px !important;
}

/* .input__label {
  font-weight: bold;
} */

.ar__body_container_form_builder .button-input-field {
  width: 100%;
}

.ar__body_container_form_builder .button-input-field label {
  width: 100%;
  padding: 0;
  height: 22px;
  font-size: 12px;
  line-height: 1.5;
  text-transform: uppercase;
  color: rgb(93 85 85 / 80%);
  box-sizing: border-box;
  font-weight: 400;
  user-select: none;
  display: block;
}

.ar__body_container_form_builder .button-input-field input {
  width: 100%;
  height: 36px;
  padding: 0px 10px;
  box-shadow: rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 12%) 0px 1px 1px 0px, rgb(60 66 87 / 16%) 0px 0px 0px 1px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(60 66 87 / 8%) 0px 2px 5px 0px;
  border: 0px;
  border-radius: 5px;
  outline: none;
  background-color: rgb(255, 255, 255);
}


.ar__body_container_form_builder .tippy-content {
  padding: 10px 10px;
}

.ar__body_container_form_builder .tippy-box[data-theme~=light] {
  color: #26323d;
  box-shadow: 0 0 20px 4px rgba(154, 161, 177, .15), 0 4px 80px -8px rgba(36, 40, 47, .25), 0 4px 4px -2px rgba(91, 94, 105, .15);
  background-color: #fff;
}

.ar__body_container_form_builder .ar__tippy {
  overflow: hidden;
  background-color: rgb(255, 255, 255);
  border-radius: 5px;
  box-shadow: rgb(60 66 87 / 5%) 0px 0px 0px 1px, rgb(60 66 87 / 10%) 0px 3px 6px, rgb(60 66 87 / 20%) 0px 9px 24px;
  max-width: 300px;
  width: 100%;
}

.FormBuilderModule-container-parent-class {
  background: #fff;
  height: auto;
  min-height: 100%;
}

.mr-10 {
  margin-right: 10px;
}

button.ar__send_button {
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  position: relative;
  border: 0px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  background: rgb(0, 0, 0);
  outline: none;
  transition: color 100ms ease-in 0s;
  text-align: left;
  color: rgb(255, 255, 255);
  font-weight: bold;
  padding: 15px;
}

.all__action__buttons button.MuiButton-root,
.ar__action_button_submit button.MuiButton-root {
  min-width: 50px;
  padding: 13px 15px;
  min-height: 50px;
}

.all__action__buttons button.MuiButton-root:hover,
.ar__action_button_submit button.MuiButton-root:hover {
  background: #00000066;
  color: #000;
}


.ar__action_button_submit button.MuiButton-root.ar__send_button:hover {
  color: #fff;
  background: rgba(0, 0, 0, 0.336);
}

.all__action__buttons .edit_input__option-button {
  min-width: 50px;
  padding: 13px 15px;
  min-height: 50px;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.04);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
}

.all__action__buttons .edit_input__option-button:hover {
  background: #00000066;
  color: #000;
}



.ar__specific_input input[type="text"],
.ar__specific_input input[type="number"],
.ar__specific_input textarea,
.ar__specific_input input[type="tel"],
.ar__specific_input input[type="date"],
.ar__specific_input input[type="time"],
.ar__specific_input input[type="email"] {
  padding: 0px 10px;
  box-shadow: rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 12%) 0px 1px 1px 0px, rgb(60 66 87 / 16%) 0px 0px 0px 1px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(60 66 87 / 8%) 0px 2px 5px 0px;
  border-radius: 5px;
  background-color: rgb(255, 255, 255);
  height: 30px !important;
  margin: 0 !important;
  border: 0 !important;
}
.ar__specific_input label.input__label.ar__input__label input {
    height: 30px !important;
}
.ar__specific_input textarea {
    resize: none;
    padding-top: 7px;
    height: unset !important;
    min-height: 60px;
}

.ar__specific_input.alt input {
    height: 46px !important;
    margin-top: 8px !important;
}
.ar__specific_input input[type="time"]:focus {
  box-shadow: rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 12%) 0px 1px 1px 0px, rgb(60 66 87 / 16%) 0px 0px 0px 1px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(60 66 87 / 8%) 0px 2px 5px 0px;
}
.ar__specific_input textarea:focus {
  outline: 0;
}
.customRow {
  display: flex;
  column-gap: 10px;
  align-items: center;
}

.ar__upload_logo{
  flex: 1 1 0%;
    display: flex;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    height: 100%;
    min-height: 150px;
    padding: 20px;
    border: 1px dashed rgb(193, 201, 210);
    border-radius: 5px;
    text-align: center;
    cursor: pointer;
}

.ar__multiple_checkbox button {
   position: relative;
   display: flex;
   padding: 8px 10px;
   box-shadow: rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 12%) 0px 1px 1px 0px, rgb(60 66 87 / 16%) 0px 0px 0px 1px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(60 66 87 / 8%) 0px 2px 5px 0px;
   border-radius: 5px;
   background-color: rgb(255, 255, 255);
   cursor: pointer;
   border: 0;
}

.ar__multiple_checkbox button p {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    width: 18px;
    height: 18px;
    min-width: 18px;
    min-height: 18px;
    background-color: rgb(102, 102, 102);
    font-size: 12px;
    font-weight: 900;
    line-height: 1;
    color: rgb(255, 255, 255);
    border-radius: 3px;
    margin-right: 10px;
}

.ar__file_uploads.file-upload-wrapper {
       position: relative;
       padding: 20px;
       cursor: pointer;
       border: 1px dashed rgb(193, 201, 210);
       border-left: 1.7px dashed rgb(193, 201, 210);
       border-radius: 5px;
       box-sizing: border-box;
       width: calc(100% - 5px);
}



.ar___embed_modal .modal__body__area__v2.ar__modal_bodyV2 input + button {
    padding: 8px 12px;
    border: 0px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    background: rgb(0, 0, 0);
    outline: none;
    transition: color 100ms ease-in 0s;
    color: rgb(255, 255, 255);
    font-weight: bold;
}

.ar___embed_modal .modal__body__area__v2.ar__modal_bodyV2 b {
    padding: 12px 0px;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    margin-bottom: -1px;
    text-decoration: none;
    white-space: nowrap;
    color: rgb(0, 0, 0);
    border-bottom: 2px solid rgb(0, 0, 0);
    margin-right: auto;
}

.ar___embed_modal .modal__body__area__v2.ar__modal_bodyV2 input {
    width: 100%;
    height: 36px;
    padding: 0px 10px;
    box-shadow: rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 12%) 0px 1px 1px 0px, rgb(60 66 87 / 16%) 0px 0px 0px 1px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(60 66 87 / 8%) 0px 2px 5px 0px !important;
    border: 0 !important;
    border-radius: 5px;
    background-color: rgb(255, 255, 255);
    margin: 20px 0;
    outline: 0;
}

.ar___embed_modal .modal__body__area__v2.ar__modal_bodyV2 {
    padding: 20px 40px;
    box-sizing: border-box;
}

/* UPload logo */
.ar__upload-modal .ar__modal_bodyV2 h2 {
    font-size: 26px;
}

.ar__upload-modal .form-area {
    display: flex;
    flex-direction: column;
}

.ar__upload-modal label {
    margin-top: 25px;
}

.ar__upload-modal .form-area .img-label svg {
    font-size: 40px;
}

.ar__upload-modal .form-area input[type="file"] {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    margin: 0 !important;
    opacity: 0;
    cursor: pointer;
}
.ar__upload-modal .modal__body__area__v2.ar__modal_bodyV2 {
    min-height: 250px;
    max-width: 550px;
}
.ar__upload-modal .form-area div > svg {
    font-size: 25px;
    margin-right: 12px;
}

.ar__input_group_item textarea,
.ar__input_group_item input {
    padding-right: 35px !important;
}

.ar__input_group_item textarea + svg, .ar__input_group_item input + svg {
    position: absolute;
    right: 10px;
    top: 50% !important;
    opacity: 0.85;
    transform: translateY(-50%);
}

.ar__input_group_item.ar__input_group_item_sa input + svg {
    top: 6px !important;
    transform: unset;
}
.ar__input_group_item.ar__input_group_item_la input + svg {
    top: px !important;
    transform: unset;
}
body .specific__input__field__wr__left.ar__specific_input input.ppfb__label_input {
    all: unset !important;
    font-weight: 400 !important;
    opacity: 1 !important;
    border: 0 !important;
    height: auto !important;
    margin: 0 0 5px !important;
    padding: 0 !important;
    box-shadow: unset !important;
    font-size: 15px !important;
    cursor: text !important;
}

body .specific__input__field__wr__left.ar__specific_input input.ppfb__label_input::placeholder {
  color: #d6d3d2 !important;
}

.ar__specific_input label.input__label.ar__input__label input.ppfb__label_input + input {
  margin-top: 3px !important;
}
.body__container__form__builder .textarea2 .ar__req,
.body__container__form__builder .alter.customRow span.ar__req {
  top: 11px;
}

.ar__input_group_item input.ppfb__label_input + input + svg {
  top: 65% !important;
}

.body__container__form__builder .textareaAlt3 .ar__req {
    top: 13px;
}

.body__container__form__builder .ar__nmbr.alter.customRow span.ar__req {
    top: 5px !important;
}

.body__container__form__builder .specific__input__field__wr__left.ar__specific_input.ar__input_group_item.ar__nmbr + span.ar__req {
    top: 11px !important;
}

.ar_shrtans .ar__input_group_item input.ppfb__label_input + input + svg {
    top: 50% !important;
}

.ar__input_group_item.ar__nmbr input.ppfb__label_input + input + svg {
  top: 72% !important;
}

body .ar__lanswer .ar__input_group_item textarea + svg {
    top: 60px !important;
}
.FormBuilderModule-container-parent-class .file__upload__with__label + .ar__req {
  position: absolute;
  right: -5px;
  font-size: 25px;
  color: #c00;
  top: 35px;
}

.FormBuilderModule-container-parent-class .file__upload__with__label .file__upload__with__label__input {
  position: relative;
  top: 20px;
}

.form__builder__background__image__section .body__edit_progress_bar{
  margin: auto;
  width: 60%;
}

.FormBuilderModule-container-parent-class .formbuilder-checkbox label {
    margin-left: -17px;
    padding-left: 17px;
}