.heading_1 {
  font-weight: bolder !important;
  border: none !important;
  outline: none !important;
  background: none !important;
  /* box-sizing: unset !important;
  border-radius: unset !important; */
}

.heading_2 {
  font-weight: bolder !important;
  border: none !important;
  outline: none !important;
  background: none !important;
}

.heading_3 {
  font-weight: bolder !important;
  border: none !important;
  outline: none !important;
  background: none !important;
}

.label {
  font-size: 16px !important;
  font-weight: bolder !important;
  border: none !important;
  outline: none !important;
  background: none !important;
}

.texts {
  font-size: 16px !important;
  font-weight: normal !important;
  border: none !important;
  outline: none !important;
  background: none !important;
}

.specific__input__field__wr__left.ar__specific_input input.ar__title_heading {
  height: auto !important;
  padding: 6px 15px;
  box-shadow: none !important;
  font-size: 30px;
  font-weight: 600;
  line-height: 1.25;
  border: 0 !important;
}

.specific__input__field__wr__left.ar__specific_input input.heading_2 {
  font-size: 24px;
}

.specific__input__field__wr__left.ar__specific_input input.heading_3 {
  font-size: 20px;
}
