.cover-area {
  width: 100%;
  height: 170px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
  /* background-color: #116dfa47; */
}
.cover-area img {
  max-width: 100%;
  height: 100%;
  object-fit: cover;
  width: 100%;
}
.cover-area .action-button {
  position: absolute;
  right: 20px;
  bottom: 20px;
  border-radius: 4px;
}
.cover-area .action-button span {
  color: #fff;
  font-weight: 600;
  text-transform: capitalize;
}

.cover-area .action-button button {
  transition: 500ms;
  cursor: pointer;
  border: none;
  display: inline-flex;
  align-items: center;
  background: #0d6efd;

}

.cover-area .action-button button:hover {
  background-color: tomato;
}

.cover-area .action-button button > span > svg{
  margin-right: 8px;
}

.logo-container > div {
  position: relative;
  height: 100px;
}
.logo-area {
  width: 250px;
  /* height: 100px; */
  display: inline-flex;
  justify-content: center;
  align-items: center;
  /* overflow: hidden; */
  /* border-radius: 100px; */
  /* background-color: #f6f6f6; */
  position: relative;
}
.preview__top__fixed{
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 333;
}
.preview__top__fixed + .new__form__builder__preview__body{
  padding: 210px 10px 0 10px;
}
.logo-area .form__logo__delete{
  position: absolute;
    border: 1px solid transparent;
    right: 2px;
    top: -26px;
    z-index: 77;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    color: #f00;
    background: #fff;
    box-shadow: 1px 1px 10px rgb(0 0 0 / 50%);
    cursor: pointer;

}
.bouncing {
  animation: 2s bounce infinite;
}
@keyframes bounce {
  50% {
    transform: scale(0.8);
  }
}
.logo-area img {
  width: 100%;
  max-width: 150px;
  height: auto;

}

/*  */
.ar__formControlLabel_grid button {
  min-width: 50px;
  margin-left: 10px;
}

.ar__formControlLabel {
  display: flex;
  align-items: center;
  margin: 0 !important;
}

.ar__formControlLabel_grid .col-lg-10 {
  width: calc(100% - 62px);
}

.ar__formControlLabel_grid {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex: 1;
  width: 100%;
}

.ar__formControlLabel > span {
  padding: 0px ​4px 4p;
}

label.MuiFormControlLabel-root.ar__formControlLabel {
  width: 100%;
}
span.MuiTypography-root.MuiFormControlLabel-label.MuiTypography-body1 {
  width: 100% !important;
  padding-top: 0;
  padding-bottom: 0;
}

.justify_center ul {
  justify-content: center;
}

.ar__formControlLabel_grid.mb1 {
  margin-bottom: 10px !important;
}

.ar__formControlLabel > span {
  padding: 0px 4px 4px;
}

button.ar__add_option {
  position: relative;
  height: 32px;
  padding: 0px 12px;
  border: 0px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  background: rgb(0, 0, 0);
  outline: none;
  transition: color 100ms ease-in 0s;
  text-align: left;
  color: rgb(255, 255, 255);
  font-weight: bold;
}

input.mt3-space + .ar__formControlLabel {
  margin-top: 10px !important;
}

.cover-area .cover_image__drag_info {
  position: absolute;
  background: rgba(0,0,0,0.7);
  color: white;
  padding: 10px;
  border-radius: 10px;
}

.cover-area.cursor-mouse-move {
  cursor: move;
}

/*  Logo */
/* .form__logo__fixed__width {
  width: 200px;
  height: auto;
} */

/* .form__logo__fixed__height img {
  height: 150px;
  object-fit: cover;
  object-position: center;
} */

.preview__image__ratio {
  width: 150px;
  height: 150px;
  background-size: 150px;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 4px;
  margin-top: -75px;
}

.preview__image__ratio.fill__with__color {
  background-color: #fff;
}

.preview__image__ratio.Rectangular__logo {
  width: 250px;
  height: 200px;
  background-size: 250px;
    margin-top: -100px;
}

.preview__image__ratio.Circular__logo {
  border-radius:100%;
}