.form_entry_container .section__top__right{
    display: flex;
}
.form_entry_container .section__top__link .section__top__right{
    width: fit-content;
}
.form_entry_container .section__top__link li{
    height: 40px;
    width: 40px;
}
.form_entry_container .form_builder.body-search input{
    border-radius: 5px !important;
    height: 40px !important;
    width: auto !important;
}
.datatable-area.pp_form_entry_table_wrapper {
    padding: 20px;
    background: white;
    border-radius: 5px;
}
.Drip_table.pp_form_entry_table {
    background: white;
}
.pp_form_entry_select{
    padding: 5px 10px;
    margin: 10px 0px;
}
.pp_form_entry_table .rdt_TableHead *{
    font-family: var(--poppins);
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 36px;
    color: #133159;
}
.pp_form_entry_table .rdt_TableBody .rdt_TableCell{
    font-family: var(--poppins);
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 24px;
    color: rgba(19, 49, 89, 0.65);
}
.pp_add_to_campaigns{
    display: flex;
    align-items: center;
    position: relative;
}
.globalModalWrapper .campaign_modal .pp_add_to_campaigns input{
    box-sizing: border-box;
    padding: 10px 20px;
}
.pp_add_to_campaigns svg{
    top: auto;
    position: absolute;
    right: 15px;
}
.pp_form_entry_table_wrapper .datatable_helper {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.pp_form_entry_table_wrapper .select-wrapper {
    width: 80px;
    background: #fff;
}

.pagination_bar ul {
    display: flex;
    justify-content: center;
}

.pagination_bar ul li a {
    color: #fff;
    background: #3C7EF3;
    border-radius: 5px;
    padding: 8px 23.5px;
    margin-left: 10px;
    display: block;
    font-weight: 600;
    border: 2px solid #3C7EF3;
    font-size: 18px;
    line-height: 30px;
}

.pagination_bar ul li:nth-child(2) a {
    background: transparent;
    border: 2px solid #3C7EF3;
    color: #3C7EF3;
}

.pagination_bar ul li:nth-child(3) a {
   background: transparent;
   color: #3C7EF3;
   border: unset;
   padding: 8px 6px;
}

.datatable_helper .total_entries {
   color: #657993;
}
.body-search form{
    position: relative;
    border: 1px solid #dddddd;
    border-radius: 5px;
}

.body-search form input {
    width: 214px !important;
    background: #FFFFFF !important;
    border-radius: 20px !important;
    border: none !important;
    display: block;
    padding: 8px 10px !important;
    font-style: normal;
    font-weight: normal;
    font-size: 14px !important;
    line-height: 24px;
    color: #BABABA;
    height: auto !important;
    min-height: auto;
    margin: 0px !important;
    overflow: hidden;
}
.body-search form input:placeholder{
    opacity: 1 !important;
}
.body-search form input:focus{
    box-shadow: inherit !important;

}
body .search-data-table .body-search input {
    margin: 0 !important;
    height: 42px !important;
    padding-top: 0px !important;
    padding-bottom: 0 !important;
}
.body-search form button {
    position: absolute;
    top: 50%;
    right: 3px;
    background: transparent;
    border: none;
    transform: translateY(-50%);
}

.body-search form button i{
    color: #3C7EF3;
}

.custom_form_builder_pages_dropdown input {
    margin: 0 !important;
    padding: 0 !important;
    height: auto !important;
}

.custom_form_builder_pages_dropdown .MuiSelect-select {
    padding: 0 0 0 12px !important;
    line-height: 43px !important;
}

.custom_form_builder_pages_dropdown .MuiInput-formControl {
    margin: 0 !important;
    flex: 1;
    width: 100%;
}

.custom__field__chip {
    font-size: 10px !important;
    margin-left: 8px;
    height: 17px !important;
}

.input-field .custom_form__file_entry {
    color: #039be5;
    display: flex;
    align-items: center;
    padding-bottom: 0.5rem;
    padding-top: 0.5rem;
    margin-right: 0.25rem;
}

.input-field .custom_form__file_entry svg {
    margin-right: 0.25rem;
}

.input-field .custom_form__file_entry:hover {
    color: #075b85;
}