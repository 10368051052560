.toggle-container {
  width: 70px;
  background-color: #dfe4ea;
  cursor: pointer;
  user-select: none;
  border-radius: 18px;
  height: 25px;
  position: relative;
}

.dialog-button {
  font-size: 14px;
  line-height: 16px;
  font-weight: bold;
  cursor: pointer;
  background-color: #2ecc71;
  color: white;
  padding: 4.5px 12px;
  border-radius: 30px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  min-width: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 38px;
  min-width: unset;
  border-radius: 18px;
  box-sizing: border-box;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  position: absolute;
  left: 34px;
  transition: all 0.3s ease;
}

.disabled {
  background-color: #e74c3c;
  left: 2px;
}
