.form-card{
  width: 100% !important;
  box-shadow: unset !important;
  padding: 20px;
}
.field-email input{
  background: #F6F7FB !important;
  border: 1px solid #E5E8EF !important;
  box-sizing: border-box !important;
  border-radius: 5px !important;
  padding: 10px 12px !important;
}
.field-first_name input:focus,
.field-email input:focus{
  box-shadow: unset !important;
  border-bottom: 1px solid #E5E8EF !important;
}
.form_url_area {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.form_url_area .copy__button {
  border: none;
}
.form_url_area .copy__button:focus {
  background: none;
}

.setting_container label {
  width: 100%;
  display: inline-block;
}

#fb-rendered-form .form-control{
  background: #F6F7FB !important;
  border: 1px solid #E5E8EF !important;
  box-sizing: border-box !important;
  border-radius: 5px !important;
  padding: 10px 12px !important;
}
.form-wrap.form-builder .stage-wrap {
  min-height: unset !important;
  height: 740px !important;
  overflow-y: auto;
}
.editor-wrapper{
  z-index: 99;
}
.editor-wrapper .form-wrap.form-builder .frmb{
  padding: 20px;
}
.form-wrap.form-builder .cb-wrap{
  height: 740px;
  overflow-y: auto;
}

#fb-rendered-form select.form-control, #fb-rendered-form input.form-control{
  height: 3rem;
}

.FormBuilderModule-container-parent-class .checkbox-field.form-field.editing .option-actions {
  display: none !important;
}

.FormBuilderModule-container-parent-class .checkbox-field.form-field.editing .form-group.className-wrap{
  display: none !important;
}

.FormBuilderModule-container-parent-class .checkbox-field.form-field.editing .form-group.name-wrap{
  display: none !important;
}

.FormBuilderModule-container-parent-class .checkbox-field.form-field.editing .form-group.field-options .option-label.option-attr:nth-child(2) {
  width: 100%;
}

.FormBuilderModule-container-parent-class .checkbox-field.form-field.editing .form-group.field-options .option-value.option-attr:nth-child(3) {
  display: none !important;
}

.FormBuilderModule-container-parent-class .checkbox-field.form-field.editing .form-group.field-options .false-label {
  color: transparent;
}

.FormBuilderModule-container-parent-class .checkbox-field.form-field.editing .form-group.field-options .false-label:after {
  content: "Add your text here";
  color: #9e9e9e;
  margin-left: -35px;
}

#fb-rendered-form .formbuilder-checkbox input {
  margin-right: 4px;
}