.logical__demo__content__right__v2 {
	padding: 20px;
}

.logical__demo__content__right__v2 .logical__demo__content__right__top__v2 {
	display: flex;
	justify-content: space-between;
	padding-bottom: 50px;
}

.logical__demo__content__right__v2 .logical__demo__content__right__right button {
	text-transform: capitalize;
	cursor: pointer;
	font-size: 14px;
	font-weight: 700;
	color: rgb(255, 255, 255);
	background-color: rgb(248, 28, 229);
	transition: all 0.4s;
}

.logical__demo__content__right__v2 .logical__demo__content__right__right button.Mui-disabled {
	background-color: rgb(248, 28, 229,0.4);
}

.logical__demo__content__middle__label {
	padding-top: 40px;
}

.logical__demo__content__right__v2 .logical__demo__content__right__right button:hover {
	opacity: 0.75;
}

.logical__demo__content__right__v2 .logical__demo__content__right__right .MuiButton-label {
	display: flex;
	grid-gap: 5px;
}

.logical__demo__content__right__v2 .logical__demo__content__right__left h5 {
	font-size: 20px;
	margin-bottom: 10px;
	font-weight: 700;
	color: rgb(55, 53, 47);
}

.logical__demo__content__right__v2 .logical__demo__content__right__left {
	margin: 0 40px 0 0;
}

.logical__demo__content__right__v2 .logical__demo__content__right__left p {
	color: rgb(68, 68, 68);
	font-size: 14px;
	line-height: 1.5;
}

.logical__demo__content__right__v2 .logical__demo__content__middle__v2 {
	/* padding: 25px 0; */
	border-top: 1px solid rgb(238, 238, 238);
	position: relative;
}

.logical__demo__content__right__v2 .logical__demo__content__middle__heading__v2 {
	display: inline-block;
	padding: 2px 4px;
	border-radius: 4px;
	font-size: 11px;
	font-weight: bold;
	line-height: 1.15;
	text-transform: uppercase;
	color: rgb(136, 136, 136);
	background: rgba(136, 136, 136, 0.125);
	position: absolute;
	top: -9px;
	left: 20px;
}

.logical__demo__content__middle__v2 .logical__demo__content__middle__label .input__label {
	font-size: 14px;
	font-weight: bold;
	line-height: 1.618;
	color: rgb(55, 53, 47);
}

.logical__demo__content__middle__v2 .logical__demo__content__middle__label.alt .input__label input,
.logical__demo__content__middle__v2 .logical__demo__content__middle__label.alt .input__label textarea {
	margin-top: 5px;
	font-weight: 300;
	font-size: 16px;
	color: #535343 !important;
}

.logical__demo__content__middle__v2 .logical__demo__content__middle__label.alt input[type="text"],
.logical__demo__content__middle__v2 .logical__demo__content__middle__label.alt input[type="email"],
.logical__demo__content__middle__v2 .logical__demo__content__middle__label.alt input[type="tel"],
.logical__demo__content__middle__v2 .logical__demo__content__middle__label.alt input[type="date"] {
	background: #fff !important;
	border: 1px solid #e5e8ef;
	box-sizing: border-box;
	border-radius: 5px;
	padding: 10px 12px;
	box-shadow: unset !important;
	font-weight: 300 !important;
}

.logical__demo__content__middle__v2 .logical__demo__content__middle__label.alt .input__label textarea::placeholder,
.logical__demo__content__middle__v2 .logical__demo__content__middle__label.alt input[type="text"]::placeholder,
.logical__demo__content__middle__v2 .logical__demo__content__middle__label.alt input[type="email"]::placeholder,
.logical__demo__content__middle__v2 .logical__demo__content__middle__label.alt input[type="tel"]::placeholder,
.logical__demo__content__middle__v2 .logical__demo__content__middle__label.alt input[type="date"]::placeholder {
	color: #535353 !important;
}

.ar__form_builder .logical__demo__content textarea {
	padding: 10px;
	border: 1px solid #ddd !important;
	border-radius: 5px;
	resize: none;
	min-height: 120px;
	box-shadow: unset !important;
	outline: 0;
}

.mt-40px {
	margin-top: 40px !important;
}

.ar__customFormGroup input {
	border: 1px solid #e5e8ef !important;
	outline: 0 !important;
	box-shadow: unset !important;
	color: #535343 !important;
	font-weight: 300 !important;
	background: #fff !important;
	border-radius: 5px !important;
	padding: 10px 12px !important;
}

fieldset.MuiFormControl-root .Mui-checked + span {
	color: #514f4a;
}

fieldset.MuiFormControl-root .Mui-checked {
	color: #514f4a;
}

.ar__customFormGroup input::placeholder {
	color: #535343 !important;
}

.ar__form_builder .logical__demo__content textarea:focus {
	outline: 0;
}

.logical__demo__content__middle__v2 .logical__demo__content__middle__label.alt input[type="text"]:focus,
.logical__demo__content__middle__v2 .logical__demo__content__middle__label.alt input[type="email"]:focus {
	box-shadow: unset;
}

.ar__form_builder p.text-muted {
	font-size: 12px;
	font-weight: 400;
	font-style: italic;
	margin: 8px 0;
}

/* .logical-content .top-content {
  display: flex;
  justify-content: space-between;
}
.logical-content .top-content .block-left h5 {
  font-weight: 700;
}
.logical-content .top-content .block-left {
  width: 70%;
}

.logical-content .bottom-content {
  padding: 30px 0;
  border-top: 1px solid rgba(0, 0, 0, 0.15);
  margin-top: 20px;
}

.logical-content .bottom-content h6 {
  font-weight: 700;
  color: rgb(48, 48, 48);
}
.logical-content .bottom-content small {
  color: black;
  font-weight: 700;
}
.loveFeedback {
  font-weight: bold;
}
.minutes {
  color: red;
}
.newPageSample {
  background-color: gray;
  height: 80px !important;
  margin-bottom: 20px;
}

.newPageSample h4 {
  text-align: center;
}

.embed__image {
  width: 400px;
  height: 260px;
} */

.MuiFormControl-root.ar__dropdown {
	width: 100%;
	border: 1px solid #e5e8ef;
	border-radius: 5px;
	padding: 12px;
	box-sizing: border-box;
}

.MuiFormControl-root.ar__dropdown label + .MuiInput-formControl {
	margin-top: 0;
}

.MuiFormControl-root.ar__dropdown .MuiSelect-select.MuiSelect-select {
	padding: 0;
}

.MuiFormControl-root.ar__dropdown .MuiInputLabel-formControl {
	top: -12px;
	left: 10px;
	background: #fff;
}

.MuiFormControl-root.ar__dropdown .MuiInputLabel-formControl.MuiFormLabel-filled {
	top: -8px;
}

.ar__heading {
	margin: 40px 0 0;
}
.ar__heading h1,
.ar__heading h2,
.ar__heading h3 {
	margin-bottom: 12px;
	font-size: 30px;
	line-height: 32px;
}

.logical__demo__content__middle__label.alt.v2 label {
	font-weight: 400;
	color: #000;
	margin-bottom: 5px;
	display: block;
	font-size: 14px;
}

.logical__demo__content__middle__label.alt.v2 input + label {
	margin-top: 10px;
}

.logical__demo__content__middle__label.alt.v3 p {
	font-size: 14px;
	font-weight: 300;
}

.logical__demo__content__middle__label.alt.v3 p span {
	font-weight: 600;
}

.logical__demo__content__middle__label.alt.v3 p span.minutes {
	background: #e873e575;
	font-weight: 400;
	padding: 0 3px;
}

.newPageSample {
	background: #f5f5f5;
	border-radius: 5px;
	max-width: 250px;
	display: flex;
	align-items: center;
	justify-content: center;
	min-height: 80px;
	font-size: 18px;
	user-select: none;
}

.newPageSample + .newPageSample {
	margin-top: 12px;
}

.newPageSample h4 {
	font-size: inherit;
	font-weight: inherit;
	line-height: inherit;
	color: #a1a1a1;
	text-transform: capitalize;
	text-align: center;
}

img.embed__image {
	max-width: 250px;
}

/* Multiple Choice Layout */
.logical__demo__content__middle__v2.multiple_choice_wrapper h6.logical__demo__content__middle__heading__v2::before {
	content: "Select a View";
	font-size: 20px;
	visibility: visible;
	display: block;
	text-transform: capitalize;
}

.logical__demo__content__middle__v2.multiple_choice_wrapper h6.logical__demo__content__middle__heading__v2 {
    visibility: hidden;
    top: -36px;
    left: -4px;
    color: #e873e5;
    right: 0;
    text-align: center;
}

.multiple-choice-grid-wrapper {
	margin-top: 20px;
}

.multiple-choice-grid-wrapper .MuiGrid-item {
	border: 1px solid #efefef;
	border-radius: 8px;
	padding: 20px 10px 0;
	max-width: 48%;
	flex-basis: 48%;
	background: #fff;
	cursor: pointer;
}

.multiple-choice-grid-wrapper .MuiGrid-item + .MuiGrid-item {
	margin-left: 10px;
}

.multiple-choice-grid-wrapper .MuiGrid-item .mb-3 {
	margin-top: 10px !important;
	margin-bottom: 0 !important;
}

.multiple-choice-grid-wrapper .MuiGrid-item h6 {
	font-size: 16px;
	font-weight: 400;
}

.multiple-choice-grid-wrapper .MuiGrid-item.active {
	background: #d0deeb;
}

.logical__demo__content__middle__v2.multiple_choice_wrapper {
	margin-top: -12px;
	border-top: none;
}

.multiple-choice-grid-wrapper .ar__multiple_checkbox button {
	min-width: 110px;
	box-shadow: unset !important;
	border: 1px solid #ddd !important;
}

.multiple-choice-grid-wrapper .MuiGrid-item .mb-3.mt-40px {
	margin-top: 0 !important;
}

.multiple-choice-grid-wrapper .grid_view_col_6 button {
  flex-direction: column;
  align-items: center;
  border: 1px solid #546376 !important;
  border-radius: 5px;
  box-shadow: unset !important;
  outline: 0 !important;
  max-width: 65px;
  max-height: 65px;
  justify-content: center;
  padding: 5px 12px;
  margin: 0 12px 12px 0;
  box-sizing: border-box;
  min-width: unset;
}

.multiple-choice-grid-wrapper .grid_view_col_6 button span { 
	font-weight: 400;
	font-size: 16px;
	line-height: 24px;
  display: block;
	color: #546376;
}

.multiple-choice-grid-wrapper .grid_view_col_6 button svg {
  margin-top: 10px;
  width: 24px;
  height: 24px;
}

.multiple-choice-grid-wrapper .grid_view_col_6 .mb-3 {
	display: flex;
	flex-wrap: wrap;
}


/* Preview View Style */
.ar__flex_column.pfb-preview-form-builder button {
    background: #fff;
    border-radius: 5px;
    border: 1px solid rgba(60, 126, 243, 0.1);
    width: 128px !important;
    height: 128px;
    display: inline-flex;
    margin: 0 24px 24px 0 !important;
    min-width: unset !important;
	box-sizing: border-box;
}
.ar__flex_column.pfb-preview-form-builder button:focus {
	background: #2ab7a9;
}
.ar__flex_column.pfb-preview-form-builder button:hover {
    border-color:  #3C7EF3;
    background: #F6F7FB;
}

.ar__flex_column.pfb-preview-form-builder button span {
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.ar__form_global_control.ar__multiple .ar__flex_column.pfb-preview-form-builder button span span {
    margin: 0 0 16px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;   
    color: #546376 !important;
    background: transparent;
    text-transform: capitalize;
}

.ar__form_global_control.ar__multiple .ar__flex_column.pfb-preview-form-builder button.MuiButton-contained span span {
	color: #fff !important;
}

.ar__form_global_control.ar__multiple .ar__flex_column.pfb-preview-form-builder button span svg {
    width: 40px;
    height: 40px;
}

.ar__form_global_control.ar__multiple .ar__flex_column.pfb-preview-form-builder {
    flex-wrap: wrap;
    flex-direction: row;
    max-width: 304px;
    justify-content: center;
}

.ar__form_global_control.ar__multiple .ar__flex_column.pfb-preview-form-builder button + button {
    margin: 0;
}

.new__form__builder__preview__body .pmc_ar__multiple legend {
    margin-bottom: 16px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px; 
    text-transform: capitalize; 
    color: #133159;
}