@import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300;400;700&display=swap");
html,
body {
  font-family: "Roboto Condensed", sans-serif;
}
ul {
  list-style: none;
  margin: 0;
}

.FormBuilderModule-container-parent-class .header-area {
    display: flex;
    justify-content: center;
    align-items: center;
    position: sticky;
    z-index: 999;
    height: 60px;
    border-bottom: 1px solid transparent;
    box-shadow: rgb(60 66 87 / 4%) 0 2px 5px 0;
    background: #fff;
    box-sizing: border-box;
    /* margin: -16px -20px 0; */
}

.header-area ul {
  display: flex;
  grid-gap: 10px;
}
.header-area .newFormBuilderBack,
.header-middle ul li {
    transition: all 0.4s;
    position: relative;
    display: flex;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
    min-width: 40px;
    padding: 5px 8px;
    font-size: 11px;
    color: rgb(119, 119, 119);
    margin: 0;
    cursor: pointer;
    border-radius: 5px;
}
.header-middle ul li + li:before {
    background: #dddddd7d;
    height: 70%;
    display: block;
    content: '';
    position: absolute;
    width: 1px;
    left: -6px;
}
.header-area .newFormBuilderBack > svg,
.header-middle ul li > svg {
    display: block;
    margin-bottom: 6px;
    color: rgb(119, 119, 119);
}

.header-area .newFormBuilderBack {
    color: rgb(119, 119, 119);
    position: absolute;
    left: 8px;
}

.header-middle ul li a {
  text-decoration: none;
  color: black;
}
.header-area .newFormBuilderBack:hover,
.header-middle ul li:hover {
    background-color: rgb(245, 245, 245);
    color: rgb(68, 68, 68);
}
.header-area .newFormBuilderBack:hover > svg,
.header-middle ul li:hover > svg {
    color: rgb(68, 68, 68);
}

.header-middle ul li span {
  display: block;
  font-size: 0.8rem;
}

/* li.form-save-button:active:after {
  box-shadow: 0 0 0 0 white;
  position: absolute;
  border-radius: 4em;
  left: 0;
  top: 0;
  opacity: 1;
  transition: 0s;
} */

li.form-save-button {
  box-shadow: 0 0 0 0 #c9c9c9;
  -webkit-animation: pulsing 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  -moz-animation: pulsing 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  -ms-animation: pulsing 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  animation: pulsing 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
}

/* Animation */

@-webkit-keyframes pulsing {
  to {
      box-shadow: 0 0 0 30px rgba(232, 76, 61, 0);
  }
}

@-moz-keyframes pulsing {
  to {
      box-shadow: 0 0 0 30px rgba(232, 76, 61, 0);
  }
}

@-ms-keyframes pulsing {
  to {
      box-shadow: 0 0 0 30px rgba(232, 76, 61, 0);
  }
}

@keyframes pulsing {
  to {
      box-shadow: 0 0 0 30px rgba(232, 76, 61, 0);
  }
}

.add_form_title_editor_wrapper .mce-content-body:focus-visible{
  outline: 0;
}