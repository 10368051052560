.choice-icon-picker-modal.ar__upload-modal .modal__body__area__v2.ar__modal_bodyV2 {
	max-width: 750px;
	background: #f6f7fb;
	border-radius: 10px;
}

.choice-icon-picker-modal header {
	padding: 20px 20px 10px;
	border-bottom: 2px solid #fff;
	width: 100%;
	box-sizing: border-box;
}

.choice-icon-picker-modal header h3 {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 500;
	font-size: 20px;
	line-height: 30px;
	display: flex;
	align-items: center;
	letter-spacing: 0.01em;
	color: #133159;
}

.choice-icon-picker-modal .modal__body__area__v2 .form__builder__modal__add__close__button {
	background: #ff264a;
	color: #fff;
	top: 20px;
	right: 20px;
}

.choice-icon-picker-modal .cipm-body-wrapper {
	margin: 20px;
	background: #fff;
	border: 1px solid #e5e8ef;
	box-sizing: border-box;
	border-radius: 5px;
	width: calc(100% - 40px);
}

.choice-icon-picker-modal .search-form-group {
	background: #f6f7fb;
	border: 1px solid rgba(60, 126, 243, 0.1);
	box-sizing: border-box;
	border-radius: 5px;
	padding: 13px 20px;
	margin-bottom: 24px;
}

.choice-icon-picker-modal .search-form-group svg {
	float: right;
	margin-top: -20px;
	margin-right: 0px;
	color: #546376;
}

.choice-icon-picker-modal .search-form-group input {
	border: 0;
	box-shadow: unset;
	margin: 0;
	padding: 0 !important;
	display: block;
	height: auto;
	font-family: "Poppins";
	font-weight: 400;
	font-size: 16px;
	line-height: 24px;
	color: rgba(19, 49, 89, 0.3);
}

.choice-icon-picker-modal .search-form-group input:focus {
	outline: 0;
	border: 0 !important;
	box-shadow: unset !important;
}

.choice-icon-picker-modal .MuiTabs-scroller.MuiTabs-fixed {
	border-bottom: 1px solid #e5e8ef;
}
.choice-icon-picker-modal .MuiTabs-scroller.MuiTabs-fixed .MuiTabs-indicator {
	display: none;
}

.choice-icon-picker-modal .MuiTabs-scroller.MuiTabs-fixed button {
	font-family: "Poppins" !important;
	font-style: normal;
	font-weight: 400 !important;
	font-size: 16px;
	line-height: 24px;
	color: #546376;
	padding: 20px;
	opacity: 1;
	text-transform: capitalize;
	border-bottom: 1px solid transparent;
}
.choice-icon-picker-modal .MuiTabs-scroller.MuiTabs-fixed button:focus {
	background-color: transparent;
	color: #133159;
}
.choice-icon-picker-modal .MuiTabs-scroller.MuiTabs-fixed button svg {
	margin: 0 8px 0 0 !important;
}

.choice-icon-picker-modal .MuiTabs-scroller.MuiTabs-fixed button.Mui-selected svg {
	color: #3c7ef3;
}

.choice-icon-picker-modal button .MuiTab-wrapper {
	flex-direction: row;
}

.choice-icon-picker-modal button.Mui-selected {
	border-bottom-color: #3c7ef3 !important;
}

.choice-icon-picker-modal .ifo-icon-wrapper {
	display: flex;
	flex-wrap: wrap;
	overflow-y: auto;
	height: 250px;
}

.cipm-tab-panel-wrapper {
	min-height: 380px;
}

.choice-icon-picker-modal .ifo-icon-wrapper span {
	background: #ffffff;
	border: 1px solid rgba(60, 126, 243, 0.1);
	box-sizing: border-box;
	border-radius: 5px;
	padding: 12.5px;
	margin: 0 12px 12px 0;
	max-height: 60px;
	max-width: 60px;
	text-align: center;
	line-height: 60px;
	display: inline-block;
	transition: all 0.4s ease-in-out;
	cursor: pointer;
}
.choice-icon-picker-modal .ifo-icon-wrapper span:hover {
	background-color: #f6f7fb;
	border-color: #3c7ef3;
}

.choice-icon-picker-modal .ifo-icon-wrapper span img {
	width: 100%;
	height: auto;
}

.choice-icon-picker-modal .awesome__scroll_bar::-webkit-scrollbar {
	width: 8px;
}

.cipm-body-wrapper .file__upload__wrapper__v2__top {
	margin-bottom: 24px;
}

/* Upload Tab */
.cipm-body-wrapper .file__upload__wrapper__v2__top {
	margin-bottom: 24px;
}

.cipm-body-wrapper .file__upload__wrapper__v2 .file__upload__signle__v2 button {
	border: 0 !important;
	padding: 12px;
	border-radius: 4px;
	font-size: 16px;
	box-shadow: unset !important;
	min-width: 180px;
	margin-top: auto;
	font-weight: 600;
}

.cipm-body-wrapper .file__upload__wrapper__v2 .file__upload__signle__v2 {
	background: #dddddd26;
	padding: 12px;
	min-height: 180px;
}
.cipm-body-wrapper .form__style__background__tabs__link input.form__tabs__link__input {
	box-shadow: unset !important;
	margin: 0 !important;
}

.cipm-body-wrapper .form__style__background__tabs__link input.form__tabs__link__input {
	box-shadow: unset !important;
	margin: 0 !important;
	border: 0 !important;
}

.cipm-body-wrapper .form__style__background__tabs__link input.form__tabs__link__input:focus {
	box-shadow: unset !important;
	margin: 0 !important;
}

/* Add Icon Button */
.specific__input__field__wr__left .mc-single-input-area .cipm-button {
	font-family: "Poppins";
	box-shadow: unset !important;
	font-weight: 400;
	font-size: 16px;
	line-height: 24px;
	color: #133159;
	text-transform: capitalize;
	border-radius: 5px 0 0 5px;
	background: #e5e8ef;
	padding: 10px 12px;
	margin: 0 !important;
	min-height: 50px;
}

.specific__input__field__wr__left .mc-single-input-area .cipm-button:hover {
	box-shadow: unset;
}

.mc-single-input-area.single-input-area.inserted__fieldLevel input {
	width: calc(100% - 221px);
	min-height: 50px;
	border: 0 !important;
	margin: 0 20px 0 0 !important;
	border-top-left-radius: 0 !important;
	border-bottom-left-radius: 0;
	box-shadow: unset;
	background: #f6f7fb;
	font-weight: 400;
	font-size: 16px;
	line-height: 24px;
	color: rgb(84 99 118 / 50%);
	text-transform: capitalize;
	padding: 13px 20px;
}

.mc-single-input-area.single-input-area.inserted__fieldLevel input:focus {
	box-shadow: unset !important;
	border: 0 !important;
}
.mc-single-input-area .action-buttons button {
	min-height: 44px;
}

.specific__input__field__wr__left .mc-single-input-area > p#multiple-choice {
	width: 35px;
}

.mc-single-input-area .action-buttons {
	height: auto;
}

.mc-single-input-area .action-buttons button {
	min-height: 50px;
	background: #f6f7fb;
	border-radius: 4px;
	margin: 0 !important;
	min-width: 50px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.mc-single-input-area.single-input-area.inserted__fieldLevel {
    margin: 17px 0 !important;
}

.file__upload__wrapper__v2.choice__upload_wrapper {
	display: block;
}

/*  Icon Finder Skeleton */
.choice-icon-picker-modal .icon__finder__skeleton__wr {
  margin: 20px 0;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(60px, 1fr));
  grid-gap: 5px;
}

.choice-icon-picker-modal .icon__finder__skeleton__wr .skeleton__each__icon__section {
  width: 100%;
}

.choice-icon-picker-modal .icon__finder__skeleton__wr .skeleton__each__icon__section .skeleton__each__icon{
  border-radius: 5px;
  width: 60px;
  height: 60px;
}

/*  No Icon Found */
.choice-icon-picker-modal .no__icon__found__section {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
