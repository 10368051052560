/* Form Design */
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

.body__container__form__builder {
  width: 60%;
  margin: 50px auto;
}

.body__container__form__builder.logo__area{
  width: 60%;
  margin: 0 auto;
}

.body__container__form__builder .ck.ck-editor__editable_inline {
  overflow: hidden !important;
}

.form-title {
  width: 100%;
  font-size: 28px;
  font-weight: 700;
  color: black;
  margin: 25px 0;
  border: navajowhite;
}

.form-input:focus,
.form-input:focus-visible {
  border: none;
  outline: none;
}

.single-input-area .action-buttons {
  transition: 500ms;
  opacity: 1;
}

.single-input-area:hover .action-buttons {
  opacity: 1;
}

.input-area > div {
  padding: 5px;
}

.action-buttons {
  display: flex;
  justify-content: flex-end;
  height: 36px;
}
.preview__top__relative{
  padding: 0 !important;
}

.action-buttons button {
  display: inline-block;
  padding: 0.3rem 0.7rem;
  margin: 1px;
  transition: 300ms;
  cursor: pointer;
  background-color: #efefef;
}

.action-buttons button:hover {
  background-color: #dfdfdf;
}

.page-devider {
  margin: 30px 0;
  display: flex;
  justify-content: space-between;
}

.page-devider Button {
  opacity: 1;
  transition: 500ms;
  background-color: rgba(0, 0, 0, 0.04);
  min-width: 50px;
  height: 50px;
  margin-right: 12px;
}

.page-devider Button:hover {
  background: #00000066;
  color: #000;
}

.page-devider .horizontal-line-area {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.page-devider .horizontal-line-area div:nth-child(1),
.page-devider .horizontal-line-area div:nth-child(3) {
  width: 40%;
  height: 1px;
  background-color: rgba(0, 0, 0, 0.5);
}

.page-devider .horizontal-line-area div:nth-child(2) {
  width: 20%;
  background-color: #fff;
  text-align: center;
  border: 1px solid rgba(0, 0, 0, 0.5);
  padding: 5px;
  font-size: 0.75em;
}

.modal-100w {
  width: 100%;
  max-width: none !important;
  margin: 0;
  border: none;
  height: 100vh;
}

.min-h-100vh.public__page__background{
  background-size: cover;
  background-repeat: no-repeat;
}
.modal-100w .modal__preview__content__wr {
  height: 100vh;
  margin: 0;
  background-color: white;
  border-radius: inherit;
  box-shadow: none;
  z-index: 999;
  overflow-y: auto;
  padding: 24px;
  background-size: cover;
  background-repeat: no-repeat;
}

.modal__preview__content__wr .modal__preview__content__heading__wr {
  display: flex;
  justify-content: space-between;
  margin: 20px;
}

.modal__preview__content__wr .back__to__edit {
  padding: 0px 12px;
  border: 0px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  text-transform: capitalize;
  display: flex;
  grid-gap: 5px;
  z-index: 5;
  font-weight: 700;
  color: #fff;
  height: 34px !important;
  background: #0d6efd !important;
}

.modal__preview__content__heading__wr h2 {
  font-size: 26px;
  font-weight: 700;
  color: rgb(55, 53, 47);
}

.modal__preview__content__wr .back__to__edit .MuiButton-label {
  display: flex;
  align-items: center;
  grid-gap: 8px;
}

.modal__preview__content__wr .back__to__edit .MuiButton-label {
  display: flex;
  align-items: center;
  grid-gap: 8px;
}

.new__form__builder__preview__body {
  overflow-y: auto;
  max-width: 740px;
  margin: 50px auto 0px;
  padding: 0 10px;
}

.new__form__builder__preview__body .logo__area{
  margin: 0 auto !important;
}

.new__form__builder__preview__body .new__form__builder__preview__title {
  font-size: 40px;
  font-weight: 800;
  line-height: 1.1;
  padding-bottom: 40px;
  margin: 0px;
}

.new__form__builder__preview__body .singleItem textarea,
.new__form__builder__preview__body .singleItem input {
  width: 100%;
  height: 36px;
  padding: 0px 10px 0px 30px;
  box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
    rgba(0, 0, 0, 0.12) 0px 1px 1px 0px, rgba(60, 66, 87, 0.16) 0px 0px 0px 1px,
    rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
    rgba(60, 66, 87, 0.08) 0px 2px 5px 0px !important;
  border: 0px !important;
  border-radius: 5px;
  outline: none;
  background-color: rgb(255, 255, 255);
  margin: 0;
}

.new__form__builder__preview__body .singleItem textarea {
  min-height: 60px;
  height: auto;
  resize: none;
  padding: 10px 10px 10px 30px;
}

.ar__flex_column {
  display: flex;
  flex-direction: column;
}

.new__form__builder__preview__body .singleItem.alt svg {
  top: 10px;
  transform: unset;
}

.singleItem h5 {
  font-size: 16px;
  margin-bottom: 10px;
}

.new__form__builder__preview__body .singleItem > div {
  position: relative;
}

.new__form__builder__preview__body .singleItem svg {
  position: absolute;
  left: 10px;
  top: 7px;
}

.new__form__builder__preview__body .new__form__builder__preview__submit__btn {
  background-color: rgb(0, 0, 0);
  color: #fff;
  text-transform: capitalize;
  font-weight: 700;
  /* padding: 3px 17px; */
}

.new__form__builder__preview__body
  .new__form__builder__preview__submit__btn:hover {
  background-color: rgb(0, 0, 0);
}

.new__form__builder__preview__body
  .new__form__builder__preview__submit__btn
  .MuiButton-label {
  display: flex;
  grid-gap: 3px;
}

.new__form__builder__preview__body
  .new__form__builder__preview__submit__btn
  svg {
  font-size: 16px;
}

.preview-body::-webkit-scrollbar {
  width: 3px;
}

/* Track */
.preview-body::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.preview-body::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
.preview-body::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.preview-form-title {
  margin: 20px 0 30px;
}

.ck.ck-editor__editable_inline {
  margin: 50px 0;
}

.ck.ck-editor__editable:not(.ck-editor__nested-editable).ck-focused {
  border: none !important;
  box-shadow: none !important;
}

.ck.ck-editor__editable_inline > :last-child {
  margin-bottom: 0 !important;
}

.ck.ck-editor__editable_inline > :first-child {
  margin-top: 0 !important;
}

.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}

/* Preview Radio, Checkbox, Multiple Choice */
.new__form__builder__preview__body .ar__form_global_control {
  margin-bottom: 20px !important;
  display: flex !important;
}

.ar__form_global_control > legend {
  font-size: 18px;
  margin-bottom: 10px;
  font-weight: 700;
}

.ar__form_global_control label span.MuiButtonBase-root {
  padding-top: 4px !important;
  padding-bottom: 4px !important;
}

.ar__form_global_control .ar__flex_column {
  flex-direction: row;
}

.ar__form_global_control.ar__multiple .ar__flex_column {
  display: inline-flex;
  flex-direction: column;
}

.ar__form_global_control.ar__multiple .ar__flex_column button {
  width: fit-content;
  min-width: 200px;
  justify-content: flex-start !important;
  box-shadow: unset !important;
}

.new__form__builder__preview__body .ar__form_global_control {
  margin-bottom: 20px !important;
  display: flex !important;
}

.ar__form_global_control.ar__multiple
  .ar__flex_column
  button.MuiButton-contained {
  background: #3f51b5;
}

.ar__form_global_control.ar__multiple
  .ar__flex_column
  button.MuiButton-contained
  span
  span {
  background: #ddd;
}

.ar__flex_column .choice-list-icon {
  width: 25px;
  height: 25px;
  margin-right: 10px;
}

.ar__form_global_control.ar__multiple .ar__flex_column button + button {
  margin-top: 10px;
}

.ar__form_global_control.ar__multiple .ar__flex_column button span span {
  background: #3f51b552;
  padding: 0 8px;
  margin-right: 10px;
  border-radius: 4px;
  color: #222 !important;
}

.ar__form_global_control.ar__dropdown {
  padding: 8px 12px;
  margin: 30px 0;
}

.ar__form_global_control.ar__dropdown fieldset {
  border: 0;
  background: transparent;
}

.new__form__builder__preview__body form form {
  margin: 40px 0 10px;
  position: relative;
}

.ar__specific_input {
  position: relative;
}

.ar__req {
  position: absolute;
  right: -5px;
  font-size: 25px;
  color: #c00;
  top: 5px;
}
.new__form__builder__preview__body form form .ar__req {
  top: -12px;
}
.textareaAlt .ar__req {
  top: 3px;
}

.textarea2 .ar__req {
  top: -6px;
}

.textareaAlt3 .ar__req {
  top: -15px;
}

.alter.customRow span.ar__req {
  top: -3px;
}

.alternative span.ar__req {
  top: -12px;
}

.ar__dropdown2 .ar__req,
.singleItem span.ar__req {
  top: -13px;
}

.singleItem.dropdown_block span.ar__req {
  top: 13px;
  z-index: 9;
}

.ar__form_global_control legend.MuiFormLabel-root {
  position: relative;
}

.ar__form_global_control legend.MuiFormLabel-root .ar__req {
  top: -3px;
  right: -15px;
}

.new__form__builder__preview__body .ar__form_global_control legend {
  position: relative;
  width: fit-content;
}

.MuiFormControl-root.ar__dropdown.ar__dropdown2 .MuiInputLabel-formControl {
  top: -7px;
}

.MuiFormControl-root.ar__dropdown.ar__dropdown2
  .MuiInputLabel-formControl.MuiFormLabel-filled {
  top: -15px !important;
  left: 0px !important;
}

.specific__input__field__wr {
  transition: all 0.3s linear;
}

.specific__input__field__wr.decrease__opacity {
  opacity: 0.35;
}

.preview__date_field .MuiFormControl-root.MuiTextField-root.MuiFormControl-marginNormal {
  width: 100%;
}

.preview__date_field button.MuiButtonBase-root.MuiIconButton-root{
  position: absolute;
  top: -4px;
  right: 0;
}

.singleItem.date-field .preview__date_field svg{
  position: unset;
}
.singleItem.date-field .preview__date_field span.ar__req {
  top: 15px !important;
}
.singleItem.date-field .preview__date_field .MuiInputAdornment-root.MuiInputAdornment-positionEnd{
  margin-left: 0px !important;
}

.preview__date_field button:focus{
  background-color: rgba(0,0,0,0.0) !important ;
}

.preview__date_field .MuiFormControl-root.MuiFormControl-marginNormal {
  margin-top: 0;
  margin-bottom: 0;
}

button.new__form__builder__preview__submit__btn.center {
  margin: 0 auto;
  display: block;
}

button.new__form__builder__preview__submit__btn.right {
  margin-left: auto;
}

button.new__form__builder__preview__submit__btn.MuiButton-sizeLarge {
  font-size: 1.3rem;
}

button.new__form__builder__preview__submit__btn.MuiButton-sizeMedium {
  font-size: 0.95rem;
}

button.new__form__builder__preview__submit__btn.MuiButton-sizeSmall {
  font-size: 0.75rem;
}

.FormBuilderModule-container-parent-class .min-h-100vh {
  min-height: 100vh;
}

.FormBuilderModule-container-parent-class .form__builder__background__image__section {
  background-size: cover;
  background-repeat: no-repeat;
}

/* progressbar designs */
.progressbar__preview-container {
  background-color: #F6F7FB;
  width: 100%;
  height: 10px;
  border-radius: 10px;
  box-shadow: 0 0 0px 1px #ddd;
}

.progressbar__preview-container .progressbar__preview-bar {
  display: block !important;
  height: 10px;
  border-radius: 10px;
}

.progressbar__preview-container .progressbar__tooltip {
  border-radius: 4px;
  display: inline-block;
  padding: 8px;
  font-size: 14px;
  line-height: 16px;
  margin-top: 10px;
  position: relative;
}
.progressbar__preview-container .progressbar__tooltip::before{
  position: absolute;
  left: 50%;
  top: -6px;
  margin-left: -8px;
  content: '';
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
}

.new__form__builder__preview__body .progressbar__preview-container {
  margin-bottom: 65px;
}

.new__form__builder__preview__body {
  overflow-y: unset;
}

.ar__flex_column .fb__choice_icon{
  width: 40px;
  height: 40px;
}