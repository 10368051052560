.all__action__buttons {
  display: flex;
  column-gap: 10px;
  justify-content: flex-start;
}

.all__action__buttons .MuiButtonBase-root {
  background-color: rgba(0, 0, 0, 0.04);
}

.each_functionality {
  display: flex;
  flex-wrap: wrap;
  flex: 100 1;
  padding: 6px 0;
  min-width: 355px;
}
.each_functionality h5 {
  flex: 70;
}

.functionality_button {
  flex: 30;
}

.input__with__buttons div:empty,
.all__action__buttons button + div:empty {
  display: none;
}

.ar__specific_input input[type="email"]:focus,
.ar__specific_input input[type="tel"]:focus,
.ar__specific_input input[type="text"]:focus {
  outline: 0;
  box-shadow: rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px,
    rgb(0 0 0 / 12%) 0px 1px 1px 0px, rgb(60 66 87 / 16%) 0px 0px 0px 1px,
    rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px,
    rgb(60 66 87 / 8%) 0px 2px 5px 0px !important;
  border: 0 !important;
}

.ar__add_an .specific__input__field__wr__left {
  margin-top: 6px;
  position: relative;
}

.ar__add_an input {
  padding: 5px;
  box-shadow: rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px,
    rgb(0 0 0 / 12%) 0px 1px 1px 0px, rgb(60 66 87 / 16%) 0px 0px 0px 1px,
    rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px,
    rgb(60 66 87 / 8%) 0px 2px 5px 0px;
  border: 0px;
  border-radius: 5px;
  background: rgba(245, 245, 245, 0.376);
  font-size: 14px;
  padding-left: 35px;
  padding-right: 10px;
  cursor: pointer;
}

.ar__add_an input:focus {
  outline: 0;
}

.ar__add_an svg {
  width: 16px;
  height: 16px;
  min-width: 16px;
  min-height: 16px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 12px;
}

/* Radio */
.single-input-area.inserted__fieldLevel {
  display: flex;
  align-items: center;
}

body .single-input-area.inserted__fieldLevel input {
  margin: 0 10px !important;
}

.specific__input__field__wr__left.ar__specific_input input {
  height: 30px !important;
  border: 0 !important;
  outline: 0 !important;
}

.form__builder__modal.ar__options_modal
  .modal__body__area__v2.ar__modal_bodyV2 {
  padding: 30px;
  max-width: 415px;
}

.form__builder__modal.ar__options_modal
  .modal__body__area__v2.ar__modal_bodyV2
  h6 {
  margin-bottom: 10px;
  border-bottom: 1px solid #dddddd91;
  padding-bottom: 6px;
  font-weight: 600;
}

.form__builder__modal.ar__options_modal .each_functionality h5 {
  font-size: 16px;
  margin-right: 15px;
}

.ar__fullWidth_input input {
  margin: 0 !important;
}

.ar__fullWidth_input {
  display: flex;
  flex: 0 0 100%;
  margin-top: 12px;
}

.ar__fullWidth_input input {
  border: 1px solid #ddd !important;
  border-radius: 6px !important;
  padding: 8px 12px !important;
  box-shadow: unset !important;
}

.modal__body__area__v2.ar__modal_bodyV2 input[type="color"] {
  position: relative;
  border: 0;
  cursor: pointer;
  box-shadow: 0 0 1px #999;
}

.modal__body__area__v2.ar__modal_bodyV2 input[type="color"]:after {
  display: inline-block;
  content: "";
  background-color: inherit;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
